import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ApplicationListComponent } from './application-list/application-list.component';
declare var $: any;
import jsPDF from 'jspdf';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/event/events.service';


@Component({
  selector: 'app-smart-objects',
  templateUrl: './smart-objects.component.html',
  styleUrls: ['./smart-objects.component.scss']
})
export class SmartObjectsComponent implements OnInit {
  smartobjects: any;
  ObjectList: any = 0;
  catForm: FormGroup
  inputField = false;
  search: any
  modalData: any;
  bodyId: any;
  slug: any;
  link: any;
  dialogRef: any;
  EditnameForm: FormGroup;
  classAdd: boolean = false;
  dragDropActive: boolean = false;
  checked: any
  EditGroup: FormGroup;
  createSmartObject: FormGroup;

  constructor(public event: EventsService, public router: Router, public dialog: MatDialog, private service: ApiService, private common: CommonService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.catForm = this.formBuilder.group({
      name: [''],
    })
    this.GetContactList()

    this.EditnameForm = this.formBuilder.group({
      title: [''],
    })

    this.EditGroup = this.formBuilder.group({
      name: [''],
      id: ['']
    })

    this.createSmartObject = this.formBuilder.group({
      tag_id: [''],
      tag_name: ['']
    })
    let contactValue: any = JSON.parse(localStorage.getItem('B-in-Touch') || '{}')
    this.checked = contactValue.theme_mode == 'dark' ? false : true
    this.event.publishSomeData(contactValue.theme_mode)
    if (contactValue.theme_mode == 'light') {     // light mode
      var a: any = document.getElementById("smobject");

      a.className += " light-theme";
    } else {              // dark mode
      var b: any = document.getElementById("smobject");
      a.classList.remove('light-theme');
    }


  }

  /* === Smart Object list === */
  Smartobject() {
    this.service.getuserSmartobject('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.smartobjects = res.items
        this.modalData = res.items[0]
        console.log(this.modalData)
        if (this.modalData) {
          this.link = this.modalData.tag_url
        }

        this.smartobjects.forEach((element: any) => {
          if (element.lock_status == "0") {
            element.lock = false
          } else if (element.lock_status == "1") {
            element.lock = false
          } else if (element.lock_status == "2") {
            element.lock = true
          }
        });
        if (this.ObjectList) {
          this.ObjectList.forEach((element: any) => {
            element.active = false
          });
          this.classAdd = true
        }

        console.log(this.smartobjects)
      }
      else {
        this.common.error(res.msg);

      }
    }, error => {

    })
  }

  /* === Change Actice and Inactive Smart Object === */
  change(event: any, status: any) {
    console.log(event, status)
    let data: any = status == "0" ? 2 : 0
    console.log(data)
    var fd = new FormData
    fd.append('shared_services', event.shared_services)
    fd.append('tag_id', event.tag_id)
    fd.append('tag_name', event.tag_name)
    fd.append('lock_status', data)
    this.service.updatesmartObject(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.msg)
        this.Smartobject()
      }
      else {
      }
    })
  }

  /* === Delete Smart Object === */
  remove(event: any) {
    console.log(event)
    var fd = new FormData
    fd.append('service_id', '')
    fd.append('tag_id', event.tag_id)
    fd.append('tag_name', event.tag_name)
    fd.append('lock_status', event.lock_status)
    // fd.append('lock_status', '')
    this.service.updatesmartObject(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success('Application successfully deleted in Smart object')
        this.Smartobject()
      }
      else {
        this.common.error(res.msg);

      }
    })
  }

  /* === Create Smart object Category=== */
  submitCat() {
    let row: any = this.ObjectList.length + 1
    var data = new FormData
    data.append('name', this.catForm.value.name)
    data.append('row_order', row)
    this.service.SaveObjectCategory(data).subscribe((res: any) => {
      //    console.log(res)
      if (res.success == 1) {
        this.inputField = false;
        this.catForm.reset()
        this.GetContactList()
      }
    })
  }

  /* === Get Category List=== */
  GetContactList() {
    this.service.GetObjectCategories().subscribe((res: any) => {
      //  console.log(res)
      if (res.success == 1) {
        this.ObjectList = res.items;
        if (res.items) {
          this.ObjectList.forEach((element: any) => {
            element.active = false

          });
        }
        console.log(this.ObjectList.length)
        this.Smartobject()
      } else if (res.http_status == '401') {
        this.common.error('You have been logged out for security purpose!');
        this.router.navigate(['']);
      } else {
        this.common.error(res.msg);
      }
    }, error => {

    })
  }

  /* === delete Category === */
  deleteContact(id: any) {
    var data = new FormData
    data.append('id', id)
    this.service.DeleteObjectCategory(data).subscribe((res: any) => {
      //  console.log(res)
      if (res.success == 1) {
        this.GetContactList()
      }
    }, error => {

    })
  }

  input() {
    this.inputField = true
  }

  // openModel() {
  //   const dialogRef = this.dialog.open(ApplicationlinkingComponent, {
  //     width: '400px',
  //   });

  //   dialogRef.afterClosed().subscribe((result: any) => {
  //   });
  // }


  /* === Assign app into Smart Object=== */
  applicationList(value: any, id: any) {
    const dialogRef = this.dialog.open(ApplicationListComponent, {
      width: '600px',
      data: {
        id: value.tag_id,
        type: id
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      //  console.log('The dialog was closed');
      this.Smartobject()
    });
  }

  model(data: any) {
    this.modalData = data;
    console.log(this.modalData)
    this.link = this.modalData.tag_url
    this.EditnameForm.patchValue({
      title: this.modalData.tag_name
    })
    console.log(this.EditnameForm.value)
    $('#editsmart_object').modal('show');
  }

  /* === Download QR code=== */
  download(value: any) {
    const qrcode: any = document.getElementById('qrcode');
    let doc = new jsPDF();
    doc.addImage(qrcode.src, 'PNG', 10, 10, 150, 150);
    doc.save(value + '.pdf');
  }

  /* === Drag and Drop Functionality=== */
  drag(ev: any, id: any, slug: any) {
    this.bodyId = id
    this.slug = slug
    this.dragDropActive = true
  }

  drop(ev: any, i: any, id: any) {
    var data = new FormData
    data.append('tag_id', this.bodyId)
    data.append('cat_id', id)

    this.service.saveObjectRelation(data).subscribe((res: any) => {
      //  console.log(res)
      if (res.http_status == '200') {
        this.dragDropActive = false
        this.common.success("Successfully assigned");
      }
      else {
        // this.common.error(res["msg"]);
      }
    })

  }

  allowDrop(ev: any) {
    ev.preventDefault();

  }

  /* === Filter data ACcording to category=== */
  getFilterObjectListing(categoryId: any) {
    console.log(categoryId)
    this.classAdd = false
    this.ObjectList.forEach((element: any) => {
      element.active = false
      if (element.id == categoryId) {
        element.active = true
      }
    });
    var data = new FormData()
    data.append('cat_id', categoryId)
    this.service.getuserSmartobject(data).subscribe((res: any) => {
      this.smartobjects = res.items;
      //  console.log(this.smartobjects)
    })
  }

  /* === Copy NFC tag Link === */
  copyUrl() {
    this.common.success("Copied the link");
  }


  // openDialog(id: any, name: any) {
  //   let editname = name ? name : 'B-in-touch'
  //   let data = {
  //     id: id,
  //     name: name
  //   }
  //   this.dialogRef = this.dialog.open(
  //     SmartObjectnameComponent, { width: '350px' })
  //   this.dialogRef.componentInstance.fromParent = data;
  //   this.dialogRef.afterClosed().subscribe((result: any) => {
  //     console.log("comp closed");
  //     this.Smartobject();
  //   })
  // }

  /* === Edit Smart Object Name=== */
  submit() {
    $('#editsmart_object').modal('hide');
    var data = new FormData
    data.append('tag_id', this.modalData.tag_id)
    data.append('tag_name', this.EditnameForm.value.title);
    this.service.EditSmartObject(data).subscribe((res: any) => {
      console.log()
      if (res.http_status == 200) {
        this.Smartobject()
      } else {
        this.common.error(res["msg"]);
      }
    })
  }

  ligthDarkVersion() {
    if (this.checked) {
      var a: any = document.getElementById("smobject");
      a.className += " light-theme";
      this.event.publishSomeData("light")
    } else {
      var a: any = document.getElementById("smobject");
      a.classList.remove('light-theme');
      this.event.publishSomeData("dark")
    }
    this.themeChange()
  }

  themeChange() {
    let value = this.checked == true ? 'light' : 'dark'
    var data = new FormData
    data.append('theme_mode', value)
    this.service.themeSelection(data).subscribe((res: any) => {
      console.log(res)
      if (res["success"] == 1) {
        localStorage.setItem('B-in-Touch', JSON.stringify(res["user"]))
      }

    })
  }

  editGroup(data: any) {
    $('#edit_category').modal('show');
    this.EditGroup.patchValue({
      name: data.name,
      id: data.id
    })

  }

  editGroupsave() {
    var data = new FormData
    data.append('name', this.EditGroup.value.name)
    data.append('id', this.EditGroup.value.id)
    this.service.editcatSmartObject(data).subscribe((res: any) => {
      console.log(res)
      if (res["success"] == 1) {
        $('#edit_category').modal('hides');
        this.GetContactList()
      }
    })
  }


  createSmart() {
    var data = new FormData
    data.append('tag_id', this.createSmartObject.value.tag_id)
    data.append('tag_only', '1')
    this.service.validatetagIdforSmartObject(data).subscribe((res: any) => {
      console.log(res)
      if (res["success"] == 1) {
        var data = new FormData
        data.append('tag_id', this.createSmartObject.value.tag_id)
        data.append('tag_name', this.createSmartObject.value.tag_name)
        data.append('lock_status', '2')
        this.service.EditSmartObject(data).subscribe((res: any) => {
          console.log(res)
          if (res["success"] == 1) {
            this.Smartobject()
          }
        })
      } else {
        this.common.error(res["msg"]);
      }
    })
  }
}
