<body class="vh-100">
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-md-7">
                    <div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <div class="auth-form termCondition" id="scroll">
                                    <h4 class="mb-3">
                                        <svg (click)="history.back()" xmlns="http://www.w3.org/2000/svg" width="30"
                                            height="30" viewBox="0 0 24 24" fill="none">
                                            <path id="Path 2" d="M21 12L3 12" stroke="#11193d" stroke-width="2"
                                                stroke-linecap="round" />
                                            <path id="Path 3" d="M9 6L3 12L9 18" stroke="#11193d" stroke-width="2"
                                                stroke-linecap="round" />
                                        </svg>
                                        Terms & Conditions
                                    </h4>
                                    <div class="card_scroll height60vh custom_scrollbar">
                                        <p [innerHTML]="termsData"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</body>