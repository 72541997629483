import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-app-notes',
  templateUrl: './app-notes.component.html',
  styleUrls: ['./app-notes.component.scss']
})
export class AppNotesComponent implements OnInit {
  notesData: any;
  id: any;
  slug: any;

  constructor(private router: Router, public activeroute: ActivatedRoute, private auth: ApiService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      console.log( this.id, this.slug )
      if (this.id) {
        this.getDetails()
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
  }

  getDetails() {
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('slug', this.slug);
    this.auth.getServiceDetail(formData).subscribe((response: any) => {
      if (response.success == 1) {
        console.log(response)
        this.notesData = response.item

      }

    })
  }

}
