import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
// import {CommonService} from '../common/common.service';
// import {LocalStorageService} from "angular-web-storage";
// import { ObservableService } from '../rxjs/observable.service';
// import { UrlService } from '../url/url.service';

@Injectable({
  providedIn: 'root'
})
export class GetInterceptorService {

  constructor(
    private router: Router,

  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
      }
    }));
  }
  error = (message:any) => {
  }
}
