<app-sidebar></app-sidebar>
<div class="content-body" id="das">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-center mb-0 justify-content-between">
            <h2 class="font-w600 mb-0 mr-auto text_opacity_68 letterspace">Overview</h2>
            <div class="mode-sec d-flex align-items-center justify-content-center">
                <p>Dark mode</p>
                <mat-slide-toggle [(ngModel)]="checked" (change)="ligthDarkVersion()"></mat-slide-toggle>
                <p>Light Mode</p>
            </div>
        </div>
        <div class="row data_cards">
            <div class="col-xl-4 col-sm-6 mt-4">
                <div class="cards-outer" [ngClass]="{'flipd': firstFlip == true}" (click)="flipCard('first')">
                    <div class="card bg_d_light_blue">
                        <div class="card-body">
                            <div class="card_icon mb-3">
                                <img src="../../../assets/images/contactless-24px.svg" alt="" />
                            </div>
                            <h2 class="text-black mb-0 font-w700">{{smartObject}}</h2>
                            <p class="mb-0 fs-14">
                                Smart Objects
                            </p>
                            <div class="more_btn text-right mt-2">
                                <a href=""><img src="assets/images/arrow_right.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                    <div class="card bg_d_blue">
                        <div class="card-body">
                            <div class="card_icon mb-3">
                                <img src="../../../assets/images/contactless-24px.svg" alt="" />
                            </div>
                            <h2 class="text-black mb-0 font-w700">{{totalScan}}</h2>
                            <p class="mb-0 fs-14">
                                Total Scans
                            </p>
                            <div class="more_btn text-right mt-2">
                                <a href=""><img src="assets/images/arrow_right.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-sm-6 mt-4">
                <div class="cards-outer" [ngClass]="{'flipd': secondFlip == true}" (click)="flipCard('second')">
                    <div class="card bg_purple-light">
                        <div class="card-body">
                            <div class="card_icon mb-3">
                                <img src="../../../assets/images/apps_white_24dp.svg" alt="" />
                            </div>
                            <h2 class="text-black mb-0 font-w700">{{applications}}</h2>
                            <p class="mb-0 fs-14">
                                Applications
                            </p>
                            <div class="more_btn text-right mt-2">
                                <a href=""><img src="assets/images/arrow_right.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                    <div class="card bg_purple">
                        <div class="card-body">
                            <div class="card_icon mb-3">
                                <img src="../../../assets/images/perm_contact_calendar-24px.svg" alt="" />
                            </div>
                            <h2 class="text-black mb-0 font-w700">{{totalContact}}</h2>
                            <p class="mb-0 fs-13">
                                New Contacts
                            </p>
                            <div class="more_btn text-right mt-2">
                                <a href=""><img src="assets/images/arrow_right.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-sm-6 mt-4">
                <div class="cards-outer" [ngClass]="{'flipd': thirdFlip == true}" (click)="flipCard('third')">
                    <div class="card bg_light-green">
                        <div class="card-body">
                            <div class="card_icon mb-3">
                                <img src="../../../assets/images/park_white_24dp.svg" alt="" />
                            </div>
                            <h2 class="text-black mb-0 font-w700">{{treeSaved}}</h2>
                            <p class="mb-0 fs-14">
                                Trees Saved
                            </p>
                            <div class="more_btn text-right mt-2">
                                <a href=""><img src="assets/images/arrow_right.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                    <div class="card bg_green">
                        <div class="card-body">
                            <div class="card_icon mb-3">
                                <img src="../../../assets/images/savings-24px.svg" alt="" />
                            </div>
                            <h2 class="text-black mb-0 font-w700">{{totalMoney}} $</h2>
                            <p class="mb-0 fs-14">
                                Money Saved
                            </p>
                            <div class="more_btn text-right mt-2">
                                <a href=""><img src="assets/images/arrow_right.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-7">
                <div class="card applications_card">
                    <div class="card-header border-0 pb-0 d-flex align-items-center justify-content-between">
                        <h4 class="mb-0 text-black"><span class="cursor-p"
                                [ngClass]="{'opacity-half': pieType === 'smart'}" (click)="pieData('app')">Applications
                            </span>
                            <span class="px-1 opacity-half">|</span> <span class="cursor-p"
                                [ngClass]="{'opacity-half': pieType === 'app'}" (click)="pieData('smart')"> Smart
                                Objects
                            </span>
                        </h4>
                        <div class="morebtn">
                            <svg (click)="openMore()" id="Button" xmlns="http://www.w3.org/2000/svg" width="4"
                                height="18" viewBox="0 0 4 18">
                                <circle id="Ellipse_13" data-name="Ellipse 13" cx="2" cy="2" r="2" fill="#BDC0CF" />
                                <circle id="Ellipse_13-2" data-name="Ellipse 13" cx="2" cy="2" r="2"
                                    transform="translate(0 7)" fill="#BDC0CF" />
                                <circle id="Ellipse_13-3" data-name="Ellipse 13" cx="2" cy="2" r="2"
                                    transform="translate(0 14)" fill="#BDC0CF" />
                            </svg>
                            <div class="more-menu" [ngClass]="{'show-menu': showMore == true}">
                                <div class="inner-sec">
                                    <h5>{{pieType == 'app' ? 'Applications': 'Smart Objects'}}</h5>
                                    <div class="select-outer cstm" *ngIf="pieType == 'app'">
                                        <!-- <select class="form-control" [(ngModel)]="slugObject"
                                            (change)="addObject('app')">
                                            <option selected value="">Choose from the list</option>
                                            <option *ngFor="let item of applicationItems" [value]="item?.slug">
                                                {{item?.slug}}</option>
                                        </select> -->

                                        <div class="form-control d-flex align-items-center justify-content-between cursor-p" (click)="selectCustm('pieApp')">
                                            Choose from the list
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
                                                <path id="Path_958" data-name="Path 958" d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z" transform="translate(-6 -8.59)" fill="#fff"/>
                                            </svg>
                                        </div>
                                        <!-- <input class="form-control deopdown-togle" placeholder="Choose from the list">  -->
                                        <div class="select-wrap" [ngClass]="{'show-menu': showSelectMenu === true}">
                                            <div class="select-inner">
                                                <mat-accordion>
                                                    <mat-expansion-panel *ngFor="let catApp of groupByApp?.reverse();">
                                                      <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                        {{catApp?.cat_name}}
                                                        </mat-panel-title>
                                                      </mat-expansion-panel-header>
                                                      <mat-checkbox class="cstm-checkbox" 
                                                      (change)="addObject('app', app.slug)"  *ngFor="let app of catApp.data">{{app?.name}}</mat-checkbox>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                            </div>
                                            <div class="selectoverlay" (click)="selectCustm('pieApp')"></div>
                                        </div>
                                    </div>
                                    <div class="select-outer" *ngIf="pieType == 'smart'">
                                        <select class="form-control" [(ngModel)]="smartObjectName"
                                            (change)="addObject('smart')">
                                            <option value="">Choose from the list</option>
                                            <option *ngFor="let item of smartObjectItems" [value]="item?.tag_id">
                                                {{item?.tag_name?item.tag_name:'B-in-touch'}}</option>
                                        </select>
                                    </div>
                                    <div class="more-itm d-flex align-items-center justify-content-between"
                                        *ngFor="let item of getSavedData(pieType);">
                                        <p>{{pieType == 'app' ? item : item?.tag_name ||'B-in-touch'}}</p>
                                        <img class="cursor-p" src="assets/images/deleteicn.svg"
                                            (click)="removeObject(item, pieType)">
                                    </div>
                                    <div class="more-itm d-flex align-items-center justify-content-between"
                                        *ngIf="getSavedData(pieType).length == 0">
                                        <p>No data added!</p>
                                    </div>
                                </div>
                                <div class="overlaydiv" (click)="openMore()"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="chart-point">
                            <ul class="chart-point-list">
                                <ng-container *ngIf="doughnutkeylist.length != 0">
                                <ng-container *ngFor="let title of doughnutkeylist">
                                    <li *ngIf="title.value != '0'"><i class="mr-2"
                                            [ngStyle]="{'background-color':title.color}"></i> <span
                                            *ngIf="title.value != '0'">{{title.key}}</span></li>
                                </ng-container>
                                </ng-container>
                                <ng-container *ngIf="doughnutkeylist.length == 0"> Firstly select the {{pieType}} first</ng-container>
                            </ul>
                            <div class="check-point-area" *ngIf="pieType =='app'">
                                <canvas id="myChart"></canvas>
                            </div>
                            <div class="check-point-area" *ngIf="pieType =='smart'">
                                <canvas id="smartChart"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-5">
                <div class="card smart_card">
                    <div class="card-header border-0 pb-0">
                        <h4 class="mb-0 text-black"><span class="cursor-p"
                                [ngClass]="{'opacity-half': accordianType === 'app'}"
                                (click)="accordianData('smart')">Smart Objects </span> <span
                                class="px-1 opacity-half">|</span> <span class="cursor-p"
                                [ngClass]="{'opacity-half': accordianType === 'smart'}" (click)="accordianData('app')">
                                Applications</span></h4>
                        <div class="input-outer d-flex align-items-center justify-content-center">
                            <svg width="14.501" height="14.498" viewBox="0 0 14.501 14.498">
                                <path id="icon"
                                    d="M10.4,10.394a6.088,6.088,0,1,1,1.784-4.307A6.071,6.071,0,0,1,10.4,10.394l2.1,2.1Z"
                                    transform="translate(1 1)" fill="none" stroke="#fff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2" />
                            </svg>
                            <!-- <form [formGroup]="searchForm"> -->
                                  <div id="search" class="search-input" contenteditable="true" placeholder="Search" name="search"  (keyup)="search()"> </div>
                            <!-- <input type="text" class="search-input" contenteditable="true" placeholder="Search" [(ngModel)]="searchValue" name="search"  (keyup)="search(searchValue)"> -->
                        <!-- </form> -->
                        </div>
                    </div>
                    <!-- formControlName="searchValue" -->
                    <div class="card-body custom_scrollbar">
                        <div class="filter-sec d-flex align-items-center justify-content-between">
                            <div class="filter_main position-relative">
                                <div class="cursor-p filterbtn" (click)="openFilter()">
                                    <svg width="14.123" height="12.871" viewBox="0 0 14.123 12.871">
                                        <g id="filter-tool-svgrepo-com" transform="translate(0.259 -17.434)">
                                            <g id="Group_1997" data-name="Group 1997" transform="translate(0 17.684)">
                                                <g id="Group_1996" data-name="Group 1996" transform="translate(0)">
                                                    <path id="Path_3158" data-name="Path 3158"
                                                        d="M240.38,81.275h.443a.3.3,0,0,0,0-.591h-.443a.3.3,0,0,0,0,.591Z"
                                                        transform="translate(-231.213 -78.319)" fill="#fff"
                                                        stroke="#fff" stroke-width="0.5" />
                                                    <path id="Path_3159" data-name="Path 3159"
                                                        d="M13.542,17.009a.589.589,0,0,0-.532-.325H.595a.617.617,0,0,0-.532.325.589.589,0,0,0,.059.621l5.217,6.562v4.271a.58.58,0,0,0,.31.517.611.611,0,0,0,.281.074.575.575,0,0,0,.34-.1l1.641-1.138a.9.9,0,0,0,.369-.724V24.177l5.2-6.547A.589.589,0,0,0,13.542,17.009ZM7.748,23.9a.317.317,0,0,0-.059.177v3a.316.316,0,0,1-.118.251L5.93,28.464v-4.39a.268.268,0,0,0-.118-.236l-3.34-4.2H7.837a.3.3,0,1,0,0-.591H2.013L.595,17.275H13.01Z"
                                                        transform="translate(0 -16.684)" fill="#fff" stroke="#fff"
                                                        stroke-width="0.5" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    Filter by Group
                                </div>
                                <div class="filter-box" [ngClass]="{'show-menu': showFilters == true}" *ngIf="accordianType == 'smart'">
                                    <div class="inner-sec">
                                        <h6>Groups</h6>
                                        <mat-checkbox  class="cstm-checkbox" *ngFor="let smart of catSmartObject; let i = index" (change)="choosecatSmart(smart.id, $event, i)">{{smart?.name}}</mat-checkbox>
                                        <!-- <mat-checkbox class="cstm-checkbox">Other Objects</mat-checkbox> -->
                                    </div>
                                    <div class="overlaydiv" (click)="openFilter()"></div>
                                </div>
                                <div class="filter-box" [ngClass]="{'show-menu': showFilters == true}" *ngIf="accordianType == 'app'">
                                    <div class="inner-sec">
                                        <h6>Groups</h6>
                                        <mat-checkbox  class="cstm-checkbox" *ngFor="let app of catApp ; let i = index" (change)="choosecatapp(app.id, $event, i)">{{app?.name}}</mat-checkbox>
                                        <!-- <mat-checkbox class="cstm-checkbox" (change)="mobile($event)">Mobile</mat-checkbox> -->
                                    </div>
                                    <div class="overlaydiv" (click)="openFilter()"></div>
                                </div>
                            </div>
                            <h4 class="mb-0 text-black fs-14 opacity-half engagements">Engagements</h4>
                        </div>
                        <!-- <div class="table-responsive">
                            <table class="table text-center bg-warning-hover"> 
                                <tbody>
                                    <tr *ngFor="let data of smartobjects">
                                        <td class="text-left">{{data.tag_name}}</td>
                                        <td>{{data.total}}</td>
                                    </tr>
                                  
                                </tbody>
                            </table>
                            <div *ngIf="smartobjects && smartobjects?.length ==0" class="noData">No Data</div>
                        </div> -->
                        <div class="smart_card_inner">
                            <mat-accordion *ngIf="accordianType == 'smart'">
                                <ng-container *ngFor="let smart  of smartObjectEng | filter:searchText">
                                <mat-expansion-panel >
                                    <mat-expansion-panel-header>
                                        <h5 class="d-flex text-black align-items-center justify-content-between">
                                            {{smart.tag_name?smart.tag_name:'B-in-touch'}}
                                            <span class="value-txt">{{smart.total}}</span>
                                        </h5>
                                    </mat-expansion-panel-header>
                                    <div class="smart_content d-flex align-items-start justify-content-between">
                                        <div class="itm-inner">
                                            <p>{{smart.dcount}}</p>
                                            <h3>Scans Today</h3>
                                        </div>
                                        <div class="itm-inner">
                                            <p>{{smart.wcount}}</p>
                                            <h3>Scans This Week</h3>
                                        </div>
                                        <div class="itm-inner">
                                            <p>{{smart.mcount}}</p>
                                            <h3>Scans This Month</h3>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                </ng-container>
                            </mat-accordion>


                            <mat-accordion *ngIf="accordianType == 'app'">
                                <mat-expansion-panel *ngFor="let app  of AppEng">
                                    <mat-expansion-panel-header>
                                        <h5 class="d-flex text-black align-items-center justify-content-between">
                                            {{app.title}}
                                            <span class="value-txt">{{app.count}}</span>
                                        </h5>
                                    </mat-expansion-panel-header>
                                    <div class="smart_content d-flex align-items-start justify-content-between">
                                        <div class="itm-inner">
                                            <p>{{app.dcount}}</p>
                                            <h3>Scans Today</h3>
                                        </div>
                                        <div class="itm-inner">
                                            <p>{{app.wcount}}</p>
                                            <h3>Scans This Week</h3>
                                        </div>
                                        <div class="itm-inner">
                                            <p>{{app.mcount}}</p>
                                            <h3>Scans This Month</h3>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card activity_card">
                    <div class="card-header border-0 pb-0 d-flex align-items-start justify-content-between">
                        <!-- <div class="mb-3">
                            <h4 class="fs-20 text-black">Activity History</h4>
                        </div> -->
                        <div class="left-sec flex_1">
                            <h4 class="mb-0 text-black"><span class="cursor-p"
                                    [ngClass]="{'opacity-half': activityType === 'app'}"
                                    (click)="activityData('smart')">Smart Objects </span> <span
                                    class="px-1 opacity-half">|</span> <span class="cursor-p"
                                    [ngClass]="{'opacity-half': activityType === 'smart'}"
                                    (click)="activityData('app')"> Applications</span></h4>
                            <select class="form-control mt-2" *ngIf="activityType == 'smart'"
                                [(ngModel)]="smartObjValue" (change)="selectSmartObject()">
                                <option value="">Choose from the list</option>
                                <option *ngFor="let item of smartObjectItems" [value]="item?.tag_id">
                                    {{item?.tag_name?item.tag_name:'B-in-touch'}}</option>
                            </select>

                            <!-- <div class="select-outer cstm mt-2" *ngIf="activityType == 'smart'">
                                <div class="form-control d-flex align-items-center justify-content-between cursor-p" (click)="selectCustm('smartactivity')">
                                    Choose from the list
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
                                        <path id="Path_958" data-name="Path 958" d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z" transform="translate(-6 -8.59)" fill="#fff"/>
                                    </svg>
                                </div>
                                
                                <div class="select-wrap" [ngClass]="{'show-menu': showActivitySelect === true}">
                                    <div class="select-inner">
                                        <mat-accordion>
                                            <mat-expansion-panel >
                                              <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                Panel One
                                                </mat-panel-title>
                                              </mat-expansion-panel-header>
                                              <mat-checkbox class="cstm-checkbox">One</mat-checkbox>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                    <div class="selectoverlay" (click)="selectCustm('smartactivity')"></div>
                                </div>
                            </div> -->

                            <!-- <select class="form-control mt-2" *ngIf="activityType == 'app'" [(ngModel)]="slugValue"
                                (change)="selectApplication()">
                                <option selected value="">Choose from the list</option>
                                <option *ngFor="let item of applicationItems" [value]="item?.id">
                                    {{item?.slug}}</option>
                            </select> -->
                            <div class="select-outer cstm mt-2" *ngIf="activityType == 'app'">
                                <div class="form-control d-flex align-items-center justify-content-between cursor-p" (click)="selectCustm('smartactivity')">
                                    {{appName?appName:'Choose from the list'}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
                                        <path id="Path_958" data-name="Path 958" d="M16.59,8.59,12,13.17,7.41,8.59,6,10l6,6,6-6Z" transform="translate(-6 -8.59)" fill="#fff"/>
                                    </svg>
                                </div>
                                
                                <div class="select-wrap" [ngClass]="{'show-menu': showActivitySelect === true}">
                                    <div class="select-inner">
                                        <mat-accordion>
                                            <mat-expansion-panel *ngFor="let catApp of groupByApp?.reverse();">
                                              <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                {{catApp?.cat_name}}
                                                </mat-panel-title>
                                              </mat-expansion-panel-header>
                                              <mat-checkbox class="cstm-checkbox"   [checked]="app.check"
                                              (change)="selectApplication(app.id, app.slug, app.name)"  *ngFor="let app of catApp.data">{{app?.name}}</mat-checkbox>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                    <div class="selectoverlay" (click)="selectCustm('smartactivity')"></div>
                                </div>
                            </div>
                        </div>
                        <div class="mid-sec text-center flex_1">
                            <h2>Activity History</h2>
                            <!-- <p class="mb-0">April</p> -->
                        </div>
                        <div class="dropdown flex_1 text-right">
                            <svg class="dropdown-toggle cursor-p" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="4"
                                height="18" viewBox="0 0 4 18">
                                <circle id="Ellipse_13" data-name="Ellipse 13" cx="2" cy="2" r="2" fill="#BDC0CF" />
                                <circle id="Ellipse_13-2" data-name="Ellipse 13" cx="2" cy="2" r="2"
                                    transform="translate(0 7)" fill="#BDC0CF" />
                                <circle id="Ellipse_13-3" data-name="Ellipse 13" cx="2" cy="2" r="2"
                                    transform="translate(0 14)" fill="#BDC0CF" />
                            </svg>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" [ngClass]="{'active': durationType == 'DAY'}"
                                    (click)="selectDuration('DAY')">
                                    Day
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.271" height="14.271"
                                        viewBox="0 0 12.271 14.271">
                                        <path id="Path_842" data-name="Path 842"
                                            d="M13.044,2.3h-.614V1H11.2V2.3H5.068V1H3.841V2.3H3.227A1.267,1.267,0,0,0,2,3.595V13.974a1.267,1.267,0,0,0,1.227,1.3h9.817a1.267,1.267,0,0,0,1.227-1.3V3.595A1.267,1.267,0,0,0,13.044,2.3Zm0,11.677H3.227V5.541h9.817Z"
                                            transform="translate(-2 -1)" fill="#fff" />
                                    </svg>
                                </a>
                                <a class="dropdown-item" [ngClass]="{'active': durationType == 'MONTH'}"
                                    (click)="selectDuration('MONTH')">
                                    Month
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.271" height="14.271"
                                        viewBox="0 0 12.271 14.271">
                                        <path id="Path_842" data-name="Path 842"
                                            d="M13.044,2.3h-.614V1H11.2V2.3H5.068V1H3.841V2.3H3.227A1.267,1.267,0,0,0,2,3.595V13.974a1.267,1.267,0,0,0,1.227,1.3h9.817a1.267,1.267,0,0,0,1.227-1.3V3.595A1.267,1.267,0,0,0,13.044,2.3Zm0,11.677H3.227V5.541h9.817Z"
                                            transform="translate(-2 -1)" fill="#fff" />
                                    </svg>
                                </a>
                                <a class="dropdown-item" [ngClass]="{'active': durationType == 'YEAR'}"
                                    (click)="selectDuration('YEAR')">
                                    Year
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.271" height="14.271"
                                        viewBox="0 0 12.271 14.271">
                                        <path id="Path_842" data-name="Path 842"
                                            d="M13.044,2.3h-.614V1H11.2V2.3H5.068V1H3.841V2.3H3.227A1.267,1.267,0,0,0,2,3.595V13.974a1.267,1.267,0,0,0,1.227,1.3h9.817a1.267,1.267,0,0,0,1.227-1.3V3.595A1.267,1.267,0,0,0,13.044,2.3Zm0,11.677H3.227V5.541h9.817Z"
                                            transform="translate(-2 -1)" fill="#fff" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pb-0">
                        <canvas id="areaChart_2" height="100" *ngIf="activityType == 'smart'"></canvas>
                        <canvas id="applicationChart" height="105" *ngIf="activityType == 'app'"></canvas>
                    </div>
                    <!-- <div class="card-body pb-0" *ngIf="activityType === 'app'">
                        <canvas id="areaChart_2App" height="75"></canvas>
                    </div> -->
                </div>
            </div>

        </div>

    </div>
</div>
<!-- The Modal -->
<div class="modal fade" id="contact_detail">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="card_header_pre d-flex justify-content-between pb-0">
                <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                <div class="preview_icons d-flex align-items-center">
                    <a href=""><img src="../../../assets/images/download-24px.svg" /></a>
                    <a href=""><img src="../../../assets/images/create-24px.svg" /></a>
                    <a href=""><img src="../../../assets/images/delete_outline-24px.svg" /></a>
                </div>
            </div>
            <button type="button" class="close" data-dismiss="modal">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_823" data-name="Path 823"
                        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                        fill="#b6b8c7" />
                </svg>
            </button>
            <div class="modal-body">
                <div class="user_profile d-flex align-items-center">

                    <img src="../../../assets/images/img_user.jpg" alt="" />
                    <div class="user_info d-flex flex-column">
                        <span class="fs17 text_white font-weight-bold">Remi Dupont</span>
                        <span class="fs14 pt-1">ESSEC/Student</span>
                    </div>
                </div>

                <ul class="list-unstyled user_info_list pt-3">
                    <li>
                        <label class="fs14 m-0">Phone Number</label>
                        <p class="text_white text_opacity_68">0033616930225</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Work Number</label>
                        <p class="text_white text_opacity_68">0033616930224</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Website</label>
                        <p class="text_white text_opacity_68">www.b-intouch.fr</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Home Address</label>
                        <p class="text_white text_opacity_68">23 Rue Louis Pouey, 92800, Puteaux France
                        </p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Birthday</label>
                        <p class="text_white text_opacity_68">11/04/1991</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Email </label>
                        <p class="text_white text_opacity_68">aaalfarra1@gmail.com</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Paypal</label>
                        <p class="text_white text_opacity_68">aboud.atassi.paris@gmail.com</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Home Address</label>
                        <p class="text_white text_opacity_68">231 Rue Saint honoré, 75001, Paris France
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>