import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import * as Chart from 'chart.js';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/event/events.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  smartObjectItems: any[] = [];
  applicationItems: any[] = [];
  savedSmartObject: any[] = [];
  savedAppObject: any[] = [];
  durationType = 'DAY';
  slugObject = '';
  smartObjectName = '';
  selectedDoughnutType = 1;
  slugValue = '';
  smartObjValue = '';
  public doughnutkeylist = [
    { key: '', color: '', value: '' },
  ]
  public SamrtGraph = [
    { key: '', color: '', value: '' },
  ];
  canvas: any;
  ctx: any;
  canvasSmart: any;
  ctxSmart: any;
  linechart: any;

  totalScan: any;
  totalContact: any;
  totalMoney: any = 0;
  smartobjects: any;
  seriess: any = []
  @Input() colors!: string[];
  @Input() labels!: string[];
  month: any;
  montharray: any = []
  linevalue: any = []
  days: any = [];
  firstFlip: boolean = false;
  secondFlip: boolean = false;
  thirdFlip: boolean = false;
  showMore: boolean = false;

  checked: any
  activityType: any = 'smart';
  pieType = 'app';
  accordianType: any = 'smart';

  showFilters: boolean = false;
  smartObject: any;
  treeSaved: any;
  applications: any;
  linechartApp: any;
  smartObjectEng: any;

  showSelectMenu: boolean = false;
  showActivitySelect: boolean = false;
  AppEng: any = [];
  max: number = 50;
  catSmartObject: any;
  catApp: any;
  smartCatIds: any = []
  catIds: any;
  appCatIds: any = []
  searchForm: any;

  searchValue: any
  groupByApp: any;
  applicationmobile: any = [];
  applicationWeb: any = [];
  slug: any;
  appName: any;




  constructor(public formBuilder: FormBuilder, public event: EventsService,
    private service: ApiService,
    private common: CommonService,
    public router: Router) {
    this.GetMobile()

  }

  ngOnInit(): void {
    let data: any = JSON.parse(localStorage.getItem('B-in-Touch') || '{}')
    this.event.publishSomeData(data.theme_mode)
    if (data.theme_mode == 'light') {     // light mode
      var a: any = document.getElementById("das");
      a.className += " light-theme";
    } else {              // dark mode
      var a: any = document.getElementById("das");
      a.classList.remove('light-theme');
    }
    this.totalScanData();
    this.canvas = document.getElementById('myChart');
    let contactValue: any = JSON.parse(localStorage.getItem('B-in-Touch') || '{}')
    this.checked = contactValue.theme_mode == 'dark' ? false : true
    this.smartEngagements('');
    // this.fetchApplicationsItems();
    this.fetchSmartObjectItems();
    // this.fetchSavedObjects('app');
    this.smartObjectcat();
    this.appContactList()

    this.ApplicationGroup()
  }

  // custm-select 
  selectCustm(e: any) {
    console.log(e)
    if (e === 'pieApp') {
      this.showSelectMenu = !this.showSelectMenu;
    }
    if (e === 'smartactivity') {
      this.showActivitySelect = !this.showActivitySelect;
    }
  }

  fetchSmartObjectItems() {
    if (!this.smartObjectItems.length) {
      this.service.getuserSmartobject('').subscribe((res: any) => {
        if (res.success) {
          this.smartObjectItems = res.items;
          //console.log(this.smartObjectItems)
        }
      });
    }
  }

  fetchApplicationsItems() {
    if (!this.applicationItems.length) {
      this.service.getApplicationList('').subscribe((res: any) => {
        if (res.success) {
          this.applicationItems = res.items;
          //console.log(this.applicationItems)
        }
      });
    }
  }

  selectApplication(value:any, slug:any, name:any) {
    this.slugValue = value
    this.slug = slug
    this.appName = name
    this.groupByApp.forEach((element:any) => {
      element.data.forEach((element1:any) => {
        console.log(element1.slug +"=="+ this.slug)
        if(element1.slug == this.slug){
          element1.check = true
        }else{
        element1.check = false
        }
      });
    });
    this.applicationFooterGraph();
  }

  selectSmartObject() {
    this.getActivity()
  }

  flipCard(e: any) {
    if (e == 'first') {
      this.firstFlip = !this.firstFlip;

    } else if (e == 'second') {
      this.secondFlip = !this.secondFlip;
    }
    else if (e == 'third') {
      this.thirdFlip = !this.thirdFlip;
    }
  }

  openMore() {
    this.showMore = !this.showMore;
  }
  openFilter() {
    this.showFilters = !this.showFilters;
  }

  /* === Count of Scan app  === */
  totalScanData() {
    this.service.getTotalScans('').subscribe((res: any) => {
      this.totalContactData();
      this.totalMoneySavedData();
      this.Smartobject();
      this.fetchSavedObjects('app');
      this.getActivity();
      if (res['success'] == 1) {
        this.totalScan = res.total
        this.smartObject = res.tag_count
      }
      else if (res.http_status == '401') {
        this.common.error('You have been logged out for security purpose!');
        this.router.navigate(['']);
      } else {
        this.common.error(res.msg);
      }
    })
  }

  /* === Count of new Contact  === */
  totalContactData() {
    this.service.getTotalContact('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.totalContact = res.total
        this.applications = res.services_count
      }
      else {
      }
    })
  }

  /* === Show Total Money === */
  totalMoneySavedData() {
    this.service.getTotalMoney('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.totalMoney = res.amount / 100;
        this.treeSaved = res.count
      }
    })
  }

  /* === Smart object list=== */
  Smartobject() {
    this.service.getsmartobject('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.smartobjects = res.items
        this.smartobjects.forEach((element: any) => {
          this.seriess.push(element.seriess)
        });
      }
      else {
      }
    })
  }

  /* === App Graph === */
  graph() {
    this.pieType = 'app';
    setTimeout(() => {
      this.ctx = null;
      const canvas: any = document.getElementById('myChart');
      this.ctx = canvas.getContext('2d');
      this.service.appGraph('').subscribe((res: any) => {
        this.doughnutkeylist = [];
        if (res['success'] == 1) {
          let doughnutChartData: any[] = [];

          let labels: any[] = [];
          if (res.items.length) {
            res.items.forEach((element: any, index: number) => {
              labels.push(element.slug);
              doughnutChartData.push(parseFloat(element.count));
              if (index == 0) {
                this.doughnutkeylist.push({ key: element.slug, color: 'rgba(202, 119, 227, 1)', value: element.name })
              }
              if (index == 1) {
                this.doughnutkeylist.push({ key: element.slug, color: 'rgba(110, 63, 173, 1)', value: element.name })
              }
              if (index == 2) {
                this.doughnutkeylist.push({ key: element.slug, color: 'rgba(237, 143, 108, 1)', value: element.name })
              }
              if (index == 3) {
                this.doughnutkeylist.push({ key: element.slug, color: 'rgba(32, 140, 208, 1)', value: element.name })
              }
              if (index == 4) {
                this.doughnutkeylist.push({ key: element.slug, color: 'rgba(115, 245, 220, 1)', value: element.name })
              }
              if (index == 5) {
                this.doughnutkeylist.push({ key: element.slug, color: 'rgba(245, 107, 144, 1)', value: element.name })
              }
              // if (index == 6) {
              //   this.doughnutkeylist.push({ key: element.slug, color: '#316B83', value: element.name })
              // }

            });
            //console.log(this.doughnutkeylist)
          }
          const myChart = new Chart(this.ctx, {
            type: 'doughnut',
            data: {
              labels,
              datasets: [{
                label: 'Total cases.',
                data: doughnutChartData,
                backgroundColor: [
                  //'green', 'red', 'blue', '#FAEEE0', '#5C7AEA', '#345B63', '#316B83'
                  "rgba(202, 119, 227, 1)",
                  "rgba(110, 63, 173, 1)",
                  "rgba(237, 143, 108, 1)",
                  "rgba(32, 140, 208, 1)",
                  "rgba(115, 245, 220, 1)",
                  "rgba(245, 107, 144, 1)"
                ],
                // backgroundColor: ['red', 'orange', 'blue', '#FFA6D5', '#9CC094', '#FF9292', '#FFF9B6', '#D8E9A8', '#D1E8E4', '#B2F9FC', '#334756', '#FAEEE0', '#5C7AEA', '#345B63', '#316B83'],
                borderWidth: 0,
                weight: 5,
              }]
            },
            options: {
              events: ['click'],
              legend: {
                display: false
              },
              responsive: true,
              cutoutPercentage: 78,
              maintainAspectRatio: false,
            }
          });
        }
      })
    }, 1500)
  }

  /* === Activity History Graph === */
  getActivity() {
    let params = {};
    if (this.smartObjValue) {
      params = { tag_id: this.smartObjValue }
    }
    this.service.ActivityHistoryPerDay(params, this.durationType).subscribe((res: any) => {
      let max = Math.max(...res.items.map((o: any) => o.total))
      this.max = max
      if (res['success'] == 1) {
        this.days = [];
        this.linevalue = [];
        for (let j = 0; j < res.items.length; j++) {
          switch (this.durationType) {
            case 'DAY': {
              this.days.push(res.items[j].created_on.slice(-5));
              this.linevalue.push(res.items[j].total);
              break;
            }
            case 'MONTH': {
              this.days.push(res.items[j].scan_month.slice(-2));
              this.linevalue.push(res.items[j].total);
              break;
            }
            case 'YEAR': {
              this.days.push(res.items[j].scan_year);
              this.linevalue.push(res.items[j].total);
              break;
            }
            default: {
              this.days.push(res.items[j].created_on.slice(-5));
              this.linevalue.push(res.items[j].total);
              break;
            }
          }
        }
        this.linechart = null;
        let canvaslineApp: any = document.getElementById('areaChart_2');
        this.linechart = canvaslineApp.getContext('2d');
        const areaChart_2gradientStroke = this.linechart.createLinearGradient(0, 1, 0, 500);
        // areaChart_2gradientStroke.addColorStop(0, "rgba(26, 73, 180, 1)");
        // areaChart_2gradientStroke.addColorStop(1, "rgba(35, 44, 82, 1)");
        if (this.checked == false) {
          areaChart_2gradientStroke.addColorStop(0, "rgba(26, 73, 180, 1)");
          areaChart_2gradientStroke.addColorStop(1, "rgba(35, 44, 82, 1)");
        } else if (this.checked == true) {
          areaChart_2gradientStroke.addColorStop(0, "rgba(126, 164, 196, 1)");
          areaChart_2gradientStroke.addColorStop(1, "rgba(77, 109, 136, 1)");
        }
        const myChart = new Chart(this.linechart, {
          type: 'line',
          data: {
            labels: this.days,
            datasets: [{
              label: 'Total cases.',
              data: this.linevalue,
              borderColor: "#147AD6",
              borderWidth: 3,
              backgroundColor: areaChart_2gradientStroke,
              pointRadius: 0
            }]
          },
          options: {
            legend: { display: false },
            events: ['click'],
            plugins: {
              tooltip: {
                // Tooltip will only receive click events
                events: ['click']
              }
            },
            scales: {
              yAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                  max: max == 0 ? 50 : max,
                  min: 0,
                  stepSize: 10,
                  padding: 5
                }

              }],
              xAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  padding: 5
                }
              }]
            }
          }
        });
      }
    })
  }

  ligthDarkVersion() {
    if (this.checked) {
      var a: any = document.getElementById("das");
      a.className += " light-theme";
      this.event.publishSomeData("light")
      this.getActivity()
    } else {
      var a: any = document.getElementById("das");
      a.classList.remove('light-theme');
      this.event.publishSomeData("dark")
      this.getActivity()
    }
    this.themeChange();
  }


  themeChange() {
    let value = this.checked == true ? 'light' : 'dark';
    var data = new FormData
    data.append('theme_mode', value)
    this.service.themeSelection(data).subscribe((res: any) => {
      //console.log(res)
      if (res["success"] == 1) {
        localStorage.setItem('B-in-Touch', JSON.stringify(res["user"]))
      }
    });
  }
  activityData(value: any) {
    this.activityType = value;
    if (value == 'app') {
      this.fetchApplicationsItems();
      this.applicationFooterGraph();
    } else if (value == 'smart') {
      this.fetchSmartObjectItems();
      this.getActivity()
    }
  }
  pieData(value: any) {
    this.pieType = value;
    if (this.pieType == 'app') {
      this.fetchSavedObjects('app');
    }
    if (this.pieType == 'smart') {
      this.fetchSavedObjects('smart');
    }
  }
  fetchSavedObjects(type: string) {
    this.service.get_meta_data(type).subscribe((response: any) => {
      if (response.success && type == 'app') {
        if (response.item.meta_value) {
          this.savedAppObject = response.item.meta_value.split(',');

        }
        this.graph();
      }
      if (response.success && response.item.meta_value && type == 'smart') {
        this.savedSmartObject = response.item.meta_value;
        this.smartObjectGraph();
      }
    });
  }
  removeObject(item: any, type: string) {
    if (type == 'app') {
      const index: number = this.savedAppObject.indexOf(item);
      if (index !== -1) {
        this.savedAppObject.splice(index, 1);
        let data = '';
        this.savedAppObject.forEach(item => {
          data = data.length ? (data + ',' + item) : item;
        });
        this.postObject('app', data);
      }
    }
    if (type == 'smart') {
      const index: number = this.savedSmartObject.findIndex(x => x.tag_id == item.tag_id);
      if (index !== -1) {
        let data = '';
        this.savedSmartObject.splice(index, 1);
        this.savedSmartObject.forEach(item => {
          data = data.length ? (data + ',' + item.tag_id) : item.tag_id;
        });
        this.postObject('smart', data);
      }
    }
  }
  accordianData(value: any) {
    this.accordianType = value;
    if (value == 'app') {
      this.appEngagements('')
    } else if (value == 'smart') {
      this.smartEngagements('')
    }
  }


  // version 2 samrt Object work
  smartObjectGraph() {
    this.pieType = 'smart';
    setTimeout(() => {
      this.ctxSmart = null;
      const canvas: any = document.getElementById('smartChart');
      this.ctxSmart = canvas.getContext('2d');
      this.service.smartObjectGraph({ graph_type: 'pie' }).subscribe((res: any) => {
        this.doughnutkeylist = [];
        if (res['success'] == 1) {
          let doughnutChartData: any[] = [];

          let labels: any[] = [];
          if (res.items.length) {
            res.items.forEach((element: any, index: number) => {
              labels.push(element.tag_name);
              doughnutChartData.push(parseFloat(element.total));
              if (index == 0) {
                this.doughnutkeylist.push({ key: element.tag_name ? element.tag_name : 'B-in-touch', color: 'rgba(202, 119, 227, 1)', value: element.tag_name })
              }
              if (index == 1) {
                this.doughnutkeylist.push({ key: element.tag_name, color: 'rgba(110, 63, 173, 1)', value: element.tag_name })
              }
              if (index == 2) {
                this.doughnutkeylist.push({ key: element.tag_name, color: 'rgba(237, 143, 108, 1)', value: element.tag_name })
              }
              if (index == 3) {
                this.doughnutkeylist.push({ key: element.tag_name, color: 'rgba(32, 140, 208, 1)', value: element.tag_name })
              }
              if (index == 4) {
                this.doughnutkeylist.push({ key: element.tag_name, color: 'rgba(115, 245, 220, 1)', value: element.tag_name })
              }
              if (index == 5) {
                this.doughnutkeylist.push({ key: element.tag_name, color: 'rgba(245, 107, 144, 1)', value: element.tag_name })
              }
              // if (index == 6) {
              //   this.doughnutkeylist.push({ key: element.tag_name, color: '#316B83', value: element.tag_name })
              // }
            });
            //console.log(this.doughnutkeylist)
          }
          const myChart = new Chart(this.ctxSmart, {
            type: 'doughnut',
            data: {
              labels,
              datasets: [{
                label: 'Total cases.',
                data: doughnutChartData,
                backgroundColor: [
                  "rgba(202, 119, 227, 1)",
                  "rgba(110, 63, 173, 1)",
                  "rgba(237, 143, 108, 1)",
                  "rgba(32, 140, 208, 1)",
                  "rgba(115, 245, 220, 1)",
                  "rgba(245, 107, 144, 1)"
                ],
                borderWidth: 0,
                weight: 5,
              }]
            },
            options: {
              events: ['click'],
              legend: {
                display: false
              },
              responsive: true,
              cutoutPercentage: 78,
              maintainAspectRatio: false,
            }
          });
        }
      })
    }, 1500)
  }
  selectDuration(type: string) {
    this.durationType = type;
    if (this.activityType == 'smart') {
      this.getActivity();
    }
    if (this.activityType == 'app') {
      this.applicationFooterGraph();
    }
  }

  applicationFooterGraph() {
    let params = {};
    if (this.slugValue) {
      //console.log(this.slugValue)
      params = { slug: this.slug, service_id: this.slugValue };
    }
    this.service.ApplicationGraph(params, this.durationType).subscribe((res: any) => {
      let max = Math.max(...res.items.map((o: any) => o.total))
      this.max = max
      if (res['success'] == 1) {
        this.days = [];
        this.linevalue = [];
        for (let j = 0; j < res.items.length; j++) {
          switch (this.durationType) {
            case 'DAY': {
              this.days.push(res.items[j].created_on.slice(-5));
              this.linevalue.push(res.items[j].total);
              break;
            }
            case 'MONTH': {
              this.days.push(res.items[j].click_month.slice(-2));
              this.linevalue.push(res.items[j].total);
              break;
            }
            case 'YEAR': {
              this.days.push(res.items[j].click_year);
              this.linevalue.push(res.items[j].total);
              break;
            }
            default: {
              this.days.push(res.items[j].click_month.slice(-5));
              this.linevalue.push(res.items[j].total);
              break;
            }
          }
        }
        this.linechartApp = null;
        let canvaslineApp: any = document.getElementById('applicationChart');
        this.linechartApp = canvaslineApp.getContext('2d');
        const areaChart_2gradientStroke = this.linechartApp.createLinearGradient(0, 1, 0, 500);
        if (this.checked == false) {
          areaChart_2gradientStroke.addColorStop(0, "rgba(26, 73, 180, 1)");
          areaChart_2gradientStroke.addColorStop(1, "rgba(35, 44, 82, 1)");
        } else if (this.checked == true) {
          areaChart_2gradientStroke.addColorStop(0, "rgba(126, 164, 196, 1)");
          areaChart_2gradientStroke.addColorStop(1, "rgba(77, 109, 136, 1)");
        }
        const myChart = new Chart(this.linechartApp, {
          type: 'line',
          data: {
            labels: this.days,
            datasets: [{
              label: 'Total cases.',
              data: this.linevalue,
              borderColor: "#147AD6",
              borderWidth: 3,
              backgroundColor: areaChart_2gradientStroke,
              pointRadius: 0
            }]
          },
          options: {
            legend: { display: false },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            scales: {
              yAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                  max: max == 0 ? 50 : max,
                  min: 0,
                  stepSize: 10,
                  padding: 5
                }

              }],
              xAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  padding: 5
                }
              }]
            }
          }
        });
      }
    });
  }
  appEngagements(value: any) {
    let data = {
      cat_ids: this.catIds,
      s: value
    }
    this.service.appEng(data).subscribe((res: any) => {
      //console.log(res)
      if (res.http_status == 200) {
        this.AppEng = res.items
        //console.log(this.AppEng)

        //  this.AppEng.((element:any) => {
        //    //console.log(element)
        //  });
      }

    })
  }



  smartEngagements(value: any) {
    let data = {
      cat_ids: this.catIds,
      s: value
    }
    this.service.smartEng(data).subscribe((res: any) => {
      //console.log(res)
      if (res.http_status == 200) {
        this.smartObjectEng = res.items
      }

    })
  }

  addObject(type: string, slug?: any) {
    //console.log(type, slug)
    if (type == 'app') {
      //console.log(this.savedAppObject)
      if (this.savedAppObject.length <= 5) {
        const object = this.savedAppObject.filter(x => x == slug);
        //console.log(object)
        if (!object.length) {
          let data = '';
          this.savedAppObject.push(slug);
          this.savedAppObject.forEach(item => {
            data = data.length ? (data + ',' + item) : item;
          });
          this.postObject('app', data);
        } else {
          this.smartObjectName = '';
          this.common.error('This object is already saved, please try another object');
        }
      } else {
        this.common.error('you can only add 6 or less applications');
      }
    }
    if (type == 'smart') {
      if (this.savedSmartObject.length <= 5) {
        let data = '';
        const index: number = this.smartObjectItems.findIndex(x => x.tag_id == this.smartObjectName);
        if (index !== -1) {
          const object = this.savedSmartObject.filter(x => x.tag_id == this.smartObjectItems[index].tag_id)
          if (!object.length) {
            this.savedSmartObject.push(this.smartObjectItems[index]);
            this.savedSmartObject.forEach(item => {
              data = data.length ? (data + ',' + item.tag_id) : item.tag_id;
            });
            this.postObject('smart', data);
          } else {
            this.smartObjectName = '';
            this.common.error('This object is already saved, please try another object');
          }
        }
      } else {
        this.common.error('you can only add 6 or less smart objectss');
      }
    }
  }

  getSavedData(type: string) {
    if (type == 'app') {
      return this.savedAppObject;
    }
    if (type == 'smart') {
      return this.savedSmartObject;
    }
    return [];
  }
  postObject(type: string, data: any) {
    this.service.save_meta_data(type, data)
      .subscribe((response: any) => {
        if (response.success) {
          this.fetchSavedObjects(type);
        }
      });
  }

  smartObjectcat() {
    this.service.GetObjectCategories().subscribe((res: any) => {
      //console.log(res)
      if (res.http_status == 200) {
        this.catSmartObject = res.items;
        this.catSmartObject.forEach((element: any) => {
          element.isChecked = false
        });
      }
    })
  }

  appContactList() {
    this.service.GetApplicationCategories().subscribe((res: any) => {
      //console.log(res)
      if (res.success == 1) {
        const objectMobile = {
          name: "Mobile",
          id: "-1"
        }
        this.catApp = res.items;
        this.catApp.push(objectMobile)
        // //console.log(this.catApp)
        this.catApp.forEach((element: any) => {
          element.isChecked = false
        });
      }
    })
  }
  choosecatSmart(value: string, event: MatCheckboxChange, index: number) {
    if (event.checked) {
      this.catSmartObject[index].isChecked = true;
      this.smartCatIds.push(value);
    } else {
      this.catSmartObject[index].isChecked = false;
      var i = this.smartCatIds.indexOf(value);
      this.smartCatIds.splice(i, 1);
    }
    this.catIds = this.smartCatIds.toString();
    this.smartEngagements('')
  }

  choosecatapp(value: string, event: MatCheckboxChange, index: number) {
    if (event.checked) {
      this.catApp[index].isChecked = true;
      this.appCatIds.push(value);
    } else {
      this.catApp[index].isChecked = false;
      var i = this.smartCatIds.indexOf(value);
      this.appCatIds.splice(i, 1);
    }
    this.catIds = this.appCatIds.toString();
    this.appEngagements('')
  }


  ApplicationGroup() {
    // let data = {
    //   group_by:1
    // }
    let value: any = 1
    const formData = new FormData();
    formData.append('group_by', value);
    this.service.groupByApplication(formData).subscribe((res: any) => {
      if (res.http_status == 200) {
        this.groupByApp = res.items
        console.log( this.groupByApp)
        let mobileObject = {
          cat_name: 'Mobile',
          data: this.applicationmobile
        }
        let webObject = {
          cat_name: 'Web',
          data: this.applicationWeb
        }
        this.groupByApp.push(mobileObject)
        this.groupByApp.push(webObject)
        this.groupByApp.forEach((element:any) => {
          element.data.forEach((element1:any) => {
            element1.check = false
          });
        });
        if (this.groupByApp[0].cat_name == "") {
          this.groupByApp.splice(0, 1);
        }
      }
    })

  }

  search() {
    var value = $('#search').html();
    // if(value.length > 3){
    if (this.accordianType == 'app') {
      this.appEngagements(value)
    } else if (this.accordianType == 'smart') {
      this.smartEngagements(value)
      // }
    }
  }

  GetMobile() {
    this.service.getApplicationList('').subscribe((res: any) => {
      if (res.success == 1) {
        res.items.forEach((element: any) => {
          if (element.source == 'primary') {
            this.applicationmobile.push(element)
          }
          if (element.source == "") {
            this.applicationWeb.push(element)
          }
        });
      }
    }, error => {

    })
  }

}
