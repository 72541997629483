<div class="main-wrap">
    <div class="template_header">
        <img src="assets/images/ft-logo.png" alt="logo-image" />
        <a>Get Your Card</a>
    </div>
    <div class="banner-wrap">
        <div class="banner-img-wrap"> 
            <div class="upload-btn">
                <a >
                    <img class="cover-img" *ngIf="contact.cover_photo" [src]="coverImg" alt="icon">
                    <img *ngIf="!contact.cover_photo" src="assets/images/upload-image.png" alt="icon">
                </a>
                <input type="file" id="myFile" name="filename">
            </div>
        </div>
        <div class="profile-img-wrap">
            <div class="img_wrap">
                <img *ngIf="contact.photo" [src]="profImg" alt="profile-img" />
                <img *ngIf="!contact.photo" src="assets/images/contact-profile4/img.png" alt="profile-img" />
            </div>
   
            <h4 *ngIf="name">{{name?name:''}}</h4>
            <p *ngIf="contact.title">{{contact.position?contact.position:''}} {{contact.company?'at':''}} {{contact.company?contact.company:''}}</p>
        </div>
    </div>
    <div class="upper-info">
        <div class="social-wrap">
            <ul>
                <!-- [ngClass]="!contact.facebook?'disableimage':''" -->
                <li *ngIf="contact.facebook"><a (click)="contact.facebook?iconsClick(contact.facebook, 'facebook'):''" ><img src="assets/images/contact-profile4/fb-shadow.png" alt=""/></a></li>
                <li *ngIf="contact.instagram"><a (click)="contact.instagram?iconsClick(contact.instagram, 'instagram'):''"><img src="assets/images/contact-profile4/instagram-shadow.png" alt=""/></a></li>
                <li *ngIf="contact.linkedin"><a (click)="contact.linkedin?iconsClick(contact.linkedin, 'linkedin'):''"><img src="assets/images/contact-profile4/Linkedin.png" alt=""/></a></li> 
                <li *ngIf="contact.tiktok"><a (click)="contact.tiktok?iconsClick(contact.tiktok, 'instagram'):''"><img src="assets/images/contact-profile4/Tiktok.png" alt=""/></a></li>
                <li *ngIf="contact.youtube"><a (click)="contact.youtube?iconsClick(contact.youtube, 'youtube'):''" ><img src="assets/images/contact-profile4/youtube-shadow.png" alt=""/></a></li>
                <li *ngIf="contact.snapchat"><a (click)="contact.snapchat?iconsClick(contact.snapchat, 'snapchat'):''"><img src="assets/images/contact-profile4/snapchat-shadow.png" alt=""/></a></li>
                <li *ngIf="contact.twitter"><a (click)="contact.twitter?iconsClick(contact.twitter, 'twitter'):''"><img src="assets/images/contact-profile4/twitter-shadow.png" alt=""/></a></li>
            </ul>
        </div>
        <div class="profile-info-wrap">
            <div class="c-btns">
                <a class="a1"(click)="addContact()" >Add To Contacts</a>
                <a class="a2" (click)="moveToPage()">Share Your Contact</a>
            </div>
            <p class="profile-para" *ngIf="contact.note">{{contact.note?contact.note:''}}</p>
            <div class="contact_wrap" *ngIf="contact.phone || contact.work_phone || contact.email || contact.website || contact.whatsapp || contact.messenger || contact.zoom">
                <h4>Contact Me</h4>
                <div class="contact-box" *ngIf="contact.phone" (click)="iconsClick(contact.phone, 'phone')">
                    <img src="assets/images/contact-profile2/phone.png" alt="icon"/>
                    <p>{{contact.phone?contact.phone:''}}</p>
                </div>
                <div class="contact-box" *ngIf="contact.work_phone" (click)="iconsClick(contact.work_phone, 'phone')">
                    <img src="assets/images/contact-profile2/job-phone.png" alt="icon"/>
                    <p>{{contact.work_phone?contact.work_phone:''}}</p>
                </div>
                <div class="contact-box" *ngIf="contact.email" (click)="iconsClick(contact.email, 'email')">
                    <img src="assets/images/contact-profile2/email.png" alt="icon"/>
                    <p>{{contact.email?contact.email:''}}</p>
                </div>
    
                <div class="contact-box" *ngIf="contact.website" (click)="iconsClick(contact.website, 'chrome')">
                    <img src="assets/images/contact-profile2/globe.png" alt="icon"/>
                    <p>{{contact.website?contact.website:''}}</p>
                </div>
                <div class="contact-box" *ngIf="contact.whatsapp" (click)="iconsClick(contact.whatsapp, 'whatsApp')">
                    <img src="assets/images/contact-profile2/watsapp.png" alt="icon"/>
                    <p>{{contact.whatsapp?contact.whatsapp:''}}</p>
                </div>
                <div class="contact-box" *ngIf="contact.messenger" (click)="iconsClick(contact.messenger, 'messenger')">
                    <img src="assets/images/contact-profile2/messanger.png" alt="icon"/>
                    <p>{{contact.messenger?contact.messenger:''}}</p>
                </div>
                <div class="contact-box" *ngIf="contact.zoom" (click)="iconsClick(contact.zoom, 'zoom')">
                    <img src="assets/images/contact-profile2/zoom-call.png" alt="icon"/>
                    <p>{{contact.zoom?contact.zoom:''}}</p>
                </div>
            </div>
        </div>
    </div>
    <hr class="half-border">
    <div class="lower-info">
        <div class="map_wrap" *ngIf="contact.address || contact.address_line2 || contact.city || contact.postcode || contact.country" >
            <h4>Locate Me</h4>
            <div #map style="width:100%; height:250px"></div>
            <div class="map-btn">
                <a class="grad-btn map-btn-bg " (click)="iconsClick(address, 'googleMaps')">Get Directions</a>
            </div>
        </div>
        <div class="pay_wrap" *ngIf="contact.paypal || contact.lydia">
            <h4>Contact Me</h4>
            <div class="pay-box" *ngIf="contact.paypal" (click)="iconsClick(contact.paypal, 'paypal')">
                <img src="assets/images/contact-profile2/paypal.png" alt="icon"/>
                <p>{{contact.paypal?contact.paypal:''}}</p>
            </div>
            <div class="pay-box" *ngIf="contact.lydia" (click)="iconsClick(contact.lydia, 'lydia')">
                <img src="assets/images/contact-profile2/lydia.png" alt="icon"/>
                <p>{{contact.lydia?contact.lydia:''}}</p>
            </div>
        </div>
        <div class="extra-btns" *ngIf="contact.link_url" (click)="Link_Title()">
            <a class="a1" >{{contact.link_title?contact.link_title:'Link'}}</a>
        </div>
        <div class="extra-btns" *ngIf="contact?.document_url || contact?.document_file" (click)="file_Title()">
            <a class="a2" >{{contact.document_title?contact.document_title:'Document'}}<img src="assets/images/contact-profile2/btn-download.png" alt="icon"/></a>
        </div>
        <p class="footer-p">All Rights Reserved. ©2021 B-in-Touch.fr</p>
    </div>
    <div class="template_footer">
        <p>Be-In-Touch</p>
        <small>Website</small>
    </div>
</div>
