import { Component, OnInit } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.scss']
})
export class NewContactComponent implements OnInit {
  baseUrl = environment.baseURL;
  contactId: any;

  constructor(private _bottomSheetRef: MatBottomSheetRef<NewContactComponent>,private common: CommonService, private auth: ApiService) { }

  ngOnInit(): void {
    this.contactId = localStorage.getItem('contact')
  }

  okButton(){
     const token = this.common.getToken();
    if (token) {
      let data = { 'contact_id': this.contactId };
      this.auth.saveSharedContact(data).subscribe((response: any) => {
        if (response.success == 1) {
          this.common.success(response.msg);
        } else {
         // this.common.error(response.msg);
        }
      }, (e) => {
       // this.common.error(e.error.msg);
      })
    }

    if (this.contactId) {
      const encodedStringBtoA = btoa(this.contactId);
     window.open(`${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`, '_blank');
    }
    this._bottomSheetRef.dismiss();
  }

  closeBtn(){
    this._bottomSheetRef.dismiss();
  }

}
