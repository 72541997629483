<div class="row no-gutters p-4 new_application">
    <div class="col-sm-3 table_tabs">
        <div class="nav flex-column nav-pills mb-0">
            <a href="#v-pills-home" data-toggle="pill" class="nav-link active show" (click)="applicationData()"><i
                    class="mr-2">
                    <svg id="home-white-18dp" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Path_771" data-name="Path 771" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Path_772" data-name="Path 772" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" fill="#fff" />
                    </svg>
                </i> Home</a>
            <div>
                <div id="div1">
                    <a class="nav-link d-flex justify-content-between" (click)="showMobile()">Mobile
                    </a>
                </div>
                <ng-container *ngFor="let contacts of CategoryList">
                    <a class="nav-link" (click)="getFilterApplicationListing(contacts.id)">{{contacts?.name}} </a>

                </ng-container>
            </div>

        </div>

    </div>
    <div class="col-sm-9">
        <div class="card">
            <div class="tab-content p-3">
                <div id="v-pills-home" class="tab-pane fade active show">
                    <div class="table-responsive custom_table custom_scrollbar">
                        <table class="table bg-warning-hover text-left">
                            <thead>
                                <tr class="text_white">

                                    <th class="text_white">Application Name</th>
                                    <th class="text_white text-center">Link</th>
                                </tr>
                            </thead>
                            <tbody class="fs14">
                                <tr *ngFor="let body of applicationDetails">
                                    <td [ngClass]="{'text_white font-weight-bold' : appId == body.id }">
                                        <div class="d-inline-flex align-items-center">
                                            <span *ngIf="body.name !=''">{{body.name}}</span>
                                            <span *ngIf="body.name ==' '">N/A</span>

                                        </div>
                                    </td>
                                    <td (click)="urlAssign(body)">
                                        <div class="editDelete justify-content-center">
                                            <a class="link_btn"><img src="assets/images/link_btn.svg" /></a>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>