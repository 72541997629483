import { Component, Inject, Input, OnInit } from '@angular/core';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-application-name',
  templateUrl: './application-name.component.html',
  styleUrls: ['./application-name.component.scss']
})
export class ApplicationNameComponent implements OnInit {
  data: any;
  @Input() fromParent: any;
  EditnameForm: FormGroup

  constructor(private service: ApiService,
    private common: CommonService,
    private modalService: MatDialog,
    public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ApplicationNameComponent>,
  ) { }

    /* === Create App name in Application List=== */
  ngOnInit(): void {
    this.EditnameForm = this.formBuilder.group({
      title: [''],
    })
    this.EditnameForm.patchValue({
      title: this.fromParent.name
    })
  }

  submit() {
    this.spinner.show()
    var data = new FormData
    data.append('slug', this.fromParent.slug);
    data.append('id', this.fromParent.id)
    data.append('title', this.EditnameForm.value.title);
    this.service.editnameApp(data).subscribe((res: any) => {
      console.log()
      this.spinner.hide()
      if (res.http_status == 200) {
        this.dialogRef.close();
      } else {
        this.common.error(res["msg"]);
      }
    })

  }

  close() {
    this.dialogRef.close();
  }

}
