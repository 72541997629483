<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-start align-items-sm-center align-items-sm-center mb-5">
            <form [formGroup]="Appname" class="w-sm-100">
                <div class="d-flex align-items-start justify-content-start mr-auto ml-sm-0 flex-column flex-sm-row">
                    <h2 class="font-w600 mb-3 mb-sm-0 mr-auto text_opacity_68 letterspace">Overview / Email &lt;</h2>
                    <input class="app_name" placeholder="Application Name" formControlName="title">
                </div>
            </form>
        </div>


        <div class="row ">

            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll height540 custom_scrollbar d-flex flex-column">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title fs21 text_transform_none">Prompt Any phone to send an email to any
                                email address</h4>
                                <div class="graphic_icon text-right d-block d-sm-none">
                                    <img class="img-fluid" src="assets/images/email_img.png" />
                                </div>
                            </div>
                            
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-8">
                                    <form class="bform text_white" [formGroup]="AppEmail">
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label class="fs17 font-weight-bold">Email Address</label>
                                                <input type="text" class="form-control" formControlName="email">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label class="fs17 font-weight-bold">Subject</label>
                                                <input type="text" class="form-control" formControlName="subject">
                                            </div>
                                        </div>
                                        <!-- Form row end -->

                                        <div class="form-row mt-2">
                                            <div class="form-group col-md-12">
                                                <label class="fs17 font-weight-bold">Content </label>
                                                <textarea class="form-control" rows="6"
                                                    formControlName="message"></textarea>
                                            </div>
                                        </div>
                                        <!-- Form row end -->
                                        <button (click)="submit()"
                                            class="btn btn-primary btn-block rounded-pill mt-4 w-50 mx-auto">Submit</button>
                                    </form>
                                </div>
                                <div class="col-md-4">
                                    <div class="graphic_icon text-right d-none d-sm-block">
                                        <img class="img-fluid" src="assets/images/email_img.png" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer border-0 border_half_width py-0">
                            <hr />
                            <p class="text-center fs17 mb-0">If you want people to send you an email with minimum
                                effort, you can get their phones to do that
                                with a tap with your smart object</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-12">

                <div class="card position-relative">
                    <div class="card_header_pre position-absolute">
                        <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    </div>

                    <div class="preview_main text-center">
                        <img class="img-fluid" src="assets/images/preview_jpg9.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner>