<ng-container *ngIf="slug == 'lost_found'">
    <app-app-lost-found></app-app-lost-found>
</ng-container>

<ng-container *ngIf="slug == 'emergency'">
        <app-app-emergency></app-app-emergency>
</ng-container>

<ng-container *ngIf="slug == 'emenu'">
<app-emenu-card></app-emenu-card>
</ng-container>

<ng-container *ngIf="slug == 'linktree'">
 <app-app-link-tree></app-app-link-tree>
</ng-container>

<ng-container *ngIf="slug == 'contact'">
    <app-contact-app-page></app-contact-app-page>
</ng-container>