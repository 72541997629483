import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../services/common/common.service';

@Component({
  selector: 'app-contact-app-page',
  templateUrl: './contact-app-page.component.html',
  styleUrls: ['./contact-app-page.component.scss']
})
export class ContactAppPageComponent implements OnInit {

  contact:any=[];
  slugImg = environment.service_icons;
  imgUrl = environment.imageUrl;
  baseUrl = environment.baseURL;
  socialLinks:any=[];
  name:string='';
  profImg:any;
  coverImg:any;
  address:any='';
  background:any;
  buttonStyle:any;
  buttonColor:any;
  iconStyle:any;
  textColor:any;
  isnoData: boolean;
  id: any;
  slug: any;

  constructor(private auth:ApiService, private router:Router,private route:ActivatedRoute,private common:CommonService, public activeroute: ActivatedRoute) { 
    // const data = localStorage.getItem('contact');
    // (data) ? this.contact = JSON.parse(data) :  this.router.navigate(['/']);
    this.activeroute.params.subscribe((params:any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if(this.id){
      this.getDetails()
      }else{
          this.router.navigate(['/']);
        }
    })
  }

  ngOnInit(): void {
    // this.makeData();
    // console.log("hello enter new page")
  }


  // makeData(){
  //   if(this.contact.length != 0){
  //     this.name = this.contact.first_name + this.contact.last_name;
  //     this.profImg = this.imgUrl + this.contact.photo;
  //     this.address = `${this.contact.address},${this.contact.postcode},${this.contact.city} ${this.contact.country}`;  
  //     this.background = (this.contact.bg_color == '') ?  '#124496' :this.contact.bg_color ;
  //     this.buttonStyle = (this.contact.btn_style == 'round') ? '20px' : '0px'; 
  //     this.buttonColor = (this.contact.btn_color == '') ? '#f5f5f5' : this.contact.btn_color;
  //     this.coverImg = this.imgUrl + this.contact.cover_photo;
  //     this.iconStyle  = (this.contact.icon_style ==  'rectangular') ? '0px' : '30px';
  //     this.textColor = (this.contact.text_color == '') ? '#000' : this.contact.text_color ;
  //   }
  // }


  // addContact(){
  //   if(this.contact.contact_id){
  //     const encodedStringBtoA = btoa(this.contact.contact_id);
  //    window.location.href = `${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`;
  //    this.common.success('File Downloaded Successfully');
  //   }
  // }


  // onImgError(event:any){
  //   event.target.src = '../../../assets/images/def_app.png';
  // }

  getDetails() {
    const formData = new FormData();
    formData.append('id',this.id);
    formData.append('slug',this.slug);
    this.auth.getServiceDetail(formData).subscribe((response: any) => {
      if (response.success == 1) {
        this.contact = response.item
        localStorage.setItem(this.slug, JSON.stringify(this.contact));
        console.log(response)
  
 // this.profImg = this.imgUrl + this.contact.photo;
      }
  
    })
  }

}