<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-center mb-5">
            <h2 class="font-w600 mb-0 mr-auto text_opacity_68 letterspace">Overview / Call/SMS</h2>

            <div class="searchbar">
                <form id="demo-2">
                    <input class="form-control" type="search" placeholder="Search here">
                </form>
            </div>
        </div>


        <div class="row ">

            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll height540 custom_scrollbar d-flex flex-column">
                        <div class="card-header border-0 pb-0">
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title fs21 text_transform_none">Prompt Any phone to call a number or send a
                                    text message</h4>
                                <div class="graphic_icon text-right d-block d-sm-none">
                                    <img class="img-fluid" src="assets/images/callSms_img.png" />
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-8">
                                    <form class="bform text_white" [formGroup]="AppForm">
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <div class="radio mb-1">
                                                    <input id="radio-1" class="radio-custom" name="option" type="radio"
                                                        formControlName="option" value="messages"
                                                        (click)="select('messages')">
                                                    <label for="radio-1"
                                                        class="radio-custom-label fs17 font-weight-bold text-white">Send
                                                        SMS</label>
                                                </div>
                                                <div class="pl_2rem mt-4">
                                                    <label class="fs17 font-weight-bold text_opacity_68">Phone
                                                        Number</label>
                                                    <input type="text" class="form-control" formControlName="title"
                                                        placeholder="Enter Phone number with Country code">
                                                </div>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <div class="radio mb-1">
                                                    <input id="radio-2" class="radio-custom" name="option" type="radio"
                                                        formControlName="option" value="phone"
                                                        (click)="select('phone')">
                                                    <label for="radio-2"
                                                        class="radio-custom-label fs17 font-weight-bold text-white">Call
                                                        Phone Number</label>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <p class="fs17 text_opacity_68 mb-0 pl_2rem">Notice: Please put your
                                                    country code before your number. For example: “+1”.</p>
                                            </div>

                                            <div class="form-group col-12 col-lg-8">
                                                <div class="pl_2rem">
                                                    <label class="fs17 font-weight-bold text_opacity_68">Text </label>
                                                    <textarea class="form-control" rows="3"
                                                        formControlName="content"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Form row end -->

                                        <button (click)="submit()"
                                            class="btn btn-primary btn-block rounded-pill mt-4 w-50 mx-auto">Submit</button>


                                    </form>

                                </div>
                                <div class="col-md-4">
                                    <div class="graphic_icon text-right d-none d-sm-block">
                                        <img class="img-fluid" src="assets/images/callSms_img.png" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer border-0 border_half_width py-0">
                            <hr />
                            <p class="text-center fs17 mb-0">If you want people to call a number or send a text message
                                with minimum effort, you can get their phones to
                                do that with a tap with your smart object</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-12">

                <div class="card position-relative">
                    <div class="card_header_pre position-absolute">
                        <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    </div>

                    <div class="preview_main text-center">
                        <img class="img-fluid" src="assets/images/preview_jpg6.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="contact_detail">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="card_header_pre d-flex justify-content-between pb-0">
                    <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    <div class="preview_icons d-flex align-items-center">
                        <a href=""><img src="assets/images/download-24px.svg" /></a>
                        <a href=""><img src="assets/images/create-24px.svg" /></a>
                        <a href=""><img src="assets/images/delete_outline-24px.svg" /></a>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal">
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Path_823" data-name="Path 823"
                            d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                            fill="#b6b8c7" />
                    </svg>
                </button>
                <div class="modal-body">
                    <div class="user_profile d-flex align-items-center">

                        <img src="assets/images/img_user.jpg" alt="" />
                        <div class="user_info d-flex flex-column">
                            <span class="fs17 text_white font-weight-bold">Remi Dupont</span>
                            <span class="fs14 pt-1">ESSEC/Student</span>
                        </div>
                    </div>

                    <ul class="list-unstyled user_info_list pt-3">
                        <li>
                            <label class="fs14 m-0">Phone Number</label>
                            <p class="text_white text_opacity_68">0033616930225</p>
                        </li>
                        <li>
                            <label class="fs14 m-0">Work Number</label>
                            <p class="text_white text_opacity_68">0033616930224</p>
                        </li>
                        <li>
                            <label class="fs14 m-0">Website</label>
                            <p class="text_white text_opacity_68">www.b-intouch.fr</p>
                        </li>
                        <li>
                            <label class="fs14 m-0">Home Address</label>
                            <p class="text_white text_opacity_68">23 Rue Louis Pouey, 92800, Puteaux
                                France</p>
                        </li>
                        <li>
                            <label class="fs14 m-0">Birthday</label>
                            <p class="text_white text_opacity_68">11/04/1991</p>
                        </li>
                        <li>
                            <label class="fs14 m-0">Email </label>
                            <p class="text_white text_opacity_68">aaalfarra1@gmail.com</p>
                        </li>
                        <li>
                            <label class="fs14 m-0">Paypal</label>
                            <p class="text_white text_opacity_68">aboud.atassi.paris@gmail.com</p>
                        </li>
                        <li>
                            <label class="fs14 m-0">Home Address</label>
                            <p class="text_white text_opacity_68">231 Rue Saint honoré, 75001, Paris
                                France</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    </ngx-spinner>