import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/api-service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2'
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';



@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],

})
export class ProfilePageComponent implements OnInit {

  contactName = "";
  userData: any = [];
  fullName = "";
  position = "";
  city = "";
  company = "";
  bio = "";
  country = "";
  services: any = [];
  otherServices: any = [];
  imgUrl = environment.service_icons;
  round_icons = environment.round_icons
  usrImg = environment.imageUrl;
  lost_found = environment.lost_found;
  baseUrl = environment.baseURL;
  slug_image = environment.slug_images;
  qr_image = environment.qr_images;
  round_icn = environment.round_icons;
  photo = "";
  contactId: any;
  buttonStyle: any;
  buttonback: any;
  headingColor: any;
  descpColor: any;
  backColor: any;
  appIconStyle: any;
  isActive = false;
  qrimg: any;
  appicon: any;

  encodedId: any
  UppbackColor: any = '';
  private modalRef: any;
  title: any;
  type: any;
  ;

  @ViewChild('mymodal', { static: false }) qrModal: ElementRef;
  socialIcon: any;

  location: Location;



  constructor(private auth: ApiService, private router: Router, private route: ActivatedRoute, private common: CommonService, private modalService: NgbModal,location: Location) {
    this.common.showSpinner();
    this.location = location;


    console.log(this.location.path())

    this.route.params.subscribe((res: any) => {
      (!res)
        ? this.router.navigate(['/'])
        : this.getUserData(res)
      console.log(res)
    })
}

  ngOnInit(): void {
    // this.getSocialLinks();
  }

  /* === Redirect social link According to Social media === */
  getUserData(res: any) {
        this.contactName = res.name;

    if(this.location.path().includes('/share')){
      console.log('yes it contains')
      var a = this.location.path().split('/')
      var profile_id =  a[3]
      console.log(profile_id)
      var data:any = { 'username': res.name, type: 'primary', profile_id:profile_id };

    }

    else{
      console.log('no it doesnot contains')
      var data:any = { 'username': res.name, type: 'primary' };

    }

  
    
    

    // const data = { 'username': res.name, type: 'primary' };

    this.auth.getProfileData(data).subscribe((response: any) => {
      
      if (response.success == 0) {
        Swal.fire('Oops...', response.msg, 'error');
        this.router.navigate(['/']);
      } else if (response.success == 1) {
        this.userData = response.user;
        console.log(this.userData)
        // localStorage.setItem('contact',this.userData.contact_id)
         
        if (response.services.length != 0) {
          (response.services || []).map((e: any) => {
            if ((e.type != 'quick_access')) {
              this.services.push(e);
              console.log(this.services)  
            } else {
              this.otherServices.push(e);
            }
          });
        }

        if (response.user.instant == 1) {
          if (response.user.instant_slug != "" || response.user.instant_slug || response.user.instant_slug != undefined) {
            if (response.user.instant_slug == 'event') {
              var result = response.services.filter((o1: any) => {
                if (o1.slug == 'event') {
                  console.log(o1.id)
                  this.encodedId = o1.id;
                }
              })
              console.log(this.encodedId)
              const encodedIdd = btoa(this.encodedId);
              console.log(encodedIdd)
              const data: any = this.common.redirectToUrl(encodedIdd, this.userData.instant_slug, '');
              if (data) {
                this.common.hideSpinner()
                this.common.success('File downloaded successfully');
                window.location.href = data.url;
              } else {
                Swal.fire('Oops...', 'Somthing went wrong !', 'error');
                this.common.hideSpinner();
              }
              //  debugger
            } else if (response.user.instant_slug == 'contact') {
              this.common.hideSpinner()
              this.addContact(this.encodedId)
            }
            else if (response.user.instant_slug == 'messages') {
              this.common.hideSpinner()
              var result = response.services.filter((o1: any) => {
                if (o1.slug == 'messages') {
                  console.log(o1.title)
                  this.title = o1.title;
                }
              })
              const { url }: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, this.title);
              location.replace(url);
            }
            else if (response.user.instant_slug == 'medical') {
              let { file_name } = this.services.find((e: any) => e.slug == response.user.instant_slug);
              this.qrimg = this.lost_found + file_name;
              this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
              return;
            }
            else if (response.user.instant_slug == 'notes') {
              this.common.hideSpinner()
              const { url }: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, '');
              location.replace(url);
              // console.log( weburl, bundlename)
              // this.deeplink(weburl, bundlename);
            }


            else if (response.user.instant_slug == 'wifi' || response.user.instant_slug == 'pcr' || response.user.instant_slug == 'vaccine') {
              this.common.hideSpinner()
              let { qrimage } = this.services.find((e: any) => e.slug == response.user.instant_slug);
              this.qrimg = this.qr_image + qrimage;
              this.modalRef = this.modalService.open(this.qrModal, {
                size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'
              });
              return;

            } else {

              this.common.hideSpinner()

              if (this.userData.instant_content && this.userData.instant_slug) {
                if (this.userData.instant_type == 'quickfiles' || this.userData.instant_type == 'quick_access') {
                  // if (this.userData.instant_slug == 'file1' || this.userData.instant_slug == 'file2' || this.userData.instant_slug == 'file3') {
                    if (this.userData.instant_slug == 'file') {

                    let urll =  environment.slug_images + this.userData.instant_file_name// `https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${this.userData.instant_file_name}`
                    location.replace(urll)

                  }
                  // else if(this.userData.instant_slug == 'bapp1' || this.userData.instant_slug == 'bapp2' || this.userData.instant_slug == 'bapp3' || this.userData.instant_slug == 'bapp4' || this.userData.instant_slug == 'bapp5'){
                    else if(this.userData.instant_slug == 'bapp'){
 
                  let split = this.userData.instant_bapp.split("|")
                    if(split[0] == "lost_found" || split[0]  == "emenu" || split[0]  == "linktree" || split[0]  == "emergency" || split[0]  == "contact" || split[0]  == "event" || split[0]  == "calendar" || split[0]  == "notes"){
                     // window.open(this.userData.instant_content, '_blank')?.focus();
                     location.replace(this.userData.instant_content);
                    }else{
                      const { url }: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, '');
                      location.replace(url);
                    }
                    // const { url }: any = this.common.redirectToUrl(this.userData.instant_content, split[0], '');
                    // location.replace(url);
                  }
                  else {
                    location.replace(this.userData.instant_content)
                  }
                } else {
                  console.log(this.userData.instant_content, this.userData.instant_slug)
                  const { url}: any = this.common.redirectToUrl(this.userData.instant_content, this.userData.instant_slug, '');
                  console.log(url)
                  //let ua = navigator.userAgent.toLowerCase();

                  // window.location.href = url;
                  location.replace(url);
                }
              } else {
                this.common.error('No data found');
              }
            }
            this.saveOnLinkClick();
          } else {
            this.common.hideSpinner()
            Swal.fire('Oops...', 'No link/application found', 'error');
          }
        } else {
          console.log("main")
          this.type = response.style.template_style
          // sessionStorage.setItem('templateType',this.type)
          console.log(this.type)
          // if (response.style.template_style == 'business_template') {
          //   this.common.hideSpinner()
          //   this.router.navigate(['/userProfile', this.contactName])
          // } else {
          //   this.common.hideSpinner()
          //   this.router.navigate(['/Profile', this.contactName])
          //   // this.common.hideSpinner()
          // }
        }
      }
    })
  }

  /* === Download contact card === */
  addContact(data: any) {
    console.log(data)
    if (this.userData.contact_id) {
      const encodedStringBtoA = btoa(this.userData.contact_id);
      window.location.href = `${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`;
      this.common.success('File Downloaded Successfully');

    }
  }

  /* === Save Count of Social Icon (click count)  === */
  saveOnLinkClick() {
    const slugData = this.services.find((e: any) => {
      return e.slug === this.userData.instant_slug
    });
    console.log(slugData)
    if (slugData) {
      let data = { 'profile_user_id': this.userData.user_id, 'slug': this.userData.instant_slug, 'service_id': slugData.id };
      this.auth.saveUserServicesClicks(data).subscribe((response: any) => {
        if (response.success == 1) {

        }
      })
    }
  }

}