<body>
    <div class="main-wrap">
        <div class="template_header" >
            <img src="../../../assets/images/ft-logo.png" alt="logo-image" />
            <a (click)="openWebsiteUrl()">Get Your Card</a>
        </div>
        <div [ngStyle]="{'background-color': UppbackColor}">
            <div class="user-info" [ngClass]="{'bioClass' : !userData?.note}">
                <div class="user-img" data-aos="zoom-in-up" data-aos-delay="500"><img [src]="photo" (error)="onImgError($event)" alt="image" /></div>
                <div class="user-details" data-aos="zoom-in-left" data-aos-delay="500">
                    <h3 [style.color]="headingColor +'!important'" class="mb_0">{{fullName}}</h3>
                    <h4 class="mb_0" *ngIf="userData?.position" [style.color]="Position_textColor +'!important'">{{position}}</h4>
                    <h5 class="mb_0"  *ngIf="userData?.company"  [style.color]="campanyName_textColor +'!important'" >{{company}}</h5>
                    <p class="mb_0" *ngIf="userData?.city || userData?.country" [ngStyle]="{'color': location_textColor}">{{userData.city}}{{userData.city?',':''}}{{userData.country}}</p>
                </div>
            </div>
            <form class="status" *ngIf="userData?.note"> 
                <p class="mb-3 px-4" [ngStyle]="{'background-color': UppbackColor, 'color': bio_textColor}" data-aos="fade-zoom-in" data-aos-delay="1500"  >{{bio}}
            </p>
            </form>
            <div class="main-btns" *ngIf="isActive" data-aos="zoom-in" data-aos-delay="100">
                <button (click)="addContact()" [ngStyle]="{'background':buttonback,'border-radius': buttonStyle}">Add To
                    Contact</button>
                <button (click)="moveToPage()" [ngStyle]="{'background':buttonback,'border-radius': buttonStyle}">Send
                    Your Contact</button>
            </div>
            <div class="section share_sec" [ngStyle]="{'background': backColor}" data-aos="zoom-in-up" data-aos-delay="500">
                <ng-container *ngFor="let oth of otherServices">
                    <ng-container *ngIf="oth.image != 'contact.png'">
                        <button [ngStyle]="{'border-radius': buttonStyle, 'border': '1px solid ' + descpColor, 'color':descpColor}" (click)="redirectToUrl(oth)"
                            class="quick-access-btn">{{oth.title}}</button>
                    </ng-container>
                </ng-container>

                <div class="margin_center" data-aos="fade-up" data-aos-delay="900">
                    <ul class="social-media">
                        <ng-container *ngFor="let srv of services">
                        <li  *ngIf="srv.image != 'contact.png'">
                            <!-- <ng-container *ngIf="srv.image != 'contact.png'"> -->
                                <a (click)=onSocial(srv.content,srv.slug,srv.title)>
                                    <img [ngClass]="{'react_img' : appicon != 'round', 'round_img' : appicon == 'round'}" src="{{appicon == 'round'? round_icn : imgUrl}}{{srv.image}}" alt="icon" />
                                    <p [ngClass]="{'react_img_p' : appicon != 'round'}">{{srv.slug == 'event'? 'Event' : srv.slug == 'messages'? 'Message' : srv.slug == 'notes'? 'Notes' : srv.slug == 'wifi'? 'wifi' : srv.slug == 'pcr'? 'Test':srv.title}}</p>
                                </a>
                            <!-- </ng-container> -->
                        </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</body>


<ng-template #mymodal let-modal>
    <div id="member_status" class="login_me" role="dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <button type="button" style="outline: none;" class="close close_modal" (click)="modalService.dismissAll()"
                data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span> </button>
            <div class="modal-header">
                <p>Long press on QR Code to connect</p>
            </div>
            <div class="modal-body">
                <form class="member_status_form">
                    <div class="text-center">
                        <img [src]="qrimg" alt="demo" style="height: 150px;width: 150px;">
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner>