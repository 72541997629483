import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-application-socialmedia',
  templateUrl: './application-socialmedia.component.html',
  styleUrls: ['./application-socialmedia.component.scss']
})
export class ApplicationSocialmediaComponent implements OnInit {
  serviceIcon: any;
  AppForm: FormGroup
  slug: any;
  name: any;
  id: any;
  soicalIcon_id: any;
  SocialMediadata: any;

  constructor(private service: ApiService,
    private common: CommonService,
    private router: Router,
    public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public activeroute: ActivatedRoute,) {

     }

  ngOnInit(): void {
    this.getServiceBytype()
    this.AppForm = this.formBuilder.group({
      content: ['', Validators.required],
    })

    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if (this.id) {
        this.getDetails()
      }
    })
  }

  ionViewWillEnter(){
    this.getServiceBytype()
  }

/* === Get List of Socail Icons=== */
  getServiceBytype() {
    var data = new FormData
    data.append('type', 'social');
   //this.service.get_services_by_type(data).subscribe((res: any) => {
   this.service.SocialIconWeb('').subscribe((res: any) => {
       console.log(res)
      if (res.success == '1') {
        this.serviceIcon = res.services
        this.SocialMediadata =  this.serviceIcon[0]
        if(this.serviceIcon){
          this.serviceIcon.forEach((element: any) => {
            if (element.slug == this.slug) {
              element.active = true
            } else {
              element.active = false
            }
      
          });
        }
      }
    })
  }

  /* === Create Social Link App=== */
  submit() {
    if (this.AppForm.value.content) {
      var data = new FormData
      if(this.soicalIcon_id){
        data.append('id',this.soicalIcon_id)
      }
      data.append('slug', this.slug);
      data.append('title', this.name);
      data.append('content', this.AppForm.value.content);
      this.spinner.show()
      this.service.AppCommon(data).subscribe((res: any) => {
        //console.log(res)
        this.spinner.hide()
        if (res.success == '1') {
          this.slug = '';
          this.name = '';
        this.soicalIcon_id = ''
          this.serviceIcon.forEach((element: any) => {
              element.active = false
      
          });
          this.AppForm.reset()
          this.common.success(res["msg"]);
        } else {
          this.common.error(res["msg"]);
        }
      }, error => {

      })
    } else {
      this.common.error("Fill the form");
    }
  }

  selectIcon(slug: any, name: any) {
    this.serviceIcon.forEach((element: any) => {
      if (element.slug == slug) {
        element.active = true
      } else {
        element.active = false
      }

    });
    this.slug = slug;
    this.name = name;
    this.SocialMediadata = this.serviceIcon.find((e: any) => { return e.slug == slug})
  }

    /* === Get Detail of Social media App=== */
  getDetails() {
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('slug', this.slug);
    this.service.getServiceDetail(formData).subscribe((response: any) => {
      if (response.success == 1) {
        this.soicalIcon_id = response.item.id;
        this.slug = response.item.slug
        this.name =  response.item.title
        this.AppForm.patchValue({
          content: response.item.content
        })
      }
   
    })
  }

}
