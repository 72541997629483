import { Component, OnInit, ViewChild, ElementRef, Input, Inject, NgZone } from '@angular/core';
import { ApiService } from '../../services/api-service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CreateContactComponent } from '../create-contact/create-contact.component';
import { DomSanitizer, Meta, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { AddContactComponent } from '../received-contacts/add-contact/add-contact.component';
import { MatDialog } from '@angular/material/dialog';
import {DOCUMENT, Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
  selector: 'app-received-contacts2',
  templateUrl: './received-contacts2.component.html',
  styleUrls: ['./received-contacts2.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],

})
export class ReceivedContacts2Component implements OnInit {
  @ViewChild('map') mapElement: any;
  contactName = "";
  userData: any = [];
  fullName = "";
  position = "";
  city = "";
  company = "";
  bio = "";
  country = "";
  services: any = [];
  otherServices: any = [];
  imgUrl = environment.service_icons;
  round_icons = environment.round_icons
  usrImg = environment.imageUrl;
  baseUrl = environment.baseURL;
  lost_found = environment.lost_found;
  slug_image = environment.slug_images;
  qr_image = environment.qr_images;
  round_icn = environment.round_icons;
  photo = "";
  contactId: any;
  buttonStyle: any;
  buttonback: any;
  headingColor: any;
  descpColor: any;
  backColor: any;
  appIconStyle: any;
  isActive = false;
  qrimg: any;
  appicon: any;
  encodedId:any
  UppbackColor: any = '';
  private modalRef: any;
  @ViewChild('mymodal', { static: false }) qrModal: ElementRef;
  socialIcon: any;
  private httpClient: HttpClient;
  lat: any;
  long: any;
  Marker: google.maps.Marker;
  map: any
  address: string;
  cover_photo: string;
  profileData: any;

  array:any = []

  embedYouTubeUrl: string;
  embedVimeoUrl:string;
  trustVideoUrl : SafeUrl="";
  trustVimeoVideoUrl: SafeResourceUrl;
  trustYouTubeVideoUrl:SafeResourceUrl;
  firstName: any;
  location: Location;

  constructor(private metaService:Meta, private handler: HttpBackend, private auth: ApiService, private router: Router,private sanitizer: DomSanitizer,public platform: Platform,
    private route: ActivatedRoute, private common: CommonService, private modalService: NgbModal,private _bottomSheet: MatBottomSheet,  public dialog: MatDialog,location: Location) {
      this.httpClient = new HttpClient(handler);
    this.common.showSpinner();
    this.location = location;

    this.route.params.subscribe((res: any) => {
      (!res)
        ? this.router.navigate(['/'])
        : this.getUserData(res)
        console.log(res)
    })
  }

  ngOnInit() {



    //  this.metaService.updateTag({property:'og:title', content:'B in Touch App Naresh Kumar Paras'})
    //  this.metaService.updateTag({property:'og:image', content: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/82/1f/1a/821f1a6b-4860-f48c-9257-10cd58c35dbe/AppIcon-0-1x_U007emarketing-0-7-0-85-220.png/1200x630wa.png'})
    
  }

   /* === Detail of profile page === */
   getUserData(res: any) {
    this.contactName = res.name;
    
    if(this.location.path().includes('/share')){
      console.log('yes it contains')
      var a = this.location.path().split('/')
      var profile_id =  a[3]
      console.log(profile_id)
      var data:any = { 'username': res.name, type: 'primary', profile_id:profile_id };

    }

    else{
      console.log('no it doesnot contains')
      var data:any = { 'username': res.name, type: 'primary' };

    }
 



    // const data = { 'username': res.name, type: 'primary' };
    this.auth.GetServiceNew(data).subscribe((res1:any)=>{
      console.log(res1)
      this.array = res1.items
      console.log(this.array)
      this.otherServices = res1.quick_access


      // old work/////
      // let key:any = Object.keys(res1.services_types);
      // //this.array = []
      // for(let i = 0; i < key.length; i++){
      //   let value =  res1.services_types[key[i]]
      //   let abc:any = { 'item': key[i], 'app':[], 'name':value}
      //   this.array.push(abc)
      // }
      ////////////

      if(this.location.path().includes('/share')){
        console.log('yes it contains')
        var a = this.location.path().split('/')
        var profile_id =  a[3]
        console.log(profile_id)
        var data:any = { 'username': res.name, type: 'primary', profile_id:profile_id };
  
      }
  
      else{
        console.log('no it doesnot contains')
        var data:any = { 'username': res.name, type: 'primary' };
  
      }


    // const data = { 'username': res.name, type: 'primary' };
    this.auth.getProfileData(data).subscribe((response: any) => {
      
      if (response.success == 0) {
        Swal.fire('Oops...', response.msg, 'error');
        this.router.navigate(['/']);
      } else if (response.success == 1) {
        this.isActive = response.user.active == '1' ? true : false;
        this.userData = response.user;
        this.profileData = response;
        sessionStorage.setItem('recevierId',this.userData.user_id)
        sessionStorage.setItem('profileId',this.userData.contact_id)

         if(this.userData.lead_capture == 1){
          this.addcontact();

         }
        // let res = this.location.path();
        // const blob = new Blob([res], { type: 'text' });
        // const url = window.URL.createObjectURL(blob);

        // saveAs(blob, "../routes.txt");
        // window.open(url);

        
        console.log(this.userData.first_name, this.userData.last_name);
        
        


        this.metaService.updateTag({property:'og:title', content:this.userData.first_name + ' ' + this.userData.last_name})
          this.metaService.updateTag({property:'og:image', content: environment.imageUrl + this.userData.image})
    
        // this.metaService.updateTag({property:'og:title', content:'B in Touch App'})
        // this.metaService.updateTag({property:'og:image', content: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/82/1f/1a/821f1a6b-4860-f48c-9257-10cd58c35dbe/AppIcon-0-1x_U007emarketing-0-7-0-85-220.png/1200x630wa.png'})       
        this.contactId = this.userData.contact_id;
        (response?.style) ? this.applyStyles(response.style) : '';
        if (response.services.length != 0) {
          (response.services || []).map((e: any) => {
        
            if (e.type != 'quick_access' && e.type != 'quickfiles') {     // differentiate between social icons and Quick Access button
              this.services.push(e);

              this.services.forEach((element:any) => {
                console.log(element)
                  if(element.slug=='embedvideo'){

                  
                    console.log(element.content)
                     if(element.content.includes('vimeo.com')){
   
                       var vimeoregExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
                       var vimeourl = element.content;
                       console.log(vimeourl)
                       var vimeomatch = vimeourl.match(vimeoregExp) || '';
                       var vimeovideoID= vimeomatch[5]
                       this.embedVimeoUrl = "https://player.vimeo.com/video/" + vimeovideoID
                       this.trustVimeoVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.embedVimeoUrl)
                       console.log(this.trustVimeoVideoUrl)
                
   
                     }
                     else{
                    console.log('no')
                     var youtuberegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                     var youtubeurl = element.content;
                     console.log(youtubeurl)
                     var youtubematch = youtubeurl.match(youtuberegExp) || '';
                     var youtubevideoID= youtubematch[7]
                     this.embedYouTubeUrl = "https://www.youtube.com/embed/" + youtubevideoID
                     this.trustYouTubeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.embedYouTubeUrl)
                     console.log(this.trustYouTubeVideoUrl)
            
                     }
                  // }
                   
                
                 }
                
            
            });

             // console.log( this.array)
            } 
            // else {
            //   this.otherServices.push(e);
            // }
            console.log(this.otherServices)
          });
          this.array.forEach((element1:any)=>{ 
            // if(element1.item != 'quick_access'){
        //    console.log(element1)
            response.services.forEach((element:any) => {
           //  if(element.type != 'quick_access' && element.type != 'quickfiles'){ 
              if(element1.item == element.type || element1.item == element.slug){
                element1.app.push(element)
              }
           // }
            });
          // }else{

          // }
          })
        console.log(this.array)


        //---------------------------------------------------------  EmbeddVideo----------------------------

        this.array.forEach((element:any) => {
          console.log(element);
          
            console.log(element.highlight);
            
              if(element.highlight){
                element?.highlight.forEach((el2:any)=>{
                  console.log(el2);
                  
                  if(el2.slug=='embedvideo'){
  
              
                    console.log(el2.content)
                     if(el2.content.includes('vimeo.com')){
         
                       var vimeoregExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
                       var vimeourl = el2.content;
                       console.log(vimeourl)
                       var vimeomatch = vimeourl.match(vimeoregExp) || '';
                       var vimeovideoID= vimeomatch[5]
                       this.embedVimeoUrl = "https://player.vimeo.com/video/" + vimeovideoID
                       this.trustVimeoVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.embedVimeoUrl)
                       el2.trustVimeoVideoUrl = this.trustVimeoVideoUrl
  
                                     
         
                     }
                     else{
                    console.log('no')
                     var youtuberegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                     var youtubeurl = el2.content;
                     console.log(youtubeurl)
                     var youtubematch = youtubeurl.match(youtuberegExp) || '';
                     var youtubevideoID= youtubematch[7]
                     this.embedYouTubeUrl = "https://www.youtube.com/embed/" + youtubevideoID
                     this.trustYouTubeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.embedYouTubeUrl)
                     console.log(this.trustYouTubeVideoUrl)
                     el2.trustYouTubeVideoUrl = this.trustYouTubeVideoUrl
  
            
                     }
                  // }
                   
                
                 }
                }) 
              }
             
             
      
      });
        }
        if (response.user.instant == 1) {
          this.router.navigate(['/profile', this.contactName])
        } else {
          this.common.hideSpinner()
          this.position = this.userData.position;
          this.city = `${this.userData.city} ,${this.userData.country}`;
          this.company = this.userData.company;
          this.bio = this.userData.note;
          this.photo = this.userData.photo?this.usrImg + this.userData.photo:'assets/images/user-image@3x.png';
          this.cover_photo = this.userData.cover_photo?this.usrImg + this.userData.cover_photo:'assets/images/header_bg.png';
          this.fullName = `${this.userData?.first_name ? this.userData.first_name : ''} ${this.userData?.last_name ? this.userData?.last_name : ''}`;
          this.address = `${this.userData.address},${this.userData.postcode},${this.userData.city} ${this.userData.country}`; 
          this.firstName =  `${this.userData?.first_name ? this.userData.first_name : ''}`
          this.mapDraw()
          // contact-profile4/img.png
          console.log(this.firstName)
          sessionStorage.setItem('profileName',this.firstName)

        }

      }
    })
  })
  }

  checkCondition(content:string){
    if(content.includes('vimeo.com')){
      return true
    }
    else{
      return false
    }
    
  }

 /* === design of profile page === */
  applyStyles(style: any) {
    this.buttonStyle = (style.btn_style == 'round') ? '40px' : '0px';
    this.buttonback = (style.btn_color == '') ? '#fff' : style.btn_color;
    this.headingColor = (style.text_color == '') ? '#0071BC' : style.text_color;
    this.descpColor = (style.text_color2 == '') ? '#9E9E9E' : style.text_color2;
    // console.log(style.keypad_color,"===========================");return
    this.backColor = (style.keypad_color == '') ? '#fff' : style.keypad_color;
    this.UppbackColor = (style.bg_color == '') ? '#fff' : style.bg_color;
    this.appIconStyle = (style.icon_style == 'rectangular') ? '0px' : '40px';
    this.appicon = style.icon_style;
  }

   /* === Click of Social Icons  === */
  onSocial(url: any, slug: string, title:string) {
    console.log(url, slug, title)
    if (slug == 'wifi' || slug == 'pcr' || slug == 'vaccine') {
      let { qrimage } = this.services.find((e: any) => e.slug == slug);
      this.qrimg = this.qr_image + qrimage;
      this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      return;
    }

    if (slug == 'medical') {
      let { file_name } = this.services.find((e: any) => e.slug == slug);
      this.qrimg = this.lost_found + file_name;
      this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      return;
    } 
    if (slug == 'messages') {

    }
    if (slug == 'notes') {
      let data = this.services.find((e: any) => e.slug == slug);
      const { url: weburl, bundlename }: any = this.common.redirectToUrl(data, slug, title);

      this.deeplink(weburl, bundlename);
    }
    if (slug == 'event') {
      let data = this.services.find((e: any) => e.slug == slug);
      const encodedId = btoa(data.id);
      const urlData: any = this.common.redirectToUrl(encodedId, slug, '');
      if (urlData) {
        this.common.success('File downloaded successfully');
        window.location.href = urlData.url;
      } else {
        Swal.fire('Oops...', 'Somthing went wrong !', 'error');
        this.common.hideSpinner();
      }
      return;
    }
    if(slug == 'maps'){
      this.common.hideSpinner();
      const { url: weburl, bundlename }: any = this.common.redirectToUrl(url, slug, title);

      this.deeplink(weburl, bundlename);
    }
  //  this.common.showSpinner();
  
    const slugData = this.services.find((e: any) => { return e.slug === slug });
    let data = { 'profile_user_id': slugData.user_id, 'slug': slugData.slug, 'service_id': slugData.id };
    const formData = new FormData();
    formData.append('profile_user_id', slugData.user_id);
    formData.append('slug', slugData.slug);
    formData.append('service_id', slugData.id);
    this.auth.saveUserServicesClicks(formData).subscribe((response: any) => {
    //  console.log(response)
      if (response.success == 1) {
      //  this.common.hideSpinner();
        console.log(url, slug)

        const { url: weburl, bundlename }: any = this.common.redirectToUrl(url, slug, title);

        this.deeplink(weburl, bundlename);

      }
    }, (e) => {
      this.common.hideSpinner();
    })
  }



  redirectToUrl(url:any) {
    // if(url.slug == 'file1' || url.slug == 'file2' || url.slug == 'file3'){
      if(url.slug == 'file'){

      let urll =  environment.slug_images + url.file_name//`https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${url.file_name}`
      window.open(urll, '_blank')?.focus();

    }
    else if(url.image == "bapp.png"){
      let split =url.bapp.split("|")
      if(split[0] == "lost_found" || split[0]  == "emenu" || split[0]  == "linktree" || split[0]  == "emergency" || split[0]  == "contact" || split[0]  == "event" || split[0]  == "calendar" || split[0]  == "notes"){
        window.open(url.content, '_blank')?.focus();
      }else{
        const { url: weburl, bundlename }: any = this.common.redirectToUrl(url.content, split[0], '');
        this.deeplink(weburl, bundlename);
      }
    }
    // else if(url.slug  == 'bapp1' || url.slug == 'bapp2' || url.slug == 'bapp3' || url.slug == 'bapp4' || url.slug  == 'bapp5'){
    //   let split =url.bapp.split("|")
    //   const { url: weburl, bundlename }: any = this.common.redirectToUrl(url.content, split[0], '');

    //   this.deeplink(weburl, bundlename);

    // }
    else {
       window.open(url.content, '_blank')?.focus();
    }
  }

   /* === Download of Contact card  === */

  addContact(type?:any) {
    localStorage.setItem('contact', this.contactId)
    
       // this._bottomSheet.open(NewContactComponent, {
        this._bottomSheet.open( CreateContactComponent, {
          panelClass: 'bottom-sheet-custm',
          disableClose: true,
          backdropClass: 'custm-sheet',
          data:{type:type}

        });
    
    
      }

  moveToPage() {
    console.log('clicked');
    const encodedName = btoa(this.contactName);
    this.router.navigate(['/profile-contact-form', encodedName, this.userData.user_id,''])
  }

  onImgError(event: any) {
    event.target.src = '../../../assets/images/dummy_user.png';
  }


  saveOnLinkClick() {
    const slugData = this.services.find((e: any) => { return e.slug === this.userData.instant_slug });
    let data = { 'profile_user_id': this.userData.user_id, 'slug': this.userData.instant_slug, 'service_id': slugData.id };
    this.auth.saveUserServicesClicks(data).subscribe((response: any) => {
      if (response.success == 1) {

      }
    })
  }

  getSocialLinks() {
    const formData = new FormData();
    this.auth.getServiceIcons(formData).subscribe((response: any) => {
    })

  }
  deeplink(url: string, bundlename: string) {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check
      if (isIphone == false) {
      let app = {
        launchApp: function () {
          setTimeout(function () {
          window.open(url, '_blank')?.focus();
          // window.location.href = url
          }, 25);
        //  window.location.href = bundlename
         window.open(bundlename,'_blank')?.focus();

        },
        openWebApp: function () {
       window.open(url, '_blank')?.focus();
     // window.location.href = url
        }
      };
      app.launchApp();
    } else if (isAndroid == false) {
      let app = {
        launchApp: function () {
          window.open(bundlename,'_blank')?.focus();
         //window.location.href = bundlename
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
         window.open(url, '_blank')?.focus();
        // window.location.href = url
        }
      };
      app.launchApp();
    } 
    else {
   window.open(url, '_blank')?.focus();
  // window.location.href = url
    }
  }
  


  openWebsiteUrl() {
       window.open('https://b-in-touch.fr', '_blank')?.focus();

  }

  mapDraw() {
    console.log("enter")
    this.httpClient.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + this.userData.address + ',' + this.userData.address2 + ',' + this.userData.city + ',' + this.userData.postcode + ',' + this.userData.country + '&key=AIzaSyDcIR2VHyymBFM-uumgYG927JMuBJpVFjI').subscribe((res: any) => {
console.log(res)
      if (res.status == 'OK') {
        this.lat = res.results[0].geometry.location.lat
        this.long = res.results[0].geometry.location.lng
        console.log(this.lat, this.long)
        this.Position()
      }
    },error=>{
      console.log(error)
    })
  }

  Position() {
    const mapProperties = {
      center: new google.maps.LatLng(this.lat, this.long),
      zoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    this.Marker = new google.maps.Marker({
      map: this.map,
      position: new google.maps.LatLng(this.lat, this.long),
    });
  }

  iconsClick(url: any, slug: any) {
    const { url: weburl, bundlename }: any = this.common.redirectToUrl(url, slug, '');

    this.deeplink(weburl, bundlename);
  }

  // exchangeContact(){
  //     this.contactId = localStorage.getItem('contact')
  //      const token = this.common.getToken();
  //     if (token) {
  //       let data = { 'contact_id': localStorage.getItem('contact') };
  //       this.auth.saveSharedContact(data).subscribe((response: any) => {
  //         if (response.success == 1) {
  //           this.common.success(response.msg);
  //         } else {
  //          // this.common.error(response.msg);
  //         }
  //       }, (e) => {
  //        // this.common.error(e.error.msg);
  //       })
  //     }
  
  //     if (localStorage.getItem('contact')) {
  //       const encodedStringBtoA = btoa( this.contactId);
  //      window.open(`${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`, '_blank');
  //     }
     
    
  // }

  //  latest one

//   exchangeContact(type?:any){

//     sessionStorage.setItem('recevierId',this.userData.user_id)

//     if (this.platform.IOS || this.platform.SAFARI) {
//       this._bottomSheet.open(CreateContactComponent, {
//         panelClass: ['bottom-sheet-custm', 'create'],
//         disableClose: true,
//         backdropClass: 'custm-sheet',
//         data:{type:type}

        
//       });
//     }

//     else{

//     this.contactId = localStorage.getItem('contact')
     
//      const token = this.common.getToken();
//     if (token) {
//       let data = { 'contact_id': localStorage.getItem('contact') };
//       this.auth.saveSharedContact(data).subscribe((response: any) => {
//         if (response.success == 1) {
//           this.common.success(response.msg);
//         } else {
//          // this.common.error(response.msg);
//         }
//       }, (e) => {
//        // this.common.error(e.error.msg);
//       })
//     }

//     if (localStorage.getItem('contact')) {
//       const encodedStringBtoA = btoa( this.contactId);
//      window.open(`${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`, '_blank');
//     }
//   }
// }

exchangeContact(type?:any){

  // sessionStorage.setItem('recevierId',this.userData.user_id)
  // sessionStorage.setItem('profileId',this.userData.contact_id)

  if (this.platform.IOS || this.platform.SAFARI) {
    this._bottomSheet.open(CreateContactComponent, {
      panelClass: ['bottom-sheet-custm', 'create'],
      disableClose: true,
      backdropClass: 'custm-sheet',
      data:{type:type, contactId:this.contactId}

      
    });
  }

  else{

  // this.contactId = localStorage.getItem('contact')
   
  //  const token = this.common.getToken();
  // if (token) {
  //   let data = { 'contact_id': this.contactId };
  //   this.auth.saveSharedContact(data).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       this.common.success(response.msg);
  //     } else {
  //      // this.common.error(response.msg);
  //     }
  //   }, (e) => {
  //    // this.common.error(e.error.msg);
  //   })
  // }

  if (this.contactId) {
    const encodedStringBtoA = btoa( this.contactId);
   window.open(`${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`, '_blank');
   if(this.userData.lead_capture == 0){

    this.addcontact()
   }

  }
}
}

addcontact() {

  this.dialog.open(AddContactComponent, {
    width: '320px',
    panelClass: 'add_contact_modal',
    disableClose: true,
    backdropClass: 'custm-sheet',


  });
}
}

