<div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content left_popup">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Linking an application to a smart object</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="smart_object_links">
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>iframe or PNG</p>
            <p>Rectangle 325</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>iframe or PNG</p>
            <p>Rectangle 325</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>Link_white_24p</p>
            <p>iframe or PNG</p>
            <p>Rectangle 325</p>

        </div>
      </div>
    </div>
  </div>