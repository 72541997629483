import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsService } from 'src/app/services/event/events.service';
declare let $: any;
declare var PerfectScrollbar: any;
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  userData: any;
  ImageUrl: any;
  Contactlist: any;
  contactDetails: any;
  userDetails: any;
  Clicklist: any;
  base_url: any;
  notList: any;
  notCount: any;
  export_contact = environment.export_contact

  constructor(private service: ApiService, private common: CommonService, private router: Router, public event: EventsService) { }

  ngOnInit(): void {
  $( function(){
    $(".deznav-scroll").length > 0 && (new PerfectScrollbar(".deznav-scroll").isRtl = !1);
    $(".social_report_scroll").length > 0 && (new PerfectScrollbar(".social_report_scroll").isRtl = !1);
   
  });
    
    this.ImageUrl = this.service.imageUrl
    if (localStorage.getItem('B-in-Touch')) {
      this.userData = JSON.parse(localStorage.getItem('B-in-Touch') || '{}')
      console.log(this.userData)
    }
    this.contactlist();
    this.event.getObservable().subscribe((res: any) => {
      if (res == 'editData') {
        if (localStorage.getItem('B-in-Touch')) {
          this.userData = JSON.parse(localStorage.getItem('B-in-Touch') || '{}')
          console.log(this.userData)
        }
      }
    })
  }

  openrightMenu(){
    $( function(){
      $('.right_sidebar').toggleClass('open_right_menu');
  });
  
    $( function(){
      $('#main-wrapper').removeClass('menu-toggle');
      $('.hamburger').removeClass('is-active');
    });
  }

  openLeftMenu(){
    
    $( function(){
      $('.right_sidebar').removeClass('open_right_menu');
    });
    $( function () {
      $("#main-wrapper").toggleClass("menu-toggle"); 
      $(".hamburger").toggleClass("is-active");
    })
    console.log('side menu clicked');
  }

/* ====== Logout ===========*/
  logout() {
    this.service.logout('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.msg)
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/'])
      }else if(res.http_status == '401'){
        this.common.error('You have been logged out for security purpose!');
        this.router.navigate(['']);
      }
      else {
        this.common.error(res.msg);

      }
    },error=>{

  })
  }

/* ====== Contact List ===========*/
  contactlist() {
    this.service.getContactList('').subscribe((res: any) => {
      this.clicklist();
      this.notificationList()
      this.notificationC()
      if (res['success'] == 1) {
        this.Contactlist = res.items
        this.Contactlist.forEach((element:any) => {
          element.firstletter = element.first_name.charAt(0)
          element.date = moment(element.created_on).format('DD/MM/yyyy')
        });
      }
      else {
      }
    })
  }

/* ======Detail of Contact ===========*/
  contactData(data: any) {
    var fd = new FormData
    fd.append('target_user_id', data.user_id)
    this.service.getContactById(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.userDetails = res.user
      }
      else {
      }
    })
    this.contactDetails = data
    }

/* ======Social Count List ===========*/
  clicklist() {
    this.service.getClickList('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.Clicklist = res.items
        this.base_url = res.base_url
      }
      else {
      }
    })
  }

  /* ======Delete Contact ===========*/
  delete(data: any) {
    var fd = new FormData
    fd.append('profile_user_id', data.user_id)
    this.service.deleteContact(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.msg);
        $('#contact_complete_detail').modal('hide')
        this.contactlist();
      }
      else {
      }
    })
  }
  
  gotoTouchShop() {
    window.open('http://b-in-touch.fr/', '_blank')
  }

  gotosetting() {
    this.router.navigate(['/setting'])
  }


  /* =============  Notication ============= */

  notificationList() {
    this.service.notificationList('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.notList = res.items
        if (res.items != null) {
          this.notList.forEach((element: any) => {
            element.timeDuration = moment(element.modified_on).fromNow();
          });
        }
      }
      else {
      }
    })
  }

  notificationC() {
    this.service.notificationCount('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.notCount = res.count
      }
      else {
      }
    })
  }

  deletenoti() {
    this.service.DeleteNotification('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.notificationList();
        this.notificationC()
      }
      else {
      }
    })
  }

  /* =============  Notication ============= */


}

