<body style="background-color: white; height: 100vh;">
    <div class="main-wrap">
       <div class="main-inner">
            <div class="top-sec">
                <div class="logo-wrap">
                    <img src="assets/images/logo-icon.png">
                </div>
                <div class="text-sec">
                    <h2>B in Touch - Digital Business Card</h2>
                    <p>Click below to download our app</p>
                </div>
            </div>
            <p class="name-sec"><span class="name">Adrien’s</span> profile will be automatically added to your connections in the app</p>
            <div class="button-sec">
                <a href="https://play.google.com/store/apps/details?id=com.b_in_touch&pli=1"><img src="assets/images/playstore.svg"></a>
                <a href="https://apps.apple.com/us/app/b-in-touch-app/id1619743993"><img src="assets/images/appstore.svg"></a>
            </div>
       </div>       
    </div>
</body>
