<body class="vh-100">
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-md-7">
                    <div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <div class="auth-form">
                                    <div class="text-center mb-3 logo">
                                        <img src="../../../assets/images/logo_login.png" alt="">
                                    </div>

                                    <form #forgotPassword="ngForm" (ngSubmit)="forgotPassword.valid && Forgot(forgotPassword)"
                                        class="custom-form mt-5" action="index.html">
                                        <p class="forgot_text">Enter your password and confirm it.</p>
                                        <div class="form-group">
                                            <input id="pass" type="password" class="form-control" pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})" name="password"
                                                ngModel #password="ngModel" required />
                                            <label for="pass" class="animated-label">New Password</label>
                                            <div *ngIf="forgotPassword.submitted && password.invalid ">
                                                <div class="alert alert-danger custom_alert" *ngIf="password.errors?.required">
                                                    Password is required
                                                </div>
                                                <div class="alert alert-danger custom_alert" *ngIf="password.errors?.pattern">
                                                    Password should be atleast 8 characters,one lowercase and  uppercase char,one numbere.
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="form-group">

                                            <input id="pass11" type="password" class="form-control" name="confirmpassword"
                                                ngModel pattern="{{ password.value }}" #confirmpassword="ngModel"
                                                required/>
                                            <label for="pass11" class="animated-label">Confirm Password</label>

                                            <div *ngIf=" confirmpassword.invalid && (forgotPassword.submitted || confirmpassword.touched)"
                                            class="alert alert-danger custom_alert">
                                            <div *ngIf="confirmpassword.errors?.required"> Confirm password is required.
                                            </div>
                                            <div *ngIf="confirmpassword.errors?.pattern"> Password & Confirm Password
                                                does not match.</div>
                                        </div>
                                        </div>
                                       
                                        <div class="text-center mt-5 mb-4">
                                            <button type="submit"
                                                class="btn btn-primary btn-block rounded-pill">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center term_text mt-4">By signing up, you agree to
                        our <a routerLink="/terms">Terms</a> and <a routerLink="/privacy">Privacy Policy.</a></div>
                </div>
            </div>
        </div>
    </div>
</body>