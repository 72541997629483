import { Component, Inject, OnInit } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { environment } from 'src/environments/environment';
import { Platform} from '@angular/cdk/platform';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddContactComponent } from '../received-contacts/add-contact/add-contact.component';

@Component({
  selector: 'app-create-contact',
  templateUrl: './create-contact.component.html',
  styleUrls: ['./create-contact.component.scss']
})
export class CreateContactComponent implements OnInit {

  
  baseUrl = environment.baseURL;
  contactId: any;
  profileName: string | null;
  type: string | null;

  

  constructor(private dialog:MatDialog, @Inject(MAT_BOTTOM_SHEET_DATA) public data: {type: any,contactId:any},
  public _bottomSheetRef: MatBottomSheetRef<CreateContactComponent>,private common: CommonService, private auth: ApiService,public platform: Platform) { }
  ngOnInit(): void {
   console.log(this.data)
  }

  okButton(){
     console.log(this.data.contactId)
     const token = this.common.getToken();
    if (token) {
      let data = { 'contact_id': this.data.contactId };
      this.auth.saveSharedContact(data).subscribe((response: any) => {
        if (response.success == 1) {
          this.common.success(response.msg);
        } else {
         // this.common.error(response.msg);
        }
      }, (e) => {
       // this.common.error(e.error.msg);
      })
    }

 

    if (this.data.contactId) {
      const encodedStringBtoA = btoa( this.data.contactId);
     window.open(`${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`, '_blank');
    }

    this._bottomSheetRef.dismiss();

    console.log(this.data)

    this._bottomSheetRef.afterDismissed().subscribe((res:any)=>{
      if(this.data.type == 1){
        this.addcontact();
      }
    })

    //  if(this.data.type == 1){
    //   this.addcontact();
    // }

    
 


  //   if(sessionStorage.getItem('templateType')=='business_template'){
  //     this.addcontact();
  // }
}




//   okButton(){
//     // this.contactId = localStorage.getItem('contact')
//      this.contactId = localStorage.getItem('contact')

//      const token = this.common.getToken();
//     if (token) {
//       let data = { 'contact_id': localStorage.getItem('contact') };
//       this.auth.saveSharedContact(data).subscribe((response: any) => {
//         if (response.success == 1) {
//           this.common.success(response.msg);
//         } else {
//          // this.common.error(response.msg);
//         }
//       }, (e) => {
//        // this.common.error(e.error.msg);
//       })
//     }

//     if (localStorage.getItem('contact')) {
//       const encodedStringBtoA = btoa( this.contactId);
//      window.open(`${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`, '_blank');
//     }

//     this._bottomSheetRef.dismiss();

//     if(this.data.type == 1){
//       this.addcontact();
//     }

    
 


//   //   if(sessionStorage.getItem('templateType')=='business_template'){
//   //     this.addcontact();
//   // }
// }

  addcontact() {
    this.dialog.open(AddContactComponent, {
      width: '320px',
      panelClass: 'add_contact_modal',
      
    });
  }


  closeBtn(){
    this._bottomSheetRef.dismiss();
  }

  // closeBtn(){
  //   this._bottomSheetRef.dismiss();
  // }

}
