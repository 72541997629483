import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AnyNsRecord } from 'dns';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import * as moment from 'moment';


@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit, AfterViewInit {

  moreFields: boolean = false;
  createContactForm: FormGroup;
  submitted = false;
  isButtonDisabled = false;

  error_messages = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Invalid email' }
    ],
    'name': { type: 'required', message: 'name is required' },
    'phone': { type: 'required', message: 'phone no is required' },
    'company': { type: 'required', message: 'company is required' },
    'position': { type: 'required', message: 'company is required' },



    // 'phoneNumber': { type: 'required', message: 'Phone number is required' },
    // 'dob': { type: 'required', message: 'Date of birth is required' },
}
  name: any;
  userId: any;
  where: any;
  profileName: string | null;
  imgURLCover: any;
  uploadImage: File;
  vcfContent: any;
  profileId: any;
  formFields: any=[];
  customForm:FormGroup;
  arrayItems: any;
  custom_error_message:any={};
  form_id: any;
  inputtype:string="";
  username: any;
  field_options: any=[];
  selected_field_options: any;
  current_location_latitude: any='';
  current_location_longitude: any='';
  leadCaptureStatus: any;

  constructor(public dialogRef: MatDialogRef<AddContactComponent>, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private auth: ApiService, private common: CommonService) {
    
    // this.profileName = sessionStorage.getItem('profileName')

    this.userId = sessionStorage.getItem('recevierId')
    this.profileId = sessionStorage.getItem('profileId')


    this.getCustomizeFormFields();
    console.log(this.userId)
    this.getcurrentLocation();
    
  }

  ngAfterViewInit(): void {
    this.profileName = sessionStorage.getItem('profileName')
  
  }

   async  getcurrentLocation(){

  var locationPromise= new Promise((resolve:any, reject:any)=>{
    
    if (navigator.geolocation) {   

     return navigator.geolocation.getCurrentPosition((position:any) => {
        resolve({longitude: position.coords.longitude, latitude: position.coords.latitude});
      }); 
    }

    else{
      this.current_location_latitude ='';
      this.current_location_longitude = '';
    }
   })
    
  await locationPromise.then((position:any)=>{
    console.log(position);
    
    this.current_location_latitude =  position.latitude;
    this.current_location_longitude = position.longitude;

   })

  

  }

  ngOnInit(): void {
    this.createContactForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      name: ['', Validators.required],
      // lastName: [''],
      phone: [''],
      birthday: [''],
      company: [''],
      position: [''],
      websiteUrl: [''],
      address: [''],
      postalCode: [''],
      city: [''],
      country: [''],
      note: ['']

    })

    

   console.log(this.createContactForm);
   

  }

  // async chooseContact(){
  //   const supportsContacts = ('contacts' in navigator && 'ContactsManager' in window);
  //   console.log(supportsContacts)

  // }

  getVcfFile(event:any){


    var obj:any = {}

    console.log(event.target.files[0])

     let fileReader = new FileReader();
    fileReader.onload = (e) => {
     var a:any =  fileReader.result?.toString().split('\n');
      for(var i=0;i<a.length;i++){
        var key = a[i].split(':')[0].trim()
         var value = a[i].indexOf(a[i].split(':')[1]);
          var sub = a[i].substring(value,a[i].length).trim()
         

        while( i+1<a.length &&  !a[i+1].includes(':')){
          i++;
          sub = sub + a[i].trim()
          
         }

       if(key.includes('FN;CHARSET=UTF-8')){
          obj.fullname = sub;

        }
        
        if(key=='N;CHARSET=UTF-8'){
          obj.firstname = sub.split(';')[1]
          obj.lastname = sub.split(';')[0]


        }
        if(key.includes('BDAY')){
         if(sub.includes('--')){
             obj.dob = ''
          }
          else{
             var dob = moment(sub).format()
             obj.dob = dob
          }
        }

        if(key.includes('ORG;CHARSET=UTF-8')){
          obj.company = sub;
        }


        if(key.includes('TITLE;CHARSET=UTF-8')){
          obj.position = sub;
        }

        if(key.includes('TEL;')){
          obj.phone = sub;
        }

        if(key.includes('EMAIL;')){
          obj.email = sub;
        }

        if(key.includes('URL')){
          obj.url = sub;
        }

        if(key.includes('ADR')){
          var postalcode = sub.split(";")
          obj.postalcode = postalcode[postalcode.length-2]
          obj.city = postalcode[postalcode.length-3]
          obj.country = postalcode[postalcode.length-1]

          obj.address = sub.substring(0,sub.indexOf(obj.postalcode)).replace(';'," ");
          
        
        }

        if(key.includes('NOTE;')){
          obj.note = sub;
        }

        if(key.includes('PHOTO;ENCODING=b')){
          var type = key.substring(key.indexOf("TYPE=")+5,key.length)
          obj.image ="data:image/"+type+";base64"+","+ sub;
        }
      }


    if(obj){
      this.createContactForm.patchValue({
        email: obj.email ? obj.email : '',
        firstName: obj.firstname ? obj.firstname : '',
        lastName: obj.lastname ? obj.lastname : '',
        phoneNumber: obj.phone ? obj.phone : '',
        dob: obj.dob ? obj.dob : '',
        company: obj.company ? obj.company : '',
        position: obj.position ? obj.position : '',
        websiteUrl: obj.url ? obj.url : '',
        address: obj.address ? obj.address : '',
        postalCode: obj.postalcode ? obj.postalcode : '',
        city: obj.city ? obj.city : '',
        country: obj.country ? obj.country : '',
        note: obj.note ? obj.note : '',
      })
     
      this.imgURLCover = obj.image ? obj.image : ''
     }
   }
    fileReader.readAsText(event.target.files[0]);
 }

  getContactImages(event: any) {
    console.log(event.target.files[0])
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.imgURLCover = reader.result;
    };
    this.uploadImage = <File>event.target.files[0];

  }

  saveSharedCont(id: any) {
    const formData = new FormData();
    formData.append('user_id', this.userId);
    formData.append('contact_id', id);
    // let data = { 'user_id': this.userId, 'contact_id': this.profileId };
    this.auth.saveSharedContact(formData).subscribe((response: any) => {
      if (response.success == 1) {
        this.closeModal();
      } else {
        this.common.error(response.msg);
      }
    })
  }



  closeModal() {
    this.dialogRef.close();
  }
  addMore() {
    this.moreFields = true;
  }


  // get Customize Fields

  getCustomizeFormFields() {
    const formData = new FormData();
    formData.append('user_id', this.userId);
    formData.append('profile_id', this.profileId);
    let data = { 'user_id': this.userId, 'profile_id': this.profileId };
    this.auth.getLeadForm(formData).subscribe((response: any) => {
      console.log(response);
      
      if (response.success == 1) {
        this.formFields = response.items;
        console.log(this.formFields);
        if(this.formFields.connect_fields){
          
          this.customForm = new FormGroup({});
          this.formFields.connect_fields.forEach((el:any)=>{
            console.log(el);
             if(el.field_type=='select'){
               this.field_options=JSON.parse(el.field_option)
            
             }
            if(el.required==1){
              var formControlFields = [{control: new FormControl('', Validators.required) }];
              if(el.field_name=='email'){
                var formControlFields = [{control: new FormControl('', Validators.compose([Validators.required, Validators.email])) }];

              } 
            }

            else{
              var formControlFields = [{control: new FormControl('') }]; 
            }
            
           formControlFields.forEach((f:any) => this.customForm.addControl(el.field_name, f.control));

          })


          console.log(this.customForm);
       
        }
       
      } else {
      }

      
    })
  }

 

  onSubmitForm() {
    this.submitted = true;

    console.log(this.createContactForm)
    if (this.createContactForm.invalid) {
      this.createContactForm.markAllAsTouched();
      return
    }

    let dob = moment(this.createContactForm.value.dob).format('YYYY-MM-DD')
    console.log(dob);
    if(dob!='Invalid date'){
      this.createContactForm.get('birthday')?.setValue(dob)    

    }
    else{
      this.createContactForm.get('birthday')?.setValue('')    

    }
    
    
    


    
    // const formData = new FormData();
    // formData.append('first_name', this.createContactForm.value.firstName ? this.createContactForm.value.firstName : '');
    // formData.append('last_name', this.createContactForm.value.lastName ? this.createContactForm.value.lastName : '');
    // formData.append('dob', dob ? dob : '');
    // formData.append('company', this.createContactForm.value.company ? this.createContactForm.value.company : '');
    // formData.append('position', this.createContactForm.value.title ? this.createContactForm.value.title : '');
    // formData.append('phone', this.createContactForm.value.phoneNumber ? this.createContactForm.value.phoneNumber : '');
    // // formData.append('work_phone',this.createContactForm.value.phoneNumber ? this.createContactForm.value.phoneNumber : '');
    // formData.append('email', this.createContactForm.value.email ? this.createContactForm.value.email : '');
    // formData.append('website', this.createContactForm.value.websiteUrl ? this.createContactForm.value.websiteUrl : '');
    // formData.append('address', this.createContactForm.value.address ? this.createContactForm.value.address : '');
    // formData.append('postcode', this.createContactForm.value.postalCode ? this.createContactForm.value.postalCode : '');
    // formData.append('city', this.createContactForm.value.city ? this.createContactForm.value.city : '');
    // formData.append('country', this.createContactForm.value.country ? this.createContactForm.value.country : '');
    // formData.append('note', this.createContactForm.value.note ? this.createContactForm.value.note : '');


    // formData.append('file_name', this.uploadImage ? this.uploadImage : '');
    // formData.append('profile_type', 'contact');

     console.log(this.uploadImage);

             var form_data=[];
        for (const property in this.createContactForm.value) {
          form_data.push({"field":`${property}`, "value":`${this.createContactForm.value[property]}`})
        }
      //  var form_data:any =  [{"field": "number","value": "9441062083"},{"field": "name","value": "Thiru php developer"}]
       console.log(form_data);

   
    this.isButtonDisabled = true;
       
    const formData = new FormData();
    formData.append('form_data', JSON.stringify(form_data));
    formData.append('form_id', '1');
    formData.append('user_id',  this.userId);
    formData.append('profile_id', this.profileId);
    formData.append('phone', this.createContactForm.value.phone ? this.createContactForm.value.phone: '');
    formData.append('email', this.createContactForm.value.email ? this.createContactForm.value.email:'');
    formData.append('position', this.createContactForm.value.position ? this.createContactForm.value.position : '');
    formData.append('company', this.createContactForm.value.company ? this.createContactForm.value.company : '');
    formData.append('name', this.createContactForm.value.name ? this.createContactForm.value.name : '');
    formData.append('file_name', this.uploadImage ? this.uploadImage : '');
    formData.append('lat', this.current_location_latitude);
    formData.append('lng', this.current_location_longitude);


    formData.append('through', 'web');


    this.auth.captureLeadForm(formData).subscribe((response: any) => {
      if (response.success == 1) {
        this.common.success(response.msg);
        // if (response.contact) {
          // this.saveSharedCont(response.contact.id);
          this.createContactForm.reset();
          this.closeModal();
          this.isButtonDisabled = false;


          //   if( this.where == 'template'){
          //   this.router.navigate(['/slug-profile/contact'])
          //   }else{
          //  this.router.navigate(['/profile',this.name])
          //   }
        //}
      } else {
        this.common.error(response.msg);
      }
    })

  }

  onSubmitCustomForm(event:any){
    this.submitted = true;

    console.log(this.customForm)
    if (this.customForm.invalid) {
      this.customForm.markAllAsTouched();
      return


    }

      console.log(event.target.type);
         var form_data=[];
        for (const property in this.customForm.value) {
          form_data.push({"field":`${property}`, "value":`${this.customForm.value[property]}`})
        }
      //  var form_data:any =  [{"field": "number","value": "9441062083"},{"field": "name","value": "Thiru php developer"}]
      this.isButtonDisabled = true;

    const formData = new FormData();
    formData.append('form_data', JSON.stringify(form_data));
    formData.append('form_id', this.formFields.id);
    formData.append('user_id', this.formFields.user_id);
    formData.append('profile_id', this.formFields.profile_id);
    formData.append('phone', this.customForm.value.phone ? this.customForm.value.phone: '');
    formData.append('position', this.customForm.value.position ? this.customForm.value.position : '');
    formData.append('company', this.customForm.value.company ? this.customForm.value.company : '');
    formData.append('email', this.customForm.value.email ? this.customForm.value.email:'');
    formData.append('name', this.customForm.value.name ? this.customForm.value.name : '');
    formData.append('file_name', this.uploadImage ? this.uploadImage : '');
    formData.append('lat', this.current_location_latitude);
    formData.append('lng', this.current_location_longitude);
    formData.append('through', 'web');



    this.auth.captureLeadForm(formData).subscribe((response: any) => {
      if (response.success == 1) {
        this.common.success(response.msg);
        // if (response.contact) {
          this.customForm.reset();
          this.closeModal();
          this.isButtonDisabled = false;

          //   if( this.where == 'template'){
          //   this.router.navigate(['/slug-profile/contact'])
          //   }else{
          //  this.router.navigate(['/profile',this.name])
          //   }
       // }
      } else {
        this.common.error(response.msg);
        this.customForm.reset();

      }
    })
  }

 

}
