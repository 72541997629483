import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Pages/login/login.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { SmartObjectsComponent } from './Pages/smart-objects/smart-objects.component';
import { ApplicationsComponent } from './Pages/applications/applications.component';
import { ContactsComponent } from './Pages/contacts/contacts.component';
import { SettingComponent } from './Pages/setting/setting.component';
import { AuthenticationGuard } from './guard/authentication.guard';
import { ForgotpasswordComponent } from './Pages/forgotpassword/forgotpassword.component';
import { SignupComponent } from './Pages/signup/signup.component';
import { ResetPaawordComponent } from './Pages/reset-paaword/reset-paaword.component';
import { ApplicationCalenderComponent } from './Pages/application-calender/application-calender.component';
import { ApplicationCallsmsComponent } from './Pages/application-callsms/application-callsms.component';
import { ApplicationContactcardComponent } from './Pages/application-contactcard/application-contactcard.component';
import { ApplicationCvComponent } from './Pages/application-cv/application-cv.component';
import { ApplicationDirectionComponent } from './Pages/application-direction/application-direction.component';
import { ApplicationEmailComponent } from './Pages/application-email/application-email.component';
import { ApplicationEMenuComponent } from './Pages/application-e-menu/application-e-menu.component';
import { ApplicationEmergencyComponent } from './Pages/application-emergency/application-emergency.component';
import { ApplicationEventComponent } from './Pages/application-event/application-event.component';
import { ApplicationLinkComponent } from './Pages/application-link/application-link.component';
import { ApplicationLinktreeComponent } from './Pages/application-linktree/application-linktree.component';
import { ApplicationLostnfoundComponent } from './Pages/application-lostnfound/application-lostnfound.component';
import { ApplicationSequencerComponent } from './Pages/application-sequencer/application-sequencer.component';
import { ApplicationSocialmediaComponent } from './Pages/application-socialmedia/application-socialmedia.component';
import { ApplicationTextnoteComponent } from './Pages/application-textnote/application-textnote.component';
import { ApplicationWhatsappComponent } from './Pages/application-whatsapp/application-whatsapp.component';
import { ApplicationWifiComponent } from './Pages/application-wifi/application-wifi.component';
import { PrivacyPolicyComponent } from './Pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './Pages/terms-condition/terms-condition.component';
import { TutorialComponent } from './Pages/tutorial/tutorial.component';
import { ProfilePageComponent } from './Pages/profile-page/profile-page.component';
import { ProfileContactFormComponent } from './Pages/profile-contact-form/profile-contact-form.component';
import { AppLostFoundComponent } from './Pages/app-lost-found/app-lost-found.component';
import { NfcTagComponent } from './Pages/nfc-tag/nfc-tag.component';
import { SlugProfileComponent } from './Pages/slug-profile/slug-profile.component';
import { QrAppServiceComponent } from './Pages/qr-app-service/qr-app-service.component';
import { ContactUsComponent } from './Pages/contact-us/contact-us.component';
import { Template1Component } from './template/template1/template1.component';
import { Template2Component } from './template/template2/template2.component';
import { Template3Component } from './template/template3/template3.component';
import { Template4Component } from './template/template4/template4.component';
import { ProfileRedirectComponent } from './Pages/profile-redirect/profile-redirect.component';
import { AppNotesComponent } from './Pages/app-notes/app-notes.component';
//import { ProfileTemplateComponent } from './profile-template/profile-template.component';
import { ProfileRedirect2Component } from './Pages/profile-redirect2/profile-redirect2.component';
import { Profilev2Component } from './Pages/profilev2/profilev2.component';
import { ReceivedContactsComponent } from './Pages/received-contacts/received-contacts.component';
import { ReceivedContacts2Component } from './Pages/received-contacts2/received-contacts2.component';
import { InvitationComponent } from './Pages/invitation/invitation.component';

const routes: Routes = [
  {
    path: '',
    //pathMatch: 'full',
    component: LoginComponent,
  },
  // {
  //   path: 'profileTemplate',
  //   component: ProfileTemplateComponent,
  // },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent,
  },
  {
    path: 'sign_up',
    component: SignupComponent,
  },
  {
    path: 'reset_password/:token/:id',
    component: ResetPaawordComponent,
  },
  {
    path: 'smart',
    component: SmartObjectsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'application',
    component: ApplicationsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'contact',
    component: ContactsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'calendar',
    component: ApplicationCalenderComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'callsms',
    component: ApplicationCallsmsComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'contactcard',
    component: ApplicationContactcardComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'cv',
    component: ApplicationCvComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'email',
    component: ApplicationEmailComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'direction',
    component: ApplicationDirectionComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'emergency',
    component: ApplicationEmergencyComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'eMenu',
    component: ApplicationEMenuComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'event',
    component: ApplicationEventComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'link',
    component: ApplicationLinkComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'linktree',
    component: ApplicationLinktreeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'lostnfound',
    component: ApplicationLostnfoundComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'sequencer',
    component: ApplicationSequencerComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'socialmedia',
    component: ApplicationSocialmediaComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'contactus',
    component: ContactUsComponent,
  },
  {
    path: 'textnote',
    component: ApplicationTextnoteComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'whatsapp',
    component: ApplicationWhatsappComponent,
    canActivate: [AuthenticationGuard]
  }, {
    path: 'wifi',
    component: ApplicationWifiComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'terms',
    component: TermsConditionComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'tutorial',
    component: TutorialComponent,
    canActivate: [AuthenticationGuard]
  },


 // ======Mobile view Pages   =========//
  {
    path: 'profile/:name',
    component: ProfilePageComponent,
  },


  {
    path: 'profile/:name/:id/share', // for multiple accounts
    component: ProfilePageComponent, 
  },


  {
    path: 'nfc/:id',
    component: NfcTagComponent,
  },
  {
    path: 'notes/:id',
    component: AppNotesComponent,
  },
  {
    path: 'slug-profile/:slug/:id',
    component: SlugProfileComponent,
  },

  {
    path: 'app/:slug/:id',
    component: QrAppServiceComponent,
  },
  {
    path: 'profile-contact-form/:name/:id/:where',
    component: ProfileContactFormComponent,
  },
  {
    path: 'template1',       // Contact Card Theme 1
    component: Template1Component,
  },
  {
    path: 'template2',       // Contact Card Theme 2
    component: Template2Component,
  },
  {
    path: 'template3',       // Contact Card Theme 3
    component: Template3Component,
  },
  {
    path: 'template4',       // Contact Card Theme 4
    component: Template4Component,
  },
  {
    path: 'profileversiontwo',       
    component: Profilev2Component,
  },
  {
    path: 'received-contacts',       
    component: ReceivedContactsComponent,
  },
  // {
  //   path: 'Profile/:name',
  //   component: ProfileRedirectComponent,
  // },
  // {
  //   path: 'userProfile/:name',
  //   component: ProfileRedirect2Component,
  // },
  {
    path: 'received-contacts2',       
    component: ReceivedContacts2Component,
  },
  {
    path: 'invitation',       
    component: InvitationComponent,
  },

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
