<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-center mb-5">
            <h2 class="font-w600 mb-0 mr-auto text_opacity_68 letterspace">Overview</h2>
        </div>


        <div class="row ">

            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll custom_scrollbar d-flex flex-column">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">

                                    <div class="d-flex flex-column align-items-center profile_img mt-3">
                                        <div class="profile_img_container position-relative">
                                            <img *ngIf="userData.photo" src="{{src}}" alt="image">
                                            <img *ngIf="!userData.photo" class="mx-2" src="assets/images/dummy_user.png"
                                                alt="image">
                                            <input type="file" (change)="profilePic($event)" accept=".png, .jpg, .jpeg">
                                        </div>
                                        <p class="fs17 font-weight-bold mt-3">Profile Photo</p>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="row mt-5">
                                        <div class="col-md-6">
                                            <form #profileForm="ngForm" (ngSubmit)="profile(profileForm)">
                                                <div class="form-row">
                                                    <div class="form-group w-100">
                                                        <label class="fs17 font-weight-bold"> Username</label>
                                                        <input type="text" name="username" #username="ngModel"
                                                            [(ngModel)]="userData.username" class="form-control">
                                                    </div>

                                                    <div class="form-group w-100">
                                                        <label class="fs17 font-weight-bold"> Email</label>
                                                        <input type="email" class="form-control" name="account_email"
                                                            #email="ngModel" [(ngModel)]="userData.account_email">
                                                    </div>
                                                </div>
                                                <button type="submit"
                                                    class="btn btn-primary btn-block rounded-pill mt-4 w-50 mx-auto mb-4 mb-sm-0">Submit</button>
                                            </form>
                                            <!-- Form row end -->


                                        </div>

                                        <div class="col-md-6">
                                            <form #passwordForm="ngForm" (ngSubmit)="changePassword(passwordForm)">
                                                <div class="form-row">
                                                    <div class="form-group w-100">
                                                        <label class="fs17 font-weight-bold"> Current Password</label>
                                                        <input type="password" class="form-control" name="pass"
                                                            #pass="ngModel" [(ngModel)]="CPassword.pass">
                                                    </div>

                                                    <div class="form-group w-100">
                                                        <label class="fs17 font-weight-bold"> New Password</label>
                                                        <input type="password" class="form-control"
                                                            #newPassword="ngModel" [(ngModel)]="CPassword.newPassword"
                                                            name="newPassword">
                                                    </div>

                                                    <div class="form-group w-100">
                                                        <label class="fs17 font-weight-bold"> Confirm Password</label>
                                                        <input type="password" class="form-control"
                                                            #confirmPassword="ngModel"
                                                            [(ngModel)]="CPassword.confirmPassword"
                                                            name="confirmPassword">
                                                    </div>
                                                </div>
                                                <button type="submit"
                                                    class="btn btn-primary btn-block rounded-pill mt-4 w-50 mx-auto">Submit</button>
                                            </form>
                                            <!-- Form row end -->


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- </form> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="contact_detail">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="card_header_pre d-flex justify-content-between pb-0">
                <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                <div class="preview_icons d-flex align-items-center">
                    <!-- <a href=""><img src="images/download-24px.svg" /></a>
                    <a href=""><img src="images/create-24px.svg" /></a>
                    <a href=""><img src="images/delete_outline-24px.svg" /></a> -->
                </div>
            </div>
            <button type="button" class="close" data-dismiss="modal">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_823" data-name="Path 823"
                        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                        fill="#b6b8c7" />
                </svg>
            </button>
            <div class="modal-body">
                <div class="user_profile d-flex align-items-center">

                    <img src="assets/images/img_user.jpg" alt="" />
                    <div class="user_info d-flex flex-column">
                        <span class="fs17 text_white font-weight-bold">Remi Dupont</span>
                        <span class="fs14 pt-1">ESSEC/Student</span>
                    </div>
                </div>

                <ul class="list-unstyled user_info_list pt-3">
                    <li>
                        <label class="fs14 m-0">Phone Number</label>
                        <p class="text_white text_opacity_68">0033616930225</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Work Number</label>
                        <p class="text_white text_opacity_68">0033616930224</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Website</label>
                        <p class="text_white text_opacity_68">www.b-intouch.fr</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Home Address</label>
                        <p class="text_white text_opacity_68">23 Rue Louis Pouey, 92800, Puteaux
                            France</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Birthday</label>
                        <p class="text_white text_opacity_68">11/04/1991</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Email </label>
                        <p class="text_white text_opacity_68">aaalfarra1@gmail.com</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Paypal</label>
                        <p class="text_white text_opacity_68">aboud.atassi.paris@gmail.com</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Home Address</label>
                        <p class="text_white text_opacity_68">231 Rue Saint honoré, 75001, Paris
                            France</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>