<div id="main-wrapper">
    <!--**********************************
        Nav header start
    ***********************************-->
    <div class="nav-header">
        <a routerLink="/dashboard" class="brand-logo">
          <img class="d-none darkversion_logo" id="logo_full" src="../../../assets/images/betalogo.png" />
          <img class="d-none lightversion_logo" id="logo_full" src="../../../assets/images/betalogolight.png" />
          <img id="logo_small" class="dark_logo_small" src="../../../assets/images/logo.png">
          <img id="logo_small" class="light_logo_small" src="../../../assets/images/logolight.png">
        </a>

        <div class="nav-control" (click)="openLeftMenu()">
            <div class="hamburger"><span class="line"></span><span class="line"></span><span class="line"></span></div>
        </div>
    </div>
    <!--**********************************
        Nav header end
    ***********************************-->

    <div class="header header-right d-xl-none">
        <div class="header-profile p-3 text-right" id="right_menu" (click)="openrightMenu()">
            <img *ngIf="userData?.photo" width="20" src="{{ImageUrl}}{{userData?.photo}}" />
            <img *ngIf="!userData?.photo" width="20" src="assets/images/dummy_user.png" />
            <svg x="0px" y="0px" width="12px" height="12px" class="imgdropdown" viewBox="0 0 292.362 292.362" style="enable-background: new 0 0 292.362 292.362; fill: #fff;" xml:space="preserve">
                <path
                    d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424   C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428   s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
                />
            </svg>
        </div>
    </div>

    <!--**********************************
        Left Sidebar start
    ***********************************-->

    <div class="deznav">
        <div class="deznav-scroll metismenuscroll">
            <ul class="metismenu" id="menu">
                <li>
                    <a routerLink="/dashboard" aria-expanded="false" routerLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="home-black-18dp" opacity="0.5">
                                <path id="Path_771" data-name="Path 771" d="M0,0H24V24H0Z" fill="none" />
                                <path id="Path_772" data-name="Path 772" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" fill="#fff" />
                            </g>
                        </svg>

                        <span class="nav-text">Dashboard</span>
                    </a>
                </li>

                <li>
                    <a routerLink="/smart" aria-expanded="false" routerLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="speaker_phone-24px" opacity="0.5">
                                <path id="Path_779" data-name="Path 779" d="M0,0H24V24H0ZM0,0H24V24H0Z" fill="none" />
                                <path
                                    id="Path_780"
                                    data-name="Path 780"
                                    d="M7,7.07,8.43,8.5a5.046,5.046,0,0,1,7.14,0L17,7.07a7.074,7.074,0,0,0-10,0ZM12,1A10.963,10.963,0,0,0,4.25,4.21L5.66,5.62a8.981,8.981,0,0,1,12.68,0l1.41-1.41A10.963,10.963,0,0,0,12,1Zm2.86,9.01L9.14,10A1.139,1.139,0,0,0,8,11.14v9.71a1.139,1.139,0,0,0,1.14,1.14h5.71a1.139,1.139,0,0,0,1.14-1.14V11.14a1.116,1.116,0,0,0-1.13-1.13ZM15,20H9V12h6Z"
                                    fill="#fff"
                                />
                            </g>
                        </svg>
                        <span class="nav-text">Smart Objects</span>
                    </a>
                </li>

                <li>
                    <a routerLink="/application" aria-expanded="false" routerLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="dynamic_feed-24px" opacity="0.496">
                                <g id="Group_923" data-name="Group 923">
                                    <rect id="Rectangle_307" data-name="Rectangle 307" width="24" height="24" fill="none" />
                                </g>
                                <g id="Group_925" data-name="Group 925">
                                    <g id="Group_924" data-name="Group 924">
                                        <path id="Path_774" data-name="Path 774" d="M8,8H6v7a2.006,2.006,0,0,0,2,2h9V15H8Z" fill="#fff" />
                                        <path id="Path_775" data-name="Path 775" d="M20,3H12a2.006,2.006,0,0,0-2,2v6a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,20,3Zm0,8H12V7h8Z" fill="#fff" />
                                        <path id="Path_776" data-name="Path 776" d="M4,12H2v7a2.006,2.006,0,0,0,2,2h9V19H4Z" fill="#fff" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span class="nav-text">Applications</span>
                    </a>
                </li>

                <li>
                    <a routerLink="/contact" aria-expanded="false" routerLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                            <g id="contacts-24px" opacity="0.496">
                                <path id="Path_781" data-name="Path 781" d="M0,0H22V22H0ZM0,0H22V22H0ZM0,0H22V22H0Z" fill="none" />
                                <path
                                    id="Path_782"
                                    data-name="Path 782"
                                    d="M18.5,0H3.833V1.833H18.5ZM3.833,22H18.5V20.167H3.833ZM18.5,3.667H3.833A1.839,1.839,0,0,0,2,5.5v11a1.839,1.839,0,0,0,1.833,1.833H18.5A1.839,1.839,0,0,0,20.333,16.5V5.5A1.839,1.839,0,0,0,18.5,3.667ZM11.167,6.188A2.063,2.063,0,1,1,9.1,8.25,2.066,2.066,0,0,1,11.167,6.188Zm4.583,9.4H6.583V14.208c0-1.531,3.053-2.292,4.583-2.292s4.583.761,4.583,2.292Z"
                                    transform="translate(-0.167)"
                                    fill="#fff"
                                />
                            </g>
                        </svg>
                        <span class="nav-text">Contacts</span>
                    </a>
                </li>

                <li>
                    <a (click)="gotosetting()" aria-expanded="false" routerLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="settings-24px" opacity="0.496">
                                <path id="Path_777" data-name="Path 777" d="M0,0H24V24H0Z" fill="none" />
                                <path
                                    id="Path_778"
                                    data-name="Path 778"
                                    d="M19.14,12.94A7.074,7.074,0,0,0,19.2,12a5.777,5.777,0,0,0-.07-.94l2.03-1.58a.491.491,0,0,0,.12-.61L19.36,5.55a.488.488,0,0,0-.59-.22l-2.39.96a7.064,7.064,0,0,0-1.62-.94L14.4,2.81a.484.484,0,0,0-.48-.41H10.08a.474.474,0,0,0-.47.41L9.25,5.35a7.22,7.22,0,0,0-1.62.94L5.24,5.33a.477.477,0,0,0-.59.22L2.74,8.87a.455.455,0,0,0,.12.61l2.03,1.58a5.563,5.563,0,0,0-.02,1.88L2.84,14.52a.491.491,0,0,0-.12.61l1.92,3.32a.488.488,0,0,0,.59.22l2.39-.96a7.064,7.064,0,0,0,1.62.94l.36,2.54a.492.492,0,0,0,.48.41h3.84a.466.466,0,0,0,.47-.41l.36-2.54a6.859,6.859,0,0,0,1.62-.94l2.39.96a.477.477,0,0,0,.59-.22l1.92-3.32a.463.463,0,0,0-.12-.61ZM12,15.6A3.6,3.6,0,1,1,15.6,12,3.611,3.611,0,0,1,12,15.6Z"
                                    fill="#fff"
                                />
                            </g>
                        </svg>
                        <span class="nav-text">Settings</span>
                    </a>
                </li>

                <li>
                    <a routerLink="/tutorial" aria-expanded="false" routerLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="play_circle_filled-24px" opacity="0.496">
                                <path id="Path_783" data-name="Path 783" d="M0,0H24V24H0Z" fill="none" />
                                <path id="Path_784" data-name="Path 784" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,16.5v-9L16,12Z" fill="#fff" />
                            </g>
                        </svg>
                        <span class="nav-text">Tutorials</span>
                    </a>
                </li>

                <li class="networking">
                    <a aria-expanded="false" routerLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.309" height="21.309" viewBox="0 0 21.309 21.309">
                            <path opacity="0.5" id="network-svgrepo-com_1_" data-name="network-svgrepo-com (1)" d="M18.539,0a2.27,2.27,0,0,0-2,3.352L12.825,7.071a3.007,3.007,0,0,0-3.706,0l-2.9-2.9a1.493,1.493,0,0,0,.215-.764A1.513,1.513,0,1,0,4.918,4.918,1.493,1.493,0,0,0,5.682,4.7l2.9,2.9a3.007,3.007,0,0,0,0,3.706L3.792,16.1a2.247,2.247,0,1,0,.473.6l4.854-4.854a3.009,3.009,0,0,0,1.475.613V16.3a2.27,2.27,0,1,0,.757,0V12.459a3.009,3.009,0,0,0,1.475-.613l2.9,2.9a1.492,1.492,0,0,0-.215.764A1.513,1.513,0,1,0,17.025,14a1.493,1.493,0,0,0-.764.215l-2.9-2.9a3.007,3.007,0,0,0,0-3.706l3.657-3.657A2.268,2.268,0,1,0,18.539,0ZM4.162,3.405a.757.757,0,1,1,.757.757A.757.757,0,0,1,4.162,3.405ZM2.27,19.3a1.513,1.513,0,1,1,1.513-1.513A1.515,1.515,0,0,1,2.27,19.3Zm10.215-.757a1.513,1.513,0,1,1-1.513-1.513A1.515,1.515,0,0,1,12.485,18.539Zm-1.513-6.81a2.27,2.27,0,1,1,2.27-2.27A2.272,2.272,0,0,1,10.972,11.728Zm6.81,3.783a.757.757,0,1,1-.757-.757A.757.757,0,0,1,17.782,15.512Zm.757-11.728A1.513,1.513,0,1,1,20.052,2.27,1.515,1.515,0,0,1,18.539,3.783Z" transform="translate(0.25 0.25)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
                          </svg>
                        <span class="nav-text">Networking Lounge
                            <span class="coming-soon">Coming Soon</span>
                        </span>
                    </a>
                </li>

                <li>
                    <a routerLink="/tutorial" aria-expanded="false" routerLinkActive="active">
                        <svg id="qr_code_2_white_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="Group_1967" data-name="Group 1967">
                              <rect id="Rectangle_5555" data-name="Rectangle 5555" width="24" height="24" fill="none"/>
                            </g>
                            <path opacity="0.5" id="Path_3157" data-name="Path 3157" d="M15,21H13V19h2Zm-2-7H11v5h2Zm8-2H19v4h2Zm-2-2H17v2h2ZM7,12H5v2H7ZM5,10H3v2H5Zm7-5h2V3H12ZM4.5,4.5v3h3v-3ZM9,9H3V3H9ZM4.5,16.5v3h3v-3ZM9,21H3V15H9ZM16.5,4.5v3h3v-3ZM21,9H15V3h6ZM19,19V16H15v2h2v3h4V19Zm-2-7H13v2h4Zm-4-2H7v2H9v2h2V12h2Zm1-1V7H12V5H10V9ZM6.75,5.25H5.25v1.5h1.5Zm0,12H5.25v1.5h1.5Zm12-12h-1.5v1.5h1.5Z" fill="#fff"/>
                          </svg>
                        <span class="nav-text">QR Codes</span>
                    </a>
                </li>
            </ul>
            </div>
                <div class="deznav-scroll social_report_scroll">
            <ul class="social_report">
                <li *ngFor="let click of Clicklist">
                    <div class="card flex-row p-3 rounded-0 align-items-center">
                        <div class="social_icon mr-4">
                            <img src="{{base_url}}/{{click?.image}}" alt="" />
                        </div>
                        <div class="social_counts">
                            <p class="m-0 small">Clicks</p>
                            <h3 class="count m-0">{{click.total}}</h3>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!--**********************************
        Sidebar end
    ***********************************-->

    <!--**********************************
        Content body start
    ***********************************-->

    <!--**********************************
        Content body end
    ***********************************-->

    <!--**********************************
        Right Sidebar
    ***********************************-->
    <div class="right_sidebar">
        <div class="deznav-scroll">
            <div class="right_menu">
                <div class="d-flex justify-content-between">
                    <ul class="d-flex justify-content-between header-right main-notification pl-4 pl-sm-0">
                        <li class="nav-item dropdown header-profile">
                            <a class="nav-link" role="button" data-toggle="dropdown">
                                <!-- <img src="../../../assets/images/profile.jpg" width="20" alt=""/> -->
                                <img *ngIf="userData?.photo" width="20" src="{{ImageUrl}}{{userData?.photo}}" />
                                <img *ngIf="!userData?.photo" width="20" src="assets/images/dummy_user.png" />
    
                                <div class="header-info">
                                    <span class="d-flex align-items-center">
                                        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                                            <path id="Path_20" data-name="Path 20" d="M2,16v2H22V16Zm0-5v2H22V11ZM2,6V8H22V6Z" transform="translate(-2 -6)" fill="#e3e3e3"/>
                                          </svg>
                                        {{userData.username}}</span>
                                    <!-- <small>{{userData.email}}</small> -->
                                    <small> {{userData?.account_email}}</small>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a (click)="gotosetting()" class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-1.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.676" height="19.2" viewBox="0 0 18.676 19.2">
                                            <path id="Path_866" data-name="Path 866" d="M19.14,12.94A7.074,7.074,0,0,0,19.2,12a5.777,5.777,0,0,0-.07-.94l2.03-1.58a.491.491,0,0,0,.12-.61L19.36,5.55a.488.488,0,0,0-.59-.22l-2.39.96a7.064,7.064,0,0,0-1.62-.94L14.4,2.81a.484.484,0,0,0-.48-.41H10.08a.474.474,0,0,0-.47.41L9.25,5.35a7.22,7.22,0,0,0-1.62.94L5.24,5.33a.477.477,0,0,0-.59.22L2.74,8.87a.455.455,0,0,0,.12.61l2.03,1.58a5.563,5.563,0,0,0-.02,1.88L2.84,14.52a.491.491,0,0,0-.12.61l1.92,3.32a.488.488,0,0,0,.59.22l2.39-.96a7.064,7.064,0,0,0,1.62.94l.36,2.54a.492.492,0,0,0,.48.41h3.84a.466.466,0,0,0,.47-.41l.36-2.54a6.859,6.859,0,0,0,1.62-.94l2.39.96a.477.477,0,0,0,.59-.22l1.92-3.32a.463.463,0,0,0-.12-.61ZM12,15.6A3.6,3.6,0,1,1,15.6,12,3.611,3.611,0,0,1,12,15.6Z" transform="translate(-2.662 -2.4)" fill="#fff"/>
                                        </svg>
                                    </span>
                                    <span class="ml-2">Subscription</span>
                                </a>
                                <a class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-2.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.708" height="19.928" viewBox="0 0 20.708 19.928">
                                            <defs>
                                              <clipPath id="clip-path">
                                                <path id="Path_813" data-name="Path 813" d="M342.858,270.727a2.681,2.681,0,0,1-2.1,4.339h-3.591v-3.635h3.591a.954.954,0,0,1,0,1.908h-1.864v-1.083h-.824v1.908h2.689a1.775,1.775,0,0,0,1.3-2.985,3.488,3.488,0,0,0,.8-.451m-6.518-1.848v.825h4.416a2.655,2.655,0,0,1,1.483.451,2.66,2.66,0,0,1-1.483.451H336.34v5.285h4.416a3.5,3.5,0,0,0,2.7-5.735,3.507,3.507,0,0,0-2.7-5.736H336.34v4.459Zm6.518.7a3.5,3.5,0,0,0-.8-.452,1.779,1.779,0,0,0-1.3-2.985h-2.688v1.908h.824v-1.083h1.864a.954.954,0,0,1,0,1.908h-3.591v-3.635h3.591a2.677,2.677,0,0,1,2.1,4.339Z" transform="translate(-336.34 -264.42)" fill="#fff"/>
                                              </clipPath>
                                            </defs>
                                            <g id="Layer_1" transform="translate(-239.858 -182.63)">
                                              <g id="Group_5" data-name="Group 5" transform="translate(240.215 182.63)">
                                                <path id="Path_811" data-name="Path 811" d="M240.248,226.807l4.857-8.414a.245.245,0,0,1,.212-.123h6.059v.491H245.46l-4.716,8.169,4.716,8.169h9.432l4.5-7.8h.567l-4.714,8.164a.245.245,0,0,1-.212.123h-9.716a.244.244,0,0,1-.212-.123l-4.857-8.414A.244.244,0,0,1,240.248,226.807Z" transform="translate(-240.215 -216.011)" fill="#fff" stroke="#f7f7f7" stroke-width="0.7"/>
                                                <g id="Group_3" data-name="Group 3" transform="translate(6.092 5.183)">
                                                  <path id="Path_812" data-name="Path 812" d="M342.858,270.727a2.681,2.681,0,0,1-2.1,4.339h-3.591v-3.635h3.591a.954.954,0,0,1,0,1.908h-1.864v-1.083h-.824v1.908h2.689a1.775,1.775,0,0,0,1.3-2.985,3.488,3.488,0,0,0,.8-.451m-6.518-1.848v.825h4.416a2.655,2.655,0,0,1,1.483.451,2.66,2.66,0,0,1-1.483.451H336.34v5.285h4.416a3.5,3.5,0,0,0,2.7-5.735,3.507,3.507,0,0,0-2.7-5.736H336.34v4.459Zm6.518.7a3.5,3.5,0,0,0-.8-.452,1.779,1.779,0,0,0-1.3-2.985h-2.688v1.908h.824v-1.083h1.864a.954.954,0,0,1,0,1.908h-3.591v-3.635h3.591a2.677,2.677,0,0,1,2.1,4.339Z" transform="translate(-336.34 -264.42)" fill="#fff"/>
                                                  <g id="Group_2" data-name="Group 2" clip-path="url(#clip-path)">
                                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="7.921" height="11.471" transform="translate(0 -0.001)" fill="#fff"/>
                                                  </g>
                                                </g>
                                                <g id="Group_4" data-name="Group 4" transform="translate(12.236)">
                                                  <path id="Path_814" data-name="Path 814" d="M436.3,241.389a4.22,4.22,0,0,0-2.677-1.824c-.045-.009-.09-.016-.134-.025l-.188.9a3.5,3.5,0,0,1,.753.247,3.608,3.608,0,0,1,2.084,3.043,3.473,3.473,0,0,1-.043.792l.911.152c.009-.044.018-.089.026-.134A4.217,4.217,0,0,0,436.3,241.389Z" transform="translate(-433.3 -235.933)" fill="#fff"/>
                                                  <path id="Path_815" data-name="Path 815" d="M443.268,213.683a6.052,6.052,0,0,0-3.821-2.587l-.134-.026-.193.931.134.026a5.345,5.345,0,0,1,4.107,6c-.008.045-.016.09-.024.134l.938.157c.008-.045.017-.089.024-.134A6.055,6.055,0,0,0,443.268,213.683Z" transform="translate(-438.751 -209.268)" fill="#fff"/>
                                                  <path id="Path_816" data-name="Path 816" d="M451.641,191.847a8.14,8.14,0,0,0-6.293-9.191l-.134-.026-.193.931.134.026a7.187,7.187,0,0,1,5.549,8.1c-.008.045-.016.09-.024.134l.938.157C451.625,191.937,451.633,191.892,451.641,191.847Z" transform="translate(-444.277 -182.63)" fill="#fff"/>
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                    </span>
                                    <span class="ml-2">Mobile App</span>
                                </a>
                                <a (click)="gotoTouchShop()" class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-3.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                            <g id="Group_972" data-name="Group 972" transform="translate(-2 -4)">
                                              <path id="Path_867" data-name="Path 867" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4ZM4,9H14.5v3.5H4Zm0,5.5H14.5V18H4ZM20,18H16.5V9H20Z" fill="#fff"/>
                                            </g>
                                          </svg>
                                    </span>
                                    <span class="ml-2">B-in-Touch Shop </span>
                                </a>
                                <a  [routerLink]="['/contactus']" target="_blank" class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-4.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <path id="Path_862" data-name="Path 862" d="M6.62,10.79a15.149,15.149,0,0,0,6.59,6.59l2.2-2.2a.994.994,0,0,1,1.02-.24,11.407,11.407,0,0,0,3.57.57,1,1,0,0,1,1,1V20a1,1,0,0,1-1,1A17,17,0,0,1,3,4,1,1,0,0,1,4,3H7.5a1,1,0,0,1,1,1,11.36,11.36,0,0,0,.57,3.57,1,1,0,0,1-.25,1.02Z" transform="translate(-3 -3)" fill="#fff"/>
                                          </svg>
                                    </span>
                                    <span class="ml-2">Contact Us </span>
                                </a>
                                <a target="_blank" class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-5.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <path id="Path_1023" data-name="Path 1023" d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2Zm6.93,6H15.97a15.649,15.649,0,0,0-1.38-3.56A8.03,8.03,0,0,1,18.92,8ZM12,4.04A14.087,14.087,0,0,1,13.91,8H10.09A14.087,14.087,0,0,1,12,4.04ZM4.26,14a7.822,7.822,0,0,1,0-4H7.64a16.515,16.515,0,0,0-.14,2,16.515,16.515,0,0,0,.14,2Zm.82,2H8.03a15.649,15.649,0,0,0,1.38,3.56A7.987,7.987,0,0,1,5.08,16ZM8.03,8H5.08A7.987,7.987,0,0,1,9.41,4.44,15.649,15.649,0,0,0,8.03,8ZM12,19.96A14.087,14.087,0,0,1,10.09,16h3.82A14.087,14.087,0,0,1,12,19.96ZM14.34,14H9.66a14.713,14.713,0,0,1-.16-2,14.585,14.585,0,0,1,.16-2h4.68a14.585,14.585,0,0,1,.16,2A14.713,14.713,0,0,1,14.34,14Zm.25,5.56A15.649,15.649,0,0,0,15.97,16h2.95A8.03,8.03,0,0,1,14.59,19.56ZM16.36,14a16.515,16.515,0,0,0,.14-2,16.515,16.515,0,0,0-.14-2h3.38a7.822,7.822,0,0,1,0,4Z" transform="translate(-2 -2)" fill="#fff"/>
                                          </svg>
                                    </span>
                                    <span class="ml-2">Language</span>
                                </a>
                                <a target="_blank" class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-6.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <g id="Group_1999" data-name="Group 1999" transform="translate(-2 -2)">
                                              <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.5" cy="1.5" r="1.5" transform="translate(14 8)" fill="#fff"/>
                                              <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.5" cy="1.5" r="1.5" transform="translate(7 8)" fill="#fff"/>
                                              <path id="Path_81" data-name="Path 81" d="M12,16a4,4,0,0,1-3.45-2H6.88a5.495,5.495,0,0,0,10.24,0H15.45A3.984,3.984,0,0,1,12,16ZM11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" fill="#fff"/>
                                            </g>
                                          </svg>
                                    </span>
                                    <span class="ml-2">About Us</span>
                                </a>
                                <a target="_blank" class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-7.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22.59" viewBox="0 0 19 22.59">
                                            <path id="Path_73" data-name="Path 73" d="M11,23.59v-3.6A9.5,9.5,0,1,1,21,10.5c0,4.95-3.44,9.93-8.57,12.4ZM11.5,3a7.5,7.5,0,0,0,0,15H13v2.3c3.64-2.3,6-6.08,6-9.8A7.5,7.5,0,0,0,11.5,3Zm-1,11.5h2v2h-2Zm2-1.5h-2c0-3.25,3-3,3-5a2,2,0,0,0-4,0h-2a4,4,0,0,1,8,0C15.5,10.5,12.5,10.75,12.5,13Z" transform="translate(-2 -1)" fill="#fff"/>
                                          </svg>
                                    </span>
                                    <span class="ml-2">FAQ</span>
                                </a>
                                <a (click)="logout()" class="dropdown-item ai-icon">
                                    <!-- <img src="assets/images/acc-8.svg" /> -->
                                    <span class="icn-outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_970" data-name="Group 970" transform="translate(-3 -3)">
                                              <path id="Path_864" data-name="Path 864" d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z" fill="#fff"/>
                                            </g>
                                        </svg>
                                    </span>
                                    <span class="ml-2">Logout </span>
                                </a>
                            </div>
                        </li> 
                    </ul>
                    <ul class="d-flex justify-content-between header-right main-notification px-4">
                        <li class="nav-item dropdown notification_dropdown">
                            <a class="nav-link ai-icon" *ngIf="notCount != 0" href="javascript:void(0)" role="button" data-toggle="dropdown">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    id="Layer_1"
                                    x="0px"
                                    y="0px"
                                    width="24px"
                                    height="24px"
                                    viewBox="-64 109.6 24 24"
                                    style="enable-background: new -64 109.6 24 24;"
                                    xml:space="preserve"
                                >
                                    <style type="text/css">
                                        .st0 {
                                            fill: none;
                                            stroke: #ffffff;
                                            stroke-width: 2;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                        }
                                    </style>
                                    <path class="st0" d="M-55.1,129h6c0,2-1.3,3.6-3,3.6C-53.8,132.6-55.1,131-55.1,129z" />
                                    <path class="st0" d="M-52.1,128.9H-63c0,0,5.4-3.5,5.5-12.7v0c0.1-3,2.5-5.4,5.5-5.4v0c0,0,0.1,0,0.1,0s0.1,0,0.1,0v0  c3,0.1,5.4,2.5,5.5,5.4l0,0c0.1,9.2,5.5,12.7,5.5,12.7H-52.1z" />
                                </svg>
                                <span class="badge light text-white rounded-circle" *ngIf="notCount">{{notCount}}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <div class="notification_header d-flex justify-content-between align-items-center p-3">
                                    
                                    <a class="small" (click)="deletenoti()">Clear All</a>
                                </div>
                                <div id="dlab_W_Notification1" class="dz-scroll height380">
                                    <ul class="timeline notificatin_list p-3">
                                        <li *ngFor="let list of notList">
                                            <div class="d-flex flex-column notification_container pb-2">
                                                <p class="mb-1">{{list.timeDuration}}</p>
                                                <div class="font-weight-bold">
                                                    {{list.title}}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
    
                        <li class="nav-item dropdown notification_dropdown">
                            <a class="nav-link  ai-icon" href="javascript:void(0)" role="button" data-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.061" height="23" viewBox="0 0 24.061 23">
                                    <g id="message_icon" data-name="message icon" transform="translate(1.061 1)">
                                        <path id="Union_3" data-name="Union 3" d="M.622,20.21a4.744,4.744,0,0,0,2.5-3.177,10.282,10.282,0,0,1-2.387-6.6A10.537,10.537,0,0,1,11.37,0,10.538,10.538,0,0,1,22,10.437,10.539,10.539,0,0,1,11.37,20.88a10.734,10.734,0,0,1-4.984-1.218A11.085,11.085,0,0,1,1.27,21C.032,21-.517,20.676.622,20.21Z" fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="2"/>
                                        <circle id="Ellipse_9" data-name="Ellipse 9" cx="1.5" cy="1.5" r="1.5" transform="translate(4.763 8.939)" />
                                        <circle id="Ellipse_12" data-name="Ellipse 12" cx="1.5" cy="1.5" r="1.5" transform="translate(9.835 8.939)" />
                                        <circle id="Ellipse_11" data-name="Ellipse 11" cx="1.5" cy="1.5" r="1.5" transform="translate(14.906 8.939)" />
                                    </g>
                                    </svg>
                                <!-- <span class="badge light text-white bg-primary rounded-circle">12</span> -->
                            </a>
                            <div class="dropdown-menu dropdown-menu-right message_box pb-0">
                                <div class="widget-timeline style-1">
                                <div class="chatbox">
                                <div class="card chat dz-chat-history-box mb-0">
    
                                    <div class="card-body msg_card_body dz-scroll" id="DZ_W_Contacts_Body3">
                                        <div class="msg_card_container d-flex flex-row">
                                        <div class="img_cont_msg mr-2">
                                            <img src="assets/images/chat_user.jpg" class="rounded-circle user_img_msg" alt=""/>
                                            <div class="online_dot"></div>
                                            <div class="offline_dot d-none"></div>
                                        </div>
                                        <div class="d-flex flex-column justify-content-start mb-4">
    
                                            <div class="msg_name d-flex justify-content-between w-100">
                                                <div><h6 class="mb-1 font-weight-bold">Mirella</h6>
                                                    <p class="font-weight-bold m-0">Sales Support</p></div>
                                                <div class="msg_time">2 min ago</div>
                                            </div>
                                            <div class="msg_cotainer font-weight-bold">
                                                    Hello there!
                                                    This is Mirella, your assistant today
                                                    How can I help you?
        
                                            </div>
                                        </div>
                                    </div>
    
                                    </div>
                                    <div class="card-footer type_msg">
                                        <div class="input-group">
                                            <textarea class="form-control" placeholder="Type a message..."></textarea>
                                            <div class="input-group-append">
                                                <button type="button" class="btn"><img src="assets/images/send-24px.svg"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                        </li>
    
                        <!-- <li class="nav-item dropdown notification_dropdown">
                            <a class="nav-link  ai-icon" href="javascript:void(0)" role="button" data-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="-64 109.6 24 24" style="enable-background:new -64 109.6 24 24;" xml:space="preserve">
                                    <style type="text/css">
                                        .st0{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
                                    </style>
                                    <path class="st0" d="M-55.1,129h6c0,2-1.3,3.6-3,3.6C-53.8,132.6-55.1,131-55.1,129z"/>
                                    <path class="st0" d="M-52.1,128.9H-63c0,0,5.4-3.5,5.5-12.7v0c0.1-3,2.5-5.4,5.5-5.4v0c0,0,0.1,0,0.1,0s0.1,0,0.1,0v0  c3,0.1,5.4,2.5,5.5,5.4l0,0c0.1,9.2,5.5,12.7,5.5,12.7H-52.1z"/>
                                    </svg>
                                <span class="badge light text-white rounded-circle">12</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <div class="notification_header d-flex justify-content-between align-items-center p-3">
                                    <h6 class="mb-0">5 New Notification</h6>
                                    <a href="" class="small">Clear All</a>
                                </div>
                                <div id="dlab_W_Notification1" class="dz-scroll height380">
                                    <ul class="timeline notificatin_list p-3">
                                        <li>
                                            <div class="d-flex flex-column notification_container pb-2">
                                                <p class="mb-1">2 min ago</p>
                                                <div class="font-weight-bold">
                                                    Donec dapibus mauris id odio ornare tempus amet.
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex flex-column notification_container pb-2">
                                                <p class="mb-1">2 min ago</p>
                                                <div class="font-weight-bold">
                                                    Donec dapibus mauris id odio ornare tempus amet.
                                                </div>
                                            </div>
                                        </li>
    
                                        <li>
                                            <div class="d-flex flex-column notification_container pb-2">
                                                <p class="mb-1">2 min ago</p>
                                                <div class="font-weight-bold">
                                                    Donec dapibus mauris id odio ornare tempus amet.
                                                </div>
                                            </div>
                                        </li>
    
                                        <li>
                                            <div class="d-flex flex-column notification_container pb-2">
                                                <p class="mb-1">2 min ago</p>
                                                <div class="font-weight-bold">
                                                    Donec dapibus mauris id odio ornare tempus amet.
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>

                <div class="mt-4 px-4">
                    <img class="img-fluid w-100 cardimg" src="../../../assets/images/b-in-touch-black-card.png" alt="" />
                </div>

                <div class="d-flex align-items-center justify-content-between px-4 mt-4 contacts-head">
                    <h5 class="m-0 font-w700">Received Contacts</h5>
                    <a class="small text-white text_opacity_68" routerLink="/contact">See All</a>
                </div>
                <ul class="mt-4 contacts" id="style-4">
                    <li *ngFor="let contact of Contactlist">
                        <div class="card flex-row justify-content-between align-items-strech last-meating">
                            <div class="contact_container mr-4 d-flex align-items-center">
                                <div class="char">{{contact.firstletter}}</div>
                                <div class="contact_name ml-2">
                                    <h5 class="m-0">{{contact.full_name}}</h5>
                                    <p class="m-0 small text_opacity_68">{{contact.position}}</p>
                                </div>
                            </div>

                            <div class="card_info">
                                <a data-toggle="modal" data-target="#contact_complete_detail" (click)="contactData(contact)" class="small text_opacity_68">View Contact</a>
                                <div class="card_date text_opacity_68 small">{{contact.date}}</div>
                            </div>
                        </div>
                    </li>
                    <li *ngIf="Contactlist && Contactlist.length ==0">No Data</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="contact_complete_detail">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div *ngIf="userDetails" class="modal-content">
            <div class="card_header_pre d-flex justify-content-between pb-0">
                <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                <div class="preview_icons d-flex align-items-center">
                    <a href="{{export_contact}}?user_id={{userDetails.base64_id}}" download><img src="assets/images/download-24px.svg" /></a>
                    <a><img src="assets/images/create-24px.svg" /></a>
                    <a (click)="delete(userDetails)"><img src="assets/images/delete_outline-24px.svg" /></a>
                </div>
            </div>
            <button type="button" class="close" data-dismiss="modal">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_823" data-name="Path 823" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#b6b8c7" />
                </svg>
            </button>
            <div class="modal-body">
                <div class="user_profile d-flex align-items-center">
                    <img *ngIf="userDetails?.photo !=''" src="{{ImageUrl}}{{userDetails?.photo}}" />
                    <img *ngIf="userDetails?.photo ==''" src="assets/images/dummy_user.png" />
                    <div class="user_info d-flex flex-column">
                        <span class="fs17 text_white font-weight-bold">{{userDetails?.first_name}} {{userDetails?.last_name}}</span>
                        <span class="fs14 pt-1">{{userDetails?.company}}/{{userDetails?.position}}</span>
                    </div>
                </div>

                <ul class="list-unstyled user_info_list pt-3">
                    <li>
                        <label class="fs14 m-0">Phone Number</label>
                        <p class="text_white text_opacity_68">{{userDetails?.phone}}</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Work Number</label>
                        <p class="text_white text_opacity_68">{{userDetails?.work_phone}}</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Website</label>
                        <p class="text_white text_opacity_68">{{userDetails?.website}}</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Home Address</label>
                        <p class="text_white text_opacity_68">{{userDetails?.address}}, {{userDetails?.postcode}}, {{userDetails?.city}} {{userDetails?.country}}</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Birthday</label>
                        <p class="text_white text_opacity_68">{{userDetails?.dob}}</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Email </label>
                        <p class="text_white text_opacity_68">{{userDetails?.email}}</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Paypal</label>
                        <p class="text_white text_opacity_68">aboud.atassi.paris@gmail.com</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
