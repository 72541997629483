import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-application-email',
  templateUrl: './application-email.component.html',
  styleUrls: ['./application-email.component.scss']
})
export class ApplicationEmailComponent implements OnInit {
  AppEmail: FormGroup
  id: any;
  slug: any;
  emailId: any;
  Appname: FormGroup;

  constructor(private service: ApiService,
    private common: CommonService,
    private router: Router,
    public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public activeroute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.AppEmail = this.formBuilder.group({
      email: ['', Validators.required],
      message: ['', Validators.required],
      subject: ['', Validators.required],
    })

    this.Appname = this.formBuilder.group({
      title: ['Email'],
    })

    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if (this.id) {
        this.getDetails()
      }
    })
  }
  /* === Create Email App === */
  submit() {
    if (this.AppEmail.value.email) {
      var data = new FormData
      if (this.emailId) {
        data.append('id', this.emailId)
      }
      data.append('slug', 'email');
      data.append('content', this.AppEmail.value.email + '?subject=' + this.AppEmail.value.subject + '&body=' + this.AppEmail.value.message);
      this.spinner.show()
      this.service.AppCommon(data).subscribe((res: any) => {
        console.log(res)
        this.spinner.hide()
        if (res.success == '1') {
          this.emailId = res.id
          this.appName()
          this.AppEmail.reset()
          this.router.navigate(['/application'])
          this.common.success(res["msg"]);
        } else {
          this.common.error(res["msg"]);
        }
      }, error => {

      })
    } else {
      this.common.error("Fill the Form");
    }
  }
  /* === Get App Detail === */
  getDetails() {
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('slug', this.slug);
    this.service.getServiceDetail(formData).subscribe((response: any) => {
      console.log(response)
      let data = response.item.content.split('?subject=')
      let data1 = data[1].split('&body=')
      if (response.success == 1) {
        this.emailId = response.item.id;
        this.AppEmail.patchValue({
          email: data[0],
          message: data1[1],
          subject: data1[0]
        })
        this.Appname.patchValue({
          title: response.item.title
        })
      }
    })
  }

  /* === Create App Name === */
  appName() {
    this.spinner.show()
    var data = new FormData
    data.append('slug', 'email');
    data.append('id', this.emailId)
    data.append('title', this.Appname.value.title);
    this.service.editnameApp(data).subscribe((res: any) => {
      console.log()
      this.spinner.hide()
      if (res.http_status == 200) {
      }
    })
  }

}
