<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-center mb-5">
            <h2 class="font-w600 mb-0 mr-auto text_opacity_68 letterspace">Overview / eMenu/Catalog</h2>

            <div class="searchbar">
                <form id="demo-2">
                    <input class="form-control" type="search" placeholder="Search here">
                </form>
            </div>
        </div>


        <div class="row ">

            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll height540 custom_scrollbar d-flex flex-column">
                        <div class="card-body">
                            <form class="bform text_white" [formGroup]="menu">
                                <div class="row">
                                    <div class="col-xl-1 col-xxl-2">
                                    </div>

                                    <div class="col-xl-11 col-xxl-10">

                                        <h4 class="card-title fs21 text_transform_none mb-4">Create Your eMenu or
                                            eCatalog</h4>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label class="fs17 font-weight-bold">Menu/Catalog Title</label>
                                                <input type="text" class="form-control" (blur)="catalogSubmitName()"
                                                    formControlName="menu_name">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-xl-1 col-xxl-2">
                                    </div>
                                    <div class="col-xl-11 col-xxl-10">
                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <label class="fs17 font-weight-bold">Text highlight Color</label>
                                                <input type="color" class="form-control colorPicker"
                                                    formControlName="text_highlight_color" />
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label class="fs17 font-weight-bold">Text Color</label>
                                                <input type="color" class="form-control colorPicker"
                                                    formControlName="text_color" />
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label class="fs17 font-weight-bold">Background Color</label>
                                                <input type="color" class="form-control colorPicker"
                                                    formControlName="bg_color" />
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label class="fs17 font-weight-bold">Categories</label>


                                                <div class="append_new_category" id="cateInput"
                                                    *ngIf="categoryLenght != 0">
                                                    <div class="input-group"
                                                        *ngFor="let category of menuDetails.category"
                                                        (click)="getcat(category.id)"
                                                        [ngClass]="{'active' : category.active === true }">
                                                        <input type="text" class="form-control"
                                                            placeholder="New Category"
                                                            value="{{category.category_name}}" disabled>
                                                        <div class="input-group-append">
                                                            <button class="btn btn_del_icon" type="button"
                                                                (click)="delete(category.id)">
                                                                <img src="assets/images/delete_outline_24px.svg"
                                                                    alt="" />
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="append_new_category" id="cateInput">
                                                    <div class="input-group">
                                                        <form class="w-100" [formGroup]="menucategory">
                                                            <input type="text" class="form-control inputonly"
                                                                placeholder="New Category"
                                                                formControlName="category_name"
                                                                title="Enter for submit">
                                                        </form>
                                                    </div>

                                                </div>
                                                <button id="cateBtn" type="button" class="btn dashbutton fs14 w-100"
                                                    (click)="categorySubmit()"> Add Category</button>
                                            </div>
                                            <div class="form-group col-md-4" *ngIf="categoryLenght != 0">
                                                <label class="fs17 font-weight-bold" id="items_heading">Items</label>
                                                <div class="append_new_category" id="itemInput">
                                                    <ng-container *ngIf="itemsList?.items.length != 0">
                                                        <div class="input-group" *ngFor="let items of itemsList.items"
                                                            (click)="getItems(items?.id, itemsList.id )">
                                                            <input type="text" class="form-control"
                                                                placeholder="New Item" value="{{items?.name}}">
                                                            <div class="input-group-append">
                                                                <button class="btn btn_del_icon" type="button"
                                                                    (click)="DeleteItems(items.id)">
                                                                    <img src="assets/images/delete_outline_24px.svg"
                                                                        alt="" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <!-- <ng-template #blankItem> -->
                                                    <div class="input-group" *ngIf="addItemInput">
                                                        <input type="text" class="form-control" placeholder="New Item"
                                                            [(ngModel)]="itemName" (input)="newItemInput('standAlone')"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                    <!-- </ng-template> -->
                                                </div>
                                                <button id="itemBtn" type="button" class="btn dashbutton fs14 w-100"
                                                    (click)="addItems()"> Add Item</button>
                                            </div>
                                            <div class="col-md-4">
                                                <ng-container *ngIf="showItems == true">
                                                    <form class="bform text_white" [formGroup]="menuItem">
                                                        <div class="add_item_form" id="itemForm">
                                                            <div class="form-row ">
                                                                <div class="form-group col-md-12 ">
                                                                    <label class="fs17 font-weight-bold">Photo</label>
                                                                    <div class="img-upload-outer">
                                                                        <!-- assets/images/cover_photo.png -->
                                                                        <img class="img-fluid" *ngIf="src" src="{{src}}"
                                                                            alt="" />
                                                                        <img class="img-fluid" *ngIf="!src"
                                                                            src="assets/images/cover_photo.png"
                                                                            alt="" />
                                                                        <input type="file" accept=".png, .jpg, .jpeg"
                                                                            (change)="selectItemImage($event)">
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <label class="fs17 font-weight-bold"> Name</label>
                                                                    <input type="text" class="form-control"
                                                                        formControlName="name"
                                                                        (input)="newItemInput('formControl')">
                                                                </div>
                                                            </div>


                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <label
                                                                        class="fs17 font-weight-bold">Description</label>
                                                                    <textarea class="form-control" rows="5"
                                                                        formControlName="description"></textarea>
                                                                </div>
                                                            </div>


                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <label class="fs17 font-weight-bold"> Price</label>
                                                                    <input type="text" class="form-control"
                                                                        formControlName="price">
                                                                </div>
                                                            </div>


                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <label class="fs17 font-weight-bold"> Url</label>
                                                                    <input type="text" class="form-control"
                                                                        formControlName="url">
                                                                </div>
                                                            </div>
                                                            <button (click)="menuItemSubmit()"
                                                                class="btn btn-info btn-xs w-100 mt-4">Save</button>
                                                        </div>
                                                        <!-- </ng-container> -->

                                                    </form>
                                                </ng-container>
                                                <!-- </ng-container> -->
                                            </div>
                                            <!-- <form items> -->

                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12 loginBtn mb-0">
                                        <button class="btn btn-primary btn-block rounded-pill mt-4 w-50 mx-auto"
                                            (click)="catalogSubmit()"> Submit</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div class="card-footer border-0 border_half_width py-0">
                        <hr class="border_default" />
                        <p class="text-center fs17 mb-4">Create & Design your own menu, brochure, or catalog and share
                            it with your friends or clients without having
                            To print and waste any paper.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="card position-relative">
                    <div class="card_header_pre position-absolute">
                        <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    </div>

                    <div class="preview_main text-center">
                        <img class="img-fluid" src="assets/images/preview_jpg3.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner>