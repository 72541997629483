<h1 mat-dialog-title class="modal_head">
    Share your contact with {{profileName}}
    <span class="closebtn" (click)="closeModal()"><img src="assets/images/closeicn.svg"></span>
</h1>
<!--––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– Dynamic Form Creation-------------------––––––––––––––––––––––––––––––––––––––––––––––––––––– -->

<ng-container *ngIf="formFields!=''">
    <div mat-dialog-content class="m-0">
        <div class="content_sec">
            <form [formGroup]="customForm" (ngSubmit)="onSubmitCustomForm($event)">
                
              <div class="input-wrap" *ngFor="let customFields of formFields.connect_fields">
              
                <input *ngIf="customFields?.field_type!='select'" type="{{customFields?.field_type}}" class="cstm-input" placeholder="{{customFields?.field_name}}" [formControlName]="customFields.field_name">
                
                  <div class="text-danger" *ngIf="customForm.get(customFields.field_name)?.hasError('required') && (customForm.get(customFields.field_name)?.dirty || customForm.get(customFields.field_name)?.touched)" >
                    {{customFields.field_name}} is required
                 </div>
                 <div class="text-danger" *ngIf="customForm.get(customFields.field_name)?.hasError('email') && (customForm.get(customFields.field_name)?.dirty || customForm.get(customFields.field_name)?.touched)" >
                    {{customFields.field_name}} is invalid
                 </div>

                 <div *ngIf="customFields?.field_type=='select'">
                    <select class="form-select cstm-input" aria-label="Default select example" [formControlName]="customFields.field_name">
                     
                        <option  *ngFor="let field_option of field_options;let i=index;" selected> {{field_option}}</option>
                    </select>
                 </div>

      
            
              </div>
            
      
            
              <button class="btn connect-btn" type="submit" [disabled]="isButtonDisabled">Connect</button>
              <p class="bottom-p">B in Touch does not sell or share your data</p>
            </form>
        </div>
      </div>
</ng-container>

<!--––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– Static Form Creation-------------------––––––––––––––––––––––––––––––––––––––––––––––––––––– -->


<ng-container *ngIf="formFields==''">
    <div mat-dialog-content class="m-0">
        <div class="content_sec">
            <form [formGroup]="createContactForm" (ngSubmit)="onSubmitForm()">
              <div class="input-wrap upload-contact" *ngIf="moreFields">
                  <input type="file" class="cstm-input" placeholder="Email" accept="text/x-vcard" (change)="getVcfFile($event)">  
                  <h6>Choose from Contacts
                      <img src="assets/images/file-upload.svg">
                  </h6>
              </div>
                <div class="input-wrap">
                    <input type="email" class="cstm-input" placeholder="Email" formControlName="email"> 
                    <ng-container *ngFor="let error of error_messages.email">
                    <div class="text-danger" *ngIf="createContactForm.get('email')?.hasError(error.type) && (createContactForm.get('email')?.dirty || createContactForm.get('email')?.touched)" >
                       {{error.message}}
                    </div>
                  </ng-container>
                </div>
                <div class="input-wrap">
                  <input  type="text" class="cstm-input" placeholder="Name" formControlName="name">
                  <div class="text-danger" *ngIf="createContactForm.get('name')?.hasError(error_messages.name.type) && (createContactForm.get('name')?.dirty || createContactForm.get('name')?.touched)" >
                      {{error_messages.firstName.message}}
                   </div>
            
              </div>
              <!-- <div class="input-wrap">
                  <input type="text" class="cstm-input" placeholder="Last Name" formControlName="lastName">  
              </div> -->
              <div class="input-wrap">
                  <input type="tel" class="cstm-input" placeholder="Phone" formControlName="phone">
                  <!-- <div class="text-danger"  *ngIf="createContactForm.get('phone')?.hasError(error_messages.phone.type) && (createContactForm.get('phone')?.dirty || createContactForm.get('phone')?.touched)" >
                    {{error_messages.phone.message}}
                 </div> -->
            
              </div>
      
              <button class="btn add-btn" *ngIf="!moreFields" (click)="addMore()">+ ADD MORE</button>
      
              <ng-container *ngIf="moreFields">
      
                  <div class="input-wrap">
                      <input  [matDatepicker]="picker" class="cstm-input" placeholder="Date of Birth" formControlName="birthday">
                      <mat-datepicker-toggle matIconSuffix [for]="picker">
                          <mat-icon matDatepickerToggleIcon><img src="assets/images/calendaricn.svg"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker panelClass="add-contct-date-picker"></mat-datepicker>
                  </div>
      
                  <div class="input-wrap">
                      <input type="text" class="cstm-input" placeholder="Company/School" formControlName="company">  
                  </div>
                  <!-- <div class="text-danger" *ngIf="createContactForm.get('company')?.hasError(error_messages.company.type) && (createContactForm.get('company')?.dirty || createContactForm.get('company')?.touched)" >
                    {{error_messages.company.message}}
                 </div> -->
      
                  <div class="input-wrap">
                      <input type="text" class="cstm-input" placeholder="Title/Position" formControlName="position">  
                  </div>
                  <!-- <div class="text-danger" *ngIf="createContactForm.get('position')?.hasError(error_messages.position.type) && (createContactForm.get('position')?.dirty || createContactForm.get('position')?.touched)" >
                    {{error_messages.position.message}}
                 </div> -->
      
                  <div class="input-wrap">
                      <input type="text" class="cstm-input" placeholder="Website URL" formControlName="websiteUrl">  
                  </div>
      
                  <div class="input-wrap">
                      <input type="text" class="cstm-input" placeholder="Address Street 1" formControlName="address">  
                  </div>
      
                  <div class="input-wrap">
                      <input type="text" class="cstm-input" placeholder="Postal Code" formControlName="postalCode">  
                  </div>
      
                  <div class="input-wrap">
                      <input type="text" class="cstm-input" placeholder="City" formControlName="city">  
                  </div>
      
                  <div class="input-wrap">
                      <input type="text" class="cstm-input" placeholder="Country" formControlName="country">  
                  </div>
      
                  <div class="input-wrap">
                      <textarea class="cstm-textarea" placeholder="Note/Bio" formControlName="note"></textarea>
                  </div>
      
      
                  <div class="photo-sec">
                      <div class="img-wrap">
                          <img class="imb-fluid" [ngClass]="{'fit_img': imgURLCover != null}" [src]="imgURLCover? imgURLCover : 'assets/images/upload_img_contact.svg'">
                      </div>
                      <div class="input-wrap upload-contact photo">
                          <input type="file" class="cstm-input" placeholder="Email" (change)="getContactImages($event)">  
                          <h6>Upload a photo
                              <img src="assets/images/file-upload.svg">
                          </h6>
                      </div>
                  </div>          
              </ng-container>
              <button class="btn connect-btn" type="submit" [disabled]="isButtonDisabled">Connect</button>
              <p class="bottom-p">B in Touch does not sell or share your data</p>
            </form>
        </div>
      </div>
</ng-container>









<!-- Static Add Contact Form -->
<!-- <div mat-dialog-content class="m-0">
  <div class="content_sec">
      <form [formGroup]="createContactForm" (ngSubmit)="onSubmitForm()">
        <div class="input-wrap upload-contact" *ngIf="moreFields">
            <input type="file" class="cstm-input" placeholder="Email" accept="text/x-vcard" (change)="getVcfFile($event)">  
            <h6>Choose from Contacts
                <img src="assets/images/file-upload.svg">
            </h6>
        </div>
          <div class="input-wrap">
              <input type="email" class="cstm-input" placeholder="Email" formControlName="email"> 
              <ng-container *ngFor="let error of error_messages.email">
              <div *ngIf="createContactForm.get('email')?.hasError(error.type) && (createContactForm.get('email')?.dirty || createContactForm.get('email')?.touched)" >
                 {{error.message}}
              </div>
            </ng-container>
          </div>
          <div class="input-wrap">
            <input type="text" class="cstm-input" placeholder="First Name" formControlName="firstName">
            <div *ngIf="createContactForm.get('firstName')?.hasError(error_messages.firstName.type) && (createContactForm.get('firstName')?.dirty || createContactForm.get('firstName')?.touched)" >
                {{error_messages.firstName.message}}
             </div>
      
        </div>
        <div class="input-wrap">
            <input type="text" class="cstm-input" placeholder="Last Name" formControlName="lastName">  
        </div>
        <div class="input-wrap">
            <input type="tel" class="cstm-input" placeholder="Phone Number" formControlName="phoneNumber">
      
        </div>

        <button class="btn add-btn" *ngIf="!moreFields" (click)="addMore()">+ ADD MORE</button>

        <ng-container *ngIf="moreFields">

            <div class="input-wrap">
                <input  [matDatepicker]="picker" class="cstm-input" placeholder="Date of Birth" formControlName="dob">
                <mat-datepicker-toggle matIconSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon><img src="assets/images/calendaricn.svg"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker panelClass="add-contct-date-picker"></mat-datepicker>
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Company/School" formControlName="company">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Title/Position" formControlName="title">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Website URL" formControlName="websiteUrl">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Address Street 1" formControlName="address">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Postal Code" formControlName="postalCode">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="City" formControlName="city">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Country" formControlName="country">  
            </div>

            <div class="input-wrap">
                <textarea class="cstm-textarea" placeholder="Note/Bio" formControlName="note"></textarea>
            </div>


            <div class="photo-sec">
                <div class="img-wrap">
                    <img class="imb-fluid" [ngClass]="{'fit_img': imgURLCover != null}" [src]="imgURLCover? imgURLCover : 'assets/images/upload_img_contact.svg'">
                </div>
                <div class="input-wrap upload-contact photo">
                    <input type="file" class="cstm-input" placeholder="Email" (change)="getContactImages($event)">  
                    <h6>Upload a photo
                        <img src="assets/images/file-upload.svg">
                    </h6>
                </div>
            </div>          
        </ng-container>
        <button class="btn connect-btn" type="submit">Connect</button>
        <p class="bottom-p">B in Touch does not sell or share your data</p>
      </form>
  </div>
</div> -->
