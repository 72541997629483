
<body class="vh-100">
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-xl-6 col-xxl-7">
                    <div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <div class="auth-form">
									<div class="text-center mb-3 logo">
										<img src="../../../assets/images/logo_login.png" alt="">
									</div>
                                    
                                    <form  #forgotpassword="ngForm" (ngSubmit)="forgotpassword.valid && Forgot(forgotpassword)" class="custom-form mt-5" action="reset-password.html">
                                        <p class="forgot_text">Enter your email address. You will receive an email with a link to reset your password</p>
                                        <div class="form-group">
                                            <input  id="username" type="email" class="form-control" name="email" ngModel  #email="ngModel" required/>
                                            <label class="animated-label" for="username">Email address</label>
                                            <div *ngIf="forgotpassword.submitted && email.invalid ">
                                                <div class="alert alert-danger custom_alert" *ngIf="email.errors?.required">
                                                    Email is required
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="text-center mt-5 mb-4">
                                            <button type="submit" class="btn btn-primary btn-block rounded-pill">Submit</button>
                                        </div>
                                        <p class="text-center mt-4"><a routerLink="/" class="text-center mb-4">Back To Login</a></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center term_text mt-4">By signing up, you agree to
                        our <a routerLink="/terms">Terms</a> and <a routerLink="/privacy">Privacy Policy.</a></div>
                </div>
            </div>
        </div>
    </div>


	
	
</body>