<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-center mb-5">
            <h2 class="font-w600 mb-0 mr-auto text_opacity_68 letterspace">Overview / Wifi</h2>

            <div class="searchbar">
                <form id="demo-2">
                    <input class="form-control" type="search" placeholder="Search here">
                </form>
            </div>
        </div>
        <div class="row ">
            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll height540 height-sm-540 custom_scrollbar d-flex flex-column">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title fs21 text_transform_none">Share your Wi-Fi with others</h4>
                                <div class="graphic_icon text-right d-block d-sm-none">
                                    <img class="img-fluid" src="assets/images/wifi_img.png" />
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-8">
                                    <form class="bform text_white" [formGroup]="AppForm">
                                        <div class="form-row mt-0 mt-sm-4">
                                            <div class="form-group col-md-6">
                                                <label class="fs17 font-weight-bold">Wireless SSID</label>
                                                <input type="text" class="form-control" formControlName="title">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label class="fs17 font-weight-bold">Password</label>
                                                <input type="password" class="form-control" formControlName="content">
                                            </div>
                                        </div>
                                        <button (click)="submit()"
                                            class="btn btn-primary btn-block rounded-pill mt-3 w-50 mx-auto">Submit</button>

                                    </form>
                                </div>
                                <div class="col-md-4">
                                    <div class="graphic_icon text-right d-none d-sm-block">
                                        <img class="img-fluid" src="assets/images/wifi_img.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-0 border_half_width py-0">
                            <hr />
                            <p class="text-center fs17 mb-0">Help others connect to your Wi-Fi directly and easily </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="card position-relative">
                    <div class="card_header_pre position-absolute">
                        <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    </div>
                    <div class="preview_main text-center">
                        <img class="img-fluid" src="assets/images/preview_jpg10.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner>