import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsService } from 'src/app/services/event/events.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public event:EventsService,  private router: Router, private service: ApiService, private common: CommonService) { }
  body = {
    phone: '',
    email: ''
  }
  submitted: boolean = false;

  ngOnInit(): void {
  }

  login(loginForm: any) {
    let token: any = localStorage.getItem('deviceToken')
    var fd = new FormData
    fd.append('email', loginForm.form.value.email);
    fd.append('password', loginForm.form.value.password)
    fd.append('device_id', '')
    fd.append('device_name', 'web')
    fd.append('device_token', token)
    loginForm.submitted = true
    this.service.login(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        localStorage.setItem("B-in-Touch", JSON.stringify(res["user"]));
        localStorage.setItem("token", res['user'].token);
        this.common.success(res["msg"]);
        this.event.contactData(res['user'].user_id)
       // this.router.navigate(['/application'])
       this.router.navigate(['/dashboard'])
      } else {
        this.common.error(res["msg"]);
      }
    },
      err => {
      }
    );
  }

  signup() {
    this.router.navigate(['/sign_up'])
  }

  term(){
    window.open('https://b-in-touch.fr/terms-conditions/' , '_blank')
  }

  privacy(){
    window.open('https://b-in-touch.fr/privacy-policy/' , '_blank')
  }
}
