import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-application-sequencer',
  templateUrl: './application-sequencer.component.html',
  styleUrls: ['./application-sequencer.component.scss']
})
export class ApplicationSequencerComponent implements OnInit {
  @Output() emitFormControl = new EventEmitter<FormControl>();
  smartobjects: any;
  applicationDetails: any;
  appShow: boolean = false
  sequenceForm: FormGroup;
  array: any = [];
  arrayShow: any = []
  seq_id: any;
  id: any;
  slug: any;

  constructor(public activeroute: ActivatedRoute, private spinner: NgxSpinnerService, private service: ApiService, private common: CommonService, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if (this.id) {
        this.getDetails()
      }
    })
    this.applicationData();
    this.sequenceForm = this.formBuilder.group({
      timer: ['1'],
      tag_id: [''],
      applicationid: [''],
      title: ['Sequncer']
    })
  }

/* === Application List === */
  applicationData() {
    this.service.getApplicationList('').subscribe((res: any) => {
      if (res.success == 1) {
        this.applicationDetails = res.items
        console.log(this.applicationDetails)
      }
      else {
        this.common.error(res.msg);
      }
    })
  }

  showApp() {
    this.appShow = true
  }

  /* === Create Sequencer app=== */
  submitSequencer() {
    this.spinner.show()
    var data = new FormData
    if (this.seq_id) {
      data.append('id', this.seq_id)
    }
    data.append('reset_timer', this.sequenceForm.value.timer)
    data.append('title', this.sequenceForm.value.title)
    data.append('services', this.array)
    this.service.AppSequencer(data).subscribe((res: any) => {
      this.spinner.hide()
      if (res.success == 1) {
        this.seq_id = res.id
      }
    })
  }

  /* === Get Detail of Sequncer=== */
  getData(id: any) {
    this.applicationDetails.forEach((element: any) => {
      if (element.id == id) {
        let anc = {
          "id": element.id,
          "slug": element.slug
        }
        this.arrayShow.push(anc)

        let data: any = element.id + "|" + element.slug
        this.array.push(data)
      }
    });
    this.appShow = false
    this.sequenceForm.patchValue({
      applicationid: ''
    })
    this.submitSequencer()
  }

  get_sequencer() {
    this.service.getSequencer('').subscribe((res: any) => {
      console.log(res)
      if (res.success == 1) {
        this.sequenceForm.patchValue({
          timer: res.item[0].timer,
          tag_id: res.item[0].tag_id
        })
        let data = res.item[0].services.split(',')
        data.forEach((element: any) => {
          let x = element.split("|")
          let anc = {
            "id": x[0],
            "slug": x[1]
          }
          this.arrayShow.push(anc)
        });
        this.array = data
      }
    })
  }

  /* === delete Sequncer App=== */
  delete(index: any) {
    this.arrayShow.splice(index, 1);
    this.array.splice(index, 1)
    this.submitSequencer()
  }

  getDatatimer() {
    this.submitSequencer()
  }

  getDetails() {
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('slug', this.slug);
    this.service.getServiceDetail(formData).subscribe((response: any) => {
      console.log(response)
      if (response.success == 1) {
        this.seq_id = response.item.id
        this.sequenceForm.patchValue({
          timer: response.item.reset_timer,
          title: response.item.title
        })
        let serviceData = response.item.services.split(',')
        serviceData.forEach((element: any) => {
          let a = element.split('|')
          let anc = {
            "id": a[0],
            "slug": a[1]
          }
          this.arrayShow.push(anc)
          let data: any = element
          this.array.push(data)
        });
      }

    })
  }
}
