<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-center mb-5">
            <h2 class="font-w600 mb-0 mr-auto text_opacity_68 letterspace">Overview / Linktree</h2>

            <div class="searchbar">
                <form id="demo-2">
                    <input class="form-control" type="search" placeholder="Search here">
                </form>
            </div>
        </div>


        <div class="row ">

            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll height540 custom_scrollbar d-flex flex-column">
                        <div class="card-body">
                            <form class="bform text_white" [formGroup]="linkForm">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div
                                            class="profile_img d-flex flex-column align-items-center linkTree_profilePhoto">
                                            <div class="profile_img_container position-relative">
                                                <img [src]="imgURLProfile" />
                                                <input type="file" nbInput fullWidth #file accept='image/*'
                                                    (change)="preview(file.files)" />
                                            </div>
                                            <p class="fs17 font-weight-bold mt-3">Profile Photo</p>
                                        </div>
                                    </div>

                                    <div class="col-md-10 pl-lg-4">
                                        <div class="d-flex justify-content-between">
                                            <h4 class="card-title fs21 text_transform_none mb-4">Create Your Own Mixed
                                                    Sharing & Shortcuts Page </h4>
                                            <div class="graphic_icon text-right d-block d-sm-none">
                                                <img class="img-fluid" src="assets/images/linkTree_img.png" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-7">

                                                <div class="form-row mb-4">
                                                    <div class="form-group col-md-10 profile_cover_img">
                                                        <label class="fs17 font-weight-bold">Cover Photo</label>
                                                        <div class="profile_img_container position-relative">
                                                            <img class="img-fluid" [src]="imgURLCover" alt="" />
                                                            <input type="file" nbInput fullWidth #fileCover
                                                                accept='image/*' (change)="cover(fileCover.files)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Form row end -->


                                            </div>
                                            <div class="col-md-5">
                                                <div class="graphic_icon text-right d-none d-sm-block">
                                                    <img class="img-fluid" src="assets/images/linkTree_img.png" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-1">
                                            <div class="col-md-12">
                                                <!-- <form ></form> -->
                                                <div class="form-row">
                                                    <div class="form-group col-md-4">
                                                        <label class="fs17 font-weight-bold"> Profile Title</label>
                                                        <input type="text" class="form-control" formControlName="title"
                                                            (blur)="makeData()">
                                                    </div>

                                                    <div class="form-group col-md-8">
                                                        <label class="fs17 font-weight-bold"> Bio</label>
                                                        <textarea class="form-control" rows="4" formControlName="bio"
                                                            (blur)="makeData()"></textarea>
                                                    </div>
                                                </div>
                                                <!-- Form row end -->


                                            </div>

                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-12">
                                                <div class="form-row w-100">
                                                    <div class="form-group col-md-8 p-0">

                                                        <div class="form-row w-100">
                                                            <ng-container formArrayName="labelUrl">
                                                                <!-- Add Link Form Div Start -->
                                                                <div class="form-group col-md-12" id="addLinkForm"
                                                                    *ngFor="let lessonForm of getControlsLinks(); let i = index">
                                                                    <div class="mainForm p-3 radius_5 position-relative"
                                                                        [formGroupName]="i">
                                                                        <div class="form_group_inner">
                                                                            <label
                                                                                class="fs17 font-weight-bold">Label</label>
                                                                            <input type="text" formControlName="label"
                                                                                class="form-control" required>
                                                                        </div>

                                                                        <div class="form_group_inner mt-3">
                                                                            <label class="fs17 font-weight-bold">
                                                                                URL</label>
                                                                            <input type="text" formControlName="url"
                                                                                class="form-control" required>
                                                                        </div>
                                                                        <div>
                                                                            <button (click)="addUrl(i,lessonForm)"
                                                                                class="btn btn-info btn-xs mt-4">Save</button>
                                                                        </div>
                                                                        <div class="delte_icon position-absolute pointer"
                                                                            id="deletLinkForm"
                                                                            (click)="delete(lessonForm, i)">
                                                                            <img src="assets/images/delete_outline-24px.svg"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <!-- Add Link Form Div End -->
                                                            <!-- Add Application Div Start -->
                                                            <ng-container formArrayName="labelApp">

                                                                <div class="form-group col-md-12"
                                                                    id="addApplicationForm"
                                                                    *ngFor="let appForm of getControls(); let i=index">
                                                                    <div class="mainForm p-3 radius_5 position-relative"
                                                                        [formGroupName]="i">
                                                                        <div class="form_group_inner">
                                                                            <label class="fs17 font-weight-bold">
                                                                                Label</label>
                                                                            <input type="text" class="form-control"
                                                                                formControlName="text" required>
                                                                        </div>

                                                                        <div class="form_group_inner mt-3">
                                                                            <label class="fs17 font-weight-bold">
                                                                                Application</label>
                                                                            <select class="form-control default-select"
                                                                                formControlName="app" required>
                                                                                <option selected disabled>Select
                                                                                    Application</option>
                                                                                <option [value]="srv.slug"
                                                                                    *ngFor="let srv of services">
                                                                                    {{srv.name}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div>
                                                                            <button (click)="EditApp(i,appForm)"
                                                                                class="btn btn-info btn-xs mt-4">Save</button>
                                                                        </div>
                                                                        <div class="delte_icon position-absolute pointer"
                                                                            id="deletApplForm"
                                                                            (click)="delete(appForm, i)">
                                                                            <img src="assets/images/delete_outline-24px.svg"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>


                                                        <div class="form-row w-100">
                                                            <div class="form-group col-md-6">
                                                                <button id="addLinkBtn" (click)="ShowAddLink()"
                                                                    type="button"
                                                                    class="btn dashbutton-blue fs14 w-100"> Add
                                                                    Link</button>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <button id="addApplicationBtn" (click)="ShowPopup()"
                                                                    type="button"
                                                                    class="btn dashbutton-blue fs14 w-100"> Add
                                                                    Application</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-md-4">
                                                        <ng-container formArrayName="SocialLink">
                                                            <div class="mainForm p-3 radius_5 position-relative mb-4"
                                                                id="socialMediaForm"
                                                                *ngFor="let social of getControlsSocial(); let i=index">
                                                                <ng-container [formGroupName]="i">
                                                                    <div
                                                                        class="form_group_inner icon_container radius_5 p-4 text-center mt-4 d-flex align-items-center justify-content-center">
                                                                        <img class="img-fluid"
                                                                            src="{{social.value.image}}" alt="">
                                                                    </div>

                                                                    <div class="form_group_inner mt-3">
                                                                        <select class="form-control default-select"
                                                                            formControlName="slug"
                                                                            (change)="selectIcon($event,i)" required>
                                                                            <option selected disabled>-Select Social
                                                                                Media Link</option>
                                                                            <option *ngFor="let icon of serviceIcon"
                                                                                [value]="icon.slug">{{icon.name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form_group_inner mt-2">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="{{SocialIconPlaceholder}}"
                                                                            formControlName="url" required>
                                                                    </div>
                                                                    <div class="delte_icon position-absolute pointer"
                                                                        id="deletSMForm">
                                                                    </div>

                                                                    <button (click)="addSocial(i,social)"
                                                                        class="btn btn-info btn-xs mt-4">Save </button>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>

                                                        <button id="addSocialBtn" type="button"
                                                            class="btn dashbutton-blue fs14 w-100"
                                                            (click)="ShowAddSocail()"> Add Social Media Link</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- Color Pickers section start -->
                                <div class="row mt-4">
                                    <div class="col-sm-4">

                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label class="fs17 font-weight-bold">Background Color</label>
                                                <input type="color" class="form-control colorPicker" value="#ffffff"
                                                    formControlName="bgColor" (blur)="makeData()">
                                            </div>
                                        </div>
                                        <!-- Form row end -->

                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label class="fs17 font-weight-bold">Text Color</label>
                                                <input type="color" class="form-control colorPicker" value="#000000"
                                                    formControlName="textColor" (blur)="makeData()">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label class="fs17 font-weight-bold">Button Color</label>
                                                <input type="color" class="form-control colorPicker" value="#5079BF"
                                                    formControlName="buttonColor" (blur)="makeData()">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <!-- Add Application Div Start -->
                                                <label class="fs17 font-weight-bold">Button Style</label>
                                                <div class="mainForm p-3 radius_5 position-relative mb-4">
                                                    <div
                                                        class="form_group_inner icon_container radius_5 p-4 text-center mt-3 d-flex align-items-center justify-content-center">
                                                        <img class="img-fluid selc_temp"
                                                            src="assets/images/button_template.svg" alt="">
                                                    </div>

                                                    <div class="form_group_inner mt-3">
                                                        <select class="form-control default-select"
                                                            formControlName="buttonStyle" (change)="makeData()">
                                                            <option selected disabled>-Select Button Style-</option>
                                                            <option value="rounded">Rounded Buttons</option>
                                                            <option value="rectangle">Rectangular Buttons</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- Add Application Form Div End -->
                                            </div>
                                        </div>
                                        <!-- Form row end -->
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <!-- Add Application Div Start -->
                                                <label class="fs17 font-weight-bold">Social Media Icon Style</label>
                                                <div class="mainForm p-3 radius_5 position-relative mb-4">
                                                    <div
                                                        class="form_group_inner icon_container radius_5 p-4 text-center mt-3 d-flex align-items-center justify-content-center">
                                                        <img class="img-fluid" style="max-width: 60px; box-shadow: 0px 3px 6px rgb(0 0 0 / 60%);" src="assets/images/facebook.png" alt="">
                                                    </div>

                                                    <div class="form_group_inner mt-3">
                                                        <select class="form-control default-select"
                                                            formControlName="styleIcon" (change)="makeData()">
                                                            <option selected disabled>-Select Icon Style-</option>
                                                            <option value="grid">Grid Style</option>
                                                            <option value="list">List Style</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- Add Application Form Div End -->
                                            </div>
                                        </div>
                                        <!-- Form row end -->
                                    </div>
                                </div>
                                <button (click)="makeData()"
                                    class="btn btn-primary btn-block rounded-pill mt-4 w-50 mx-auto">Submit</button>

                            </form>
                        </div>


                    </div>
                    <div class="card-footer border-0 border_half_width py-0">
                        <hr class="border_default" />
                        <p class="text-center fs17 mb-0">Mix & Match all the applications or links that you want to
                            use/share in one landing page of your
                            design, and connect audiences to all of your content with just one page</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="card position-relative">
                    <div class="card_header_pre position-absolute">
                        <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    </div>
                    <div class="preview_main text-center">
                        <img class="img-fluid" src="assets/images/preview_jpg3.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner>