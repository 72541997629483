<body>
    <div class="main-wrap">
        <div class="template_header">
            <img src="assets/images/app/ft-logo.png" alt="logo-image" />
            <a href="#">Get Your Card</a>
        </div>
        <div class="upload-btn">
            <a *ngIf="!linkTree.cover_photo"><img src="assets/images/app/upload-image.png" alt="icon" /></a>
            <a *ngIf="linkTree.cover_photo"><img [src]="coverImg" alt="icon" /></a>
        </div>
        <div class="info-container" [ngStyle]="{'background':pageBack}">
            <div class="profile-image">
                <img *ngIf="!linkTree.photo" src="assets/images/app/profile_img.png" alt="image" />
                <img *ngIf="linkTree.photo" [src]="profileImg" alt="image" />
            </div>
            <div class="content">
                <h4 class="blue-cl" *ngIf="linkTree.title">{{linkTree.title}}</h4>
                <p *ngIf="linkTree.title">{{linkTree.bio}}</p>
                <div class="common-btns">
                    <a [ngStyle]="{'border-radius': buttonStyle,'background':buttonback, 'border-color': buttonback}"
                        *ngFor="let oth of otherLinks">
                        <span *ngIf="oth.image != 'profileImg'">
                            <span *ngIf="oth.url || oth.label"
                                (click)="redirectToUrl(oth.url)">{{oth.label?oth.label:'Button'}}</span>
                        </span>
                    </a>
                </div>
            </div>
            <div class="social-media-grid" *ngIf="linkTree.icon_style == 'grid'">
                <ul>

                    <li *ngFor="let srv of socialLinks">
                        <ng-container *ngIf="srv.image != 'profileImg'">
                            <a (click)=onSocial(srv.url,srv.slug)>
                                <img [ngClass]="{'rectimg' : linkTree.button_style  == 'rectangle'}"
                                    src="{{imageUrl}}{{srv.image}}" alt="icon" />
                            </a>
                        </ng-container>
                    </li>
                </ul>
            </div>
            <div class="social-media" *ngIf="linkTree.icon_style == 'list'">
                <div class="sm-input-wrap" *ngFor="let srv of socialLinks">
                    <ng-container *ngIf="srv.image != 'profileImg'">
                        <img src="assets/images/app/fb-input.png" alt="image" />
                        <img (click)=onSocial(srv.url,srv.slug) class="fb-icon" src="{{round_icons}}{{srv.image}}"
                            alt="image" />
                        <input type="textbox" placeholder="username here..." value="{{srv.url}}" readonly />
                    </ng-container>
                </div>
            </div>
            <p class="footer pl-0 pt-4">All Rights Reserved. ©2021 B-in-Touch.fr</p>
        </div>
    </div>
</body>