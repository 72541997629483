import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../services/common/common.service';



@Component({
  selector: 'app-emenu-card',
  templateUrl: './emenu-card.component.html',
  styleUrls: ['./emenu-card.component.scss']
})
export class EmenuCardComponent implements OnInit {

  imgUrl=environment.slug_images;
  emenuData:any=[];
  allCatData:any=[];
  hasData:boolean = false;
  id: any;
  slug: any;

  constructor(private router:Router,private common:CommonService, public activeroute: ActivatedRoute, private auth: ApiService) {
 /* === Mobile view of emenu Card  === */
    // const data = localStorage.getItem('emenu');
    // if(data){
    //   this.emenuData = JSON.parse(data);
    // }else{
    //   this.router.navigate(['/']);
    // }
    this.activeroute.params.subscribe((params:any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if(this.id){
      this.getDetails()
      }else{
          this.router.navigate(['/']);
        }
    })
   }

  ngOnInit(): void {
    // if(this.emenuData.category.length != -0){
    //   this.allCatData = this.emenuData.category;
    //   this.hasData = true;
    // }else{
    //   this.common.error('No Data Found')
    // }
  }

  onImgError(event:any){
    event.target.src = '../../../assets/images/def_app.png';
  }

  openClick(url:any){
    window.open(url, '_blank')?.focus();
  }

  getDetails() {
    const formData = new FormData();
    formData.append('id',this.id);
    formData.append('slug',this.slug);
    this.auth.getServiceDetail(formData).subscribe((response: any) => {
      if (response.success == 1) {
        console.log(response)
  this.emenuData = response.item
  this.allCatData = this.emenuData.category;
      }
    })
  }

}
