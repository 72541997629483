<body>
    <div class="main-wrap">
        <div class="head d-flex">
            <div class="back-arrow">
                <img src="../../../assets/images/back_arrow.png" alt="icon" />
            </div>
            <h4 class="blue-cl">Food Menu</h4>
        </div>
        <div class="tabs">
            <ul class="tab-list">
                <li *ngFor="let cat of emenuData.category"><a *ngIf="cat.category_name"><i>{{cat.category_name}}</i></a>
                </li>
            </ul>
        </div>
        <div class="container" [style.background]="emenuData.bg_color + ' !important'">
            <!-- <div class="container" [ngStyle]="{'background':emenuData.bg_color}" [style.background]="emenuData.bg_color+ ' !important'"> -->
            <div class="card" id="salad" *ngFor="let cat of emenuData.category">
                <div class="card-title">
                    <h3 [style.color]="emenuData.text_highlight_color + ' !important'" *ngIf="cat.category_name">
                        {{cat.category_name}}</h3>
                </div>
                <div class="card-body" *ngFor="let catitems of cat.items">
                    <div class="menu-img">
                        <img *ngIf="!catitems.photo" src="../../../assets/images/dish_img.png" alt="image" />
                        <img *ngIf="catitems.photo" src="{{imgUrl}}{{catitems.photo}}" alt="image" />
                    </div>
                    <div class="menu-content">
                        <div class="menu-title">
                            <h3 [style.color]="emenuData.text_highlight_color + ' !important'" *ngIf="catitems.name">
                                {{catitems.name}}</h3>
                            <p class="blue-cl" *ngIf="catitems.price">{{catitems.price}} $</p>
                        </div>
                        <p class="" [style.color]="emenuData.text_color + ' !important'" *ngIf="catitems.description">
                            {{catitems.description}}</p>
                        <a *ngIf="catitems.url || catitems.url != 'null'" (click)="openClick(catitems.url)">{{catitems.url}}</a>
                    </div>
                </div>
            </div>
            <p class="footer">All Rights Reserved. ©2021 B-in-Touch.fr</p>
        </div>
    </div>
</body>