<app-sidebar></app-sidebar>
<div class="content-body" id="smobject">
	<div class="container-fluid">
		<div class="form-head d-flex flex-wrap align-items-center mb-5">
			<h2 class="font-w600 mb-0 mr-auto text_opacity_68 letterspace">Overview</h2>

			<div class="mode-sec d-flex align-items-center justify-content-center">
                <p>Dark mode</p>
                <mat-slide-toggle [(ngModel)]="checked"
                (change)="ligthDarkVersion()"></mat-slide-toggle>
                <p>Light Mode</p>
            </div>
			<!-- <div class="searchbar">
				<form id="demo-2">
					<input class="form-control" type="search" placeholder="Search here" [(ngModel)]="search"
						name="search">
				</form>
			</div> -->
		</div>


		<div class="row barcode_sec">
			<div class="col-xl-4">
				<div class="card scanner_sec">
					<div class="card-body">
						<h2 class="font-weight-bold text-black">Smart Object Overview</h2>
						<div class="scanner_main d-flex">
							<div class="icons_main align-self-end text-right" *ngIf="modalData?.qrimage">
								<a>
									<svg width="24" height="24" viewBox="0 0 24 24">
										<g id="download-24px" opacity="0.753">
											<path id="Path_806" data-name="Path 806" d="M0,0H24V24H0Z" fill="none" />
											<path id="Path_807" data-name="Path 807"
												d="M19,9H15V3H9V9H5l7,7Zm-8,2V5h2v6h1.17L12,13.17,9.83,11ZM5,18H19v2H5Z"
												fill="#b6b8c7" />
										</g>
									</svg>
								</a>
								<a>
									<svg width="24" height="24" viewBox="0 0 24 24">
										<g id="share-24px" opacity="0.753">
										  <path id="Path_802" data-name="Path 802" d="M0,0H24V24H0Z" fill="none"></path>
										  <path id="Path_803" data-name="Path 803" d="M18,16.08a2.912,2.912,0,0,0-1.96.77L8.91,12.7A3.274,3.274,0,0,0,9,12a3.274,3.274,0,0,0-.09-.7l7.05-4.11A2.993,2.993,0,1,0,15,5a3.274,3.274,0,0,0,.09.7L8.04,9.81a3,3,0,1,0,0,4.38l7.12,4.16a2.821,2.821,0,0,0-.08.65A2.92,2.92,0,1,0,18,16.08ZM18,4a1,1,0,1,1-1,1A1,1,0,0,1,18,4ZM6,13a1,1,0,1,1,1-1A1,1,0,0,1,6,13Zm12,7.02a1,1,0,1,1,1-1A1,1,0,0,1,18,20.02Z" fill="#b6b8c7"></path>
										</g>
									</svg>
								</a>
							</div>

							<div class="scan_img ml-2">
								<h3 class="text-black font-weight-bold fs-16 mb-2 pb-1">QR Code</h3>
								<div class="img-wrap">
									<img class="img-fluid" *ngIf="modalData?.qrimage" id="qrcode"
									src="{{service.qrurl}}{{modalData?.qrimage}}" alt="">
									<img class="img-fluid" *ngIf="!modalData?.qrimage" src="assets/images/scan_img.png"
										alt="">
								</div>
							</div>
						</div>
						<!-- Scanner div end -->

						<div class="object_name mt-5 d-flex pl-0">
							<div class="icons_main align-self-end text-right">
								<a role="button" (click)="model(modalData)">
									<svg id="create-24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
										<path id="Path_800" data-name="Path 800" d="M0,0H24V24H0Z" fill="none"/>
										<path id="Path_801" data-name="Path 801" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM5.92,19H5v-.92l9.06-9.06.92.92ZM20.71,5.63,18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41Z" fill="#b6b8c7"/>
									</svg>
								</a>
							</div>
							<div class="flex_1 ml-2">
								<h5 class="fs17 font-weight-bold mb-3 pb-1">Smart Object Name</h5>
								<h6 class="fs14 text-white text_opacity_68 mb-0">{{modalData?.tag_name ? modalData?.tag_name : 'B-in-touch'}}</h6>
							</div>
						</div>

						<div class="d-flex mt-5" *ngIf="modalData?.tag_url">
							<div class="icons_main align-self-end pb-1">
								<a [cdkCopyToClipboard]="link" (click)="copyUrl()">
									<svg width="24" height="24" viewBox="0 0 24 24">
										<g id="file_copy-24px" opacity="0.753">
											<path id="Path_868" data-name="Path 868" d="M0,0H24V24H0Z" fill="none" />
											<path id="Path_869" data-name="Path 869"
												d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16ZM15,5H8A2,2,0,0,0,6.01,7L6,21a2,2,0,0,0,1.99,2H19a2.006,2.006,0,0,0,2-2V11ZM8,21V7h6v5h5v9Z"
												fill="#b6b8c7" />
										</g>
									</svg>
								</a>
								<a>
									<svg width="24" height="24" viewBox="0 0 24 24">
										<g id="share-24px" opacity="0.753">
										  <path id="Path_802" data-name="Path 802" d="M0,0H24V24H0Z" fill="none"></path>
										  <path id="Path_803" data-name="Path 803" d="M18,16.08a2.912,2.912,0,0,0-1.96.77L8.91,12.7A3.274,3.274,0,0,0,9,12a3.274,3.274,0,0,0-.09-.7l7.05-4.11A2.993,2.993,0,1,0,15,5a3.274,3.274,0,0,0,.09.7L8.04,9.81a3,3,0,1,0,0,4.38l7.12,4.16a2.821,2.821,0,0,0-.08.65A2.92,2.92,0,1,0,18,16.08ZM18,4a1,1,0,1,1-1,1A1,1,0,0,1,18,4ZM6,13a1,1,0,1,1,1-1A1,1,0,0,1,6,13Zm12,7.02a1,1,0,1,1,1-1A1,1,0,0,1,18,20.02Z" fill="#b6b8c7"></path>
										</g>
									</svg>
								</a>
							</div>

							<!-- <div class="scan_img ml-2 flex_1" >
								<h5 class="fs17 font-weight-bold">Virtual Sharing Link</h5>
								<p class="mb-0 fs14" id="myInput"><a
										class="text-white text_opacity_68">{{modalData?.tag_url}}</a></p>
							</div> -->

							<div class="scan_img ml-2 flex_1" >
								<h5 class="fs17 font-weight-bold">Virtual Sharing Link</h5>
								<p class="mb-0 fs14" id="myInput"><a
										class="text-white text_opacity_68">{{modalData?.tag_url}}</a></p>
							</div>
						</div>

						<div class="d-flex mt-5">
							<div class="icons_main align-self-end pb-1">
								<a>
									<svg width="24" height="24" viewBox="0 0 24 24">
										<g id="file_copy-24px" opacity="0.753">
											<path id="Path_868" data-name="Path 868" d="M0,0H24V24H0Z" fill="none" />
											<path id="Path_869" data-name="Path 869"
												d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16ZM15,5H8A2,2,0,0,0,6.01,7L6,21a2,2,0,0,0,1.99,2H19a2.006,2.006,0,0,0,2-2V11ZM8,21V7h6v5h5v9Z"
												fill="#b6b8c7" />
										</g>
									</svg>
								</a>
							</div>

							<div class="scan_img ml-2 flex_1" >
								<h5 class="fs17 font-weight-bold">Code</h5>
								<p class="mb-0 fs14 text-white text_opacity_68">784312</p>
							</div>
						</div>
						<!--=======Code========-->
					</div>
				</div>

			</div>

			<div class="col-xl-8 smart_object_main">

				<div class="row no-gutters">
					<div class="col-md-3 table_tabs">
						<div class="nav flex-column nav-pills mb-0">
							<a href="#v-pills-home" [ngClass]="{'active' : classAdd === true }" data-toggle="pill"
								class="nav-link active show" (click)="Smartobject()"><i class="mr-2">
									<svg id="home-white-18dp" width="24" height="24" viewBox="0 0 24 24">
										<path id="Path_771" data-name="Path 771" d="M0,0H24V24H0Z" fill="none" />
										<path id="Path_772" data-name="Path 772" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z"
											fill="#fff" />
									</svg>
								</i> Home</a>
							
								<div class="position-relative nav-link-outer d-table" *ngFor="let contacts of ObjectList; let i=index"
									(drop)="drop($event,i,contacts.id)" id="{{contacts.id}}"
									(dragover)="allowDrop($event)">
									<div class="namebtns">
										<button class="btn delete" (click)="deleteContact(contacts?.id)">
											<svg id="delete_outline-24px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
												<path id="Path_808" data-name="Path 808" d="M0,0H20V20H0Z" fill="none"/>
												<path id="Path_809" data-name="Path 809" d="M5.816,16.2a1.646,1.646,0,0,0,1.632,1.65h6.527a1.646,1.646,0,0,0,1.632-1.65V6.3H5.816ZM7.448,7.951h6.527V16.2H7.448Zm6.119-4.126L12.751,3H8.672l-.816.825H5v1.65H16.423V3.825Z" transform="translate(-0.711 -0.427)" fill="#1c254b"/>
											  </svg>
										</button>
										<button class="btn edit" (click)="editGroup(contacts)">
											<svg id="create-24px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
												<path id="Path_800" data-name="Path 800" d="M0,0H20V20H0Z" fill="none"/>
												<path id="Path_801" data-name="Path 801" d="M3,14.875V18H6.125l9.217-9.217L12.217,5.658Zm2.433,1.458H4.667v-.767l7.55-7.55.767.767ZM17.758,5.192l-1.95-1.95a.835.835,0,0,0-1.175,0L13.108,4.767l3.125,3.125,1.525-1.525a.83.83,0,0,0,0-1.175Z" transform="translate(-0.5 -0.5)" fill="#1b254b"/>
											  </svg>
										</button>
									</div>
									<a class="nav-link d-flex justify-content-between"
										[ngClass]="{'active' : contacts.active === true }"
										(click)="getFilterObjectListing(contacts.id)">
										
										{{contacts?.name}}
									</a>
								</div>
						</div>
						<div class="d-flex justify-content-end flex-column align-items-end action_btn mob_view">
							<button class="nav-link btn mr-0" data-toggle="modal"
							data-target="#add_category">
								<svg xmlns="http://www.w3.org/2000/svg" id="add-24px" width="24" height="24"
									viewBox="0 0 24 24">
									<path id="Path_810" data-name="Path 810" d="M0,0H24V24H0Z" fill="none" />
									<path id="Path_811" data-name="Path 811" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z"
										fill="#b6b8c7" />
								</svg>
							</button>
						</div>
						<div class="tab_view">
							<form [formGroup]="catForm">
								<input type="text" *ngIf="inputField == true" formControlName="name"
									(keyup.enter)="submitCat()" class="add_input" placeholder="Add category">
							</form>
							<div class="d-flex justify-content-end flex-column align-items-end action_btn">
								<button class="nav-link btn" (click)="input()">
									<svg xmlns="http://www.w3.org/2000/svg" id="add-24px" width="24" height="24"
										viewBox="0 0 24 24">
										<path id="Path_810" data-name="Path 810" d="M0,0H24V24H0Z" fill="none" />
										<path id="Path_811" data-name="Path 811" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z"
											fill="#b6b8c7" />
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div class="col-md-9">
						<div class="card">
							<div class="tab-content p-0">
								<div id="v-pills-home" class="fade active show">
									<div class="table-responsive custom_table custom_scrollbar">
										<table class="table bg-warning-hover text-left">
											<thead>
												<tr class="text_white">
													<th class="add_sm_object" colspan="2"><a data-toggle="modal"
															data-target="#addsmart_object">
															<svg xmlns="http://www.w3.org/2000/svg" id="add-active-24px"
																width="24" height="24" viewBox="0 0 24 24">
																<path id="Path_810" data-name="Path 810"
																	d="M0,0H24V24H0Z" fill="none" />
																<path id="Path_811" data-name="Path 811"
																	d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z"
																	fill="#8eb8ff" />
															</svg>
															Smart Objects
														</a>
													</th>

													<th class="text_white">Application</th>
													<th class="text_white text-center">Inactive/Active</th>
												</tr>
											</thead>
											<tbody class="fs14">
												<ng-container *ngIf="smartobjects?.length != 0">
												<tr id="drag1" *ngFor="let smart of smartobjects | filter:search"
													draggable="true" (dragstart)="drag($event,smart.tag_id,smart.slug)">
													<td *ngIf="smart.tag_name !=''" (click)="model(smart)" class="">
														<span>
															<svg class="l_menu" xmlns="http://www.w3.org/2000/svg" width="16" height="6" viewBox="0 0 16 6">
																<path id="Path_1038" data-name="Path 1038" d="M20,9H4v2H20ZM4,15H20V13H4Z" transform="translate(-4 -9)" fill="#B4B6C2"/>
															</svg>
															  {{smart.tag_name}}
														</span>
													</td>
													<td *ngIf="smart.tag_name ==''" class="" (click)="model(smart)" >
														B-in-touch
													</td>
													<td class="pr-0">
														<div class="editDelete">
															<ng-container *ngIf="smart.slug != 'emenu'">
																<a class="ed_btn"
																	*ngIf="smart?.data?.title || smart?.data?.slug"
																	(click)="applicationList(smart, smart.data.id)">
																	<!-- <img src="assets/images/create-24px.svg" /> -->
																	<svg xmlns="http://www.w3.org/2000/svg" width="18.002" height="18" viewBox="0 0 18.002 18">
																		<path id="Path_801" data-name="Path 801" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM5.92,19H5v-.92l9.06-9.06.92.92ZM20.71,5.63,18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41Z" transform="translate(-3 -3)" fill="#b6b8c7"/>
																	</svg>
																</a>
																<a class="ed_btn"
																	*ngIf="smart?.data?.title || smart?.data?.slug"
																	(click)="remove(smart)">
																	<!-- <img src="assets/images/delete_outline-24px.svg" /> -->
																	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
																		<path id="Path_809" data-name="Path 809" d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM8,9h8V19H8Zm7.5-5-1-1h-5l-1,1H5V6H19V4Z" transform="translate(-5 -3)" fill="#b6b8c7"/>
																	</svg>
																</a>
																<a class="ed_btn" *ngIf="smart?.data?.length == 0"
																	(click)="applicationList(smart,'')">
																	<!-- <img src="../../../assets/images/add_icon.svg" /> -->
																	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
																		<path id="Path_811" data-name="Path 811" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" transform="translate(-5 -5)" fill="#b6b8c7"/>
																	</svg>
																</a>
															</ng-container>
															<ng-container *ngIf="smart.slug == 'emenu'">
																<a class="ed_btn" *ngIf="smart.data.menu_name"
																	(click)="applicationList(smart, smart.data.id)">
																	<!-- <img src="assets/images/create-24px.svg" /> -->
																	<svg xmlns="http://www.w3.org/2000/svg" width="18.002" height="18" viewBox="0 0 18.002 18">
																		<path id="Path_801" data-name="Path 801" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM5.92,19H5v-.92l9.06-9.06.92.92ZM20.71,5.63,18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41Z" transform="translate(-3 -3)" fill="#b6b8c7"/>
																	</svg>
																</a>
																<a class="ed_btn" *ngIf="smart.data.menu_name"
																	(click)="remove(smart)">
																	<!-- <img src="assets/images/delete_outline-24px.svg" /> -->
																	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
																		<path id="Path_809" data-name="Path 809" d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM8,9h8V19H8Zm7.5-5-1-1h-5l-1,1H5V6H19V4Z" transform="translate(-5 -3)" fill="#b6b8c7"/>
																	</svg>
																</a>
																<a class="ed_btn" *ngIf="smart.data.length == 0"
																	(click)="applicationList(smart,'')">
																	<!-- <img src="../../../assets/images/add_icon.svg" /> -->
																	<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
																		<path id="Path_811" data-name="Path 811" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" transform="translate(-5 -5)" fill="#b6b8c7"/>
																	</svg>
																</a>
															</ng-container>
														</div>
													</td>
													<td>
														<span *ngIf="smart?.data">
															<!-- <ng-container *ngIf="smart.data && smart.data.slug == 'contact'">{{smart.data.first_name}} {{smart.data.middle_name}} {{smart.data.last_name}}</ng-container> -->
															<ng-container *ngIf="smart.data && smart.slug != 'emenu'">
																{{smart.data.title}}</ng-container>
															<ng-container *ngIf="smart.data && smart.slug == 'emenu'">
																{{smart.data.menu_name}}</ng-container>
														</span>
														<span *ngIf="!smart.data">N/A</span>

													</td>
													<td class="text-center">
														<div class="switch">
															<label class="mb-0">
																<input type="checkbox"
																	(change)="change(smart, smart.lock_status)"
																	[checked]="smart.lock">
																<span class="lever"></span>
															</label>
														</div>
													</td>
													<td class="pl-0">
														<svg class="r_menu" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">
															<path id="Path_1040" data-name="Path 1040" d="M11,18a2,2,0,1,1-2-2A2.006,2.006,0,0,1,11,18ZM9,10a2,2,0,1,0,2,2A2.006,2.006,0,0,0,9,10ZM9,4a2,2,0,1,0,2,2A2.006,2.006,0,0,0,9,4Zm6,4a2,2,0,1,0-2-2A2.006,2.006,0,0,0,15,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,15,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,15,16Z" transform="translate(-7 -4)" fill="#B4B6C2"/>
														</svg>
													</td>
												</tr>
												</ng-container>
												
											</tbody>
										</table>
										<div *ngIf="smartobjects && smartobjects?.length==0" class="noData">No Data</div>
									</div>
								</div>
								<div id="v-pills-profile" class="tab-pane fade">
									<p>Culpa dolor voluptate do laboris laboris irure reprehenderit id incididunt duis
										pariatur mollit aute magna pariatur consectetur. Eu veniam duis non ut dolor
										deserunt commodo et minim in quis laboris ipsum
										velit id veniam. Quis ut consectetur adipisicing officia excepteur non sit. Ut
										et elit aliquip labore Lorem enim eu. Ullamco mollit occaecat dolore ipsum id
										officia mollit qui esse anim eiusmod do sint
										minim consectetur qui.
									</p>
									<p>Culpa dolor voluptate do laboris laboris irure reprehenderit id incididunt duis
										pariatur mollit aute magna pariatur consectetur. Eu veniam duis non ut dolor
										deserunt commodo et minim in quis laboris ipsum
										velit id veniam. Quis ut consectetur adipisicing officia excepteur non sit. Ut
										et elit aliquip labore Lorem enim eu. Ullamco mollit occaecat dolore ipsum id
										officia mollit qui esse anim eiusmod do sint
										minim consectetur qui.
									</p>
									<p>Culpa dolor voluptate do laboris laboris irure reprehenderit id incididunt duis
										pariatur mollit aute magna pariatur consectetur. Eu veniam duis non ut dolor
										deserunt commodo et minim in quis laboris ipsum
										velit id veniam.
									</p>


								</div>
								<div id="v-pills-messages" class="tab-pane fade">
									<p>Fugiat id quis dolor culpa eiusmod anim velit excepteur proident dolor aute qui
										magna. Ad proident laboris ullamco esse anim Lorem Lorem veniam quis Lorem irure
										occaecat velit nostrud magna nulla. Velit
										et et proident Lorem do ea tempor officia dolor. Reprehenderit Lorem aliquip
										labore est magna commodo est ea veniam consectetur.</p>

									<p>Fugiat id quis dolor culpa eiusmod anim velit excepteur proident dolor aute qui
										magna. Ad proident laboris ullamco esse anim Lorem Lorem veniam quis Lorem irure
										occaecat velit nostrud magna nulla. Velit
										et et proident Lorem do ea tempor officia dolor. Reprehenderit Lorem aliquip
										labore est magna commodo est ea veniam consectetur.</p>

									<p>Fugiat id quis dolor culpa eiusmod anim velit excepteur proident dolor aute qui
										magna. Ad proident laboris ullamco esse anim Lorem Lorem veniam quis Lorem irure
										occaecat velit nostrud magna nulla. Velit
										et et proident Lorem do ea tempor officia dolor. Reprehenderit Lorem aliquip
										labore est magna commodo est ea veniam consectetur.</p>


								</div>
							</div>
						</div>

					</div>
				</div>

			</div>

			<div class="col-xl-12">

				<div class="card position-relative">
					<div class="card_header_pre position-absolute">
						<h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
					</div>

					<div class="preview_main text-center">
						<img class="img-fluid darkpreview-img" src="assets/images/preview_jpg.jpg" alt="">
						<img class="img-fluid lightpreview-img" src="assets/images/smartobjectpreview-img.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- The Modal -->
<!-- <div class="modal fade" id="addsmart_object">
	<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
		<div class="modal-content">
			<button type="button" class="close" data-dismiss="modal">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
					<path id="Path_823" data-name="Path 823"
						d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
						fill="#b6b8c7" />
				</svg>
			</button>
			<div class="modal-body">
				<div class="scanner_sec">
					<div class="scanner_main d-flex">
						<div class="scanner_div d-flex align-items-end justify-content-center w-100">
							<div class="scanner_icons pb-1">
								<a href=""><img src="assets/images/qr_code_scanner-24px.svg" /></a>
								<a href=""><img src="assets/images/settings_remote-24px.svg" /></a>

							</div>
						</div>
					</div>
					
					<form [formGroup]="EditnameForm">
						<div class="object_name mt-5 pl-0">
							<h5 class="fs17 font-weight-bold pl-3">Smart Object Name</h5>
							<input class="form-control" placeholder="Smart Object Name" formControlName="title"
								(keyup.enter)="submit()" (focusout)="submit()">
						</div>
					</form>

					<div class="d-flex mt-5">
						<div class="icons_main align-self-end pb-1">
							<a>
								<svg width="24" height="24" viewBox="0 0 24 24">
									<g id="share-24px" opacity="0.753">
										<path id="Path_802" data-name="Path 802" d="M0,0H24V24H0Z" fill="none" />
										<path id="Path_803" data-name="Path 803"
											d="M18,16.08a2.912,2.912,0,0,0-1.96.77L8.91,12.7A3.274,3.274,0,0,0,9,12a3.274,3.274,0,0,0-.09-.7l7.05-4.11A2.993,2.993,0,1,0,15,5a3.274,3.274,0,0,0,.09.7L8.04,9.81a3,3,0,1,0,0,4.38l7.12,4.16a2.821,2.821,0,0,0-.08.65A2.92,2.92,0,1,0,18,16.08ZM18,4a1,1,0,1,1-1,1A1,1,0,0,1,18,4ZM6,13a1,1,0,1,1,1-1A1,1,0,0,1,6,13Zm12,7.02a1,1,0,1,1,1-1A1,1,0,0,1,18,20.02Z"
											fill="#b6b8c7" />
									</g>
								</svg>
							</a>
						</div>

						<div class="scan_img ml-3">
							<h5 class="fs17 font-weight-bold">Virtual Link</h5>
							<p class="mb-0 fs14"><a class="text-white text_opacity_68">{{modalData?.tag_url}}</a></p>
						</div>
					</div>
					
				</div>


			</div>
		</div>
	</div>
</div> -->

<div class="modal fade" id="add_category">
	<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
		<div class="modal-content">
			<button type="button" class="close" data-dismiss="modal">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
					<path id="Path_823" data-name="Path 823"
						d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
						fill="#b6b8c7" />
				</svg>
			</button>
			<!-- <button class="btn donebtn">Done</button> -->
			<div class="modal-body"> 
				<form [formGroup]="catForm">
					<h3 class="fs-18 font-weight-bold mt-3 pt-1">Add a group</h3>
					<div class="object_name pl-0">
						<h5 class="font-weight-bold">Group name</h5>
						<input class="form-control pl-4" placeholder="Other Objects" formControlName="name">
					</div>
					<button class="btn add_ob_btn fs17 font-weight-bold" (click)="submitCat()">Add</button>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="editsmart_object">
	<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
		<div class="modal-content">
			<button type="button" class="close" data-dismiss="modal">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
					<path id="Path_823" data-name="Path 823"
						d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
						fill="#b6b8c7" />
				</svg>
			</button>
			<!-- <button class="btn donebtn">Done</button> -->
			<div class="modal-body">
				<form [formGroup]="EditnameForm">
					<h3 class="fs-18 font-weight-bold mt-3 pt-1">Edit Smart Object name</h3>
					<div class="object_name pl-0">
						<h5 class="font-weight-bold">Smart Object Name</h5>
						<input class="form-control pl-4" placeholder="Enter Business Name"  formControlName="title">
					</div>
					<button class="btn add_ob_btn fs17 font-weight-bold" (click)="submit()">Save</button>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="addsmart_object">
	<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
		<div class="modal-content">
			<button type="button" class="close" data-dismiss="modal">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
					<path id="Path_823" data-name="Path 823"
						d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
						fill="#b6b8c7" />
				</svg>
			</button>
			<div class="modal-body">
				<div class="scanner_sec">
					<div class="scanner_main d-flex">
						<div class="scanner_div d-flex align-items-center justify-content-between w-100 flex-column">
							<div class="img-wrapper">
								<img class="drk-logo" src="assets/images/object-logo.png">
								<img class="lght-logo" src="assets/images/object-logo-light.png">
							</div>
							<div class="scanner_icons pb-1">
								<a href=""><svg width="20" height="21" viewBox="0 0 20 21">
									<g id="qr_code_scanner-24px" transform="translate(-0.5 0.396)" opacity="0.576">
									  <rect id="Rectangle_329" data-name="Rectangle 329" width="20" height="21" transform="translate(0.5 -0.396)" fill="none"/>
									  <path id="Path_818" data-name="Path 818" d="M8.439,5.863V8.439H5.863V5.863H8.439M9.726,4.575H4.575V9.726H9.726V4.575ZM8.439,12.731v2.575H5.863V12.731H8.439m1.288-1.288H4.575v5.151H9.726V11.443Zm5.58-5.58V8.439H12.731V5.863h2.575m1.288-1.288H11.443V9.726h5.151V4.575Zm-5.151,6.868h1.288v1.288H11.443Zm1.288,1.288h1.288v1.288H12.731Zm1.288-1.288h1.288v1.288H14.019Zm-2.575,2.575h1.288v1.288H11.443Zm1.288,1.288h1.288v1.288H12.731Zm1.288-1.288h1.288v1.288H14.019Zm1.288-1.288h1.288v1.288H15.306Zm0,2.575h1.288v1.288H15.306ZM19.17,6.292H17.453V3.717H14.877V2H19.17Zm0,12.877V14.877H17.453v2.575H14.877V19.17ZM2,19.17H6.292V17.453H3.717V14.877H2ZM2,2V6.292H3.717V3.717H6.292V2Z" transform="translate(-0.283 -0.283)" fill="#fff"/>
									</g>
								  </svg>
								  </a>
								<a href=""><svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="20.604" viewBox="0 0 20.604 20.604">
									<g id="settings_remote-24px" opacity="0.576">
									  <path id="Path_819" data-name="Path 819" d="M0,0H20.6V20.6H0Z" fill="none"/>
									  <path id="Path_820" data-name="Path 820" d="M14.009,9H8.858A.861.861,0,0,0,8,9.858v10.3a.861.861,0,0,0,.858.858h5.151a.861.861,0,0,0,.858-.858V9.858A.861.861,0,0,0,14.009,9Zm-.858,10.3H9.717V10.717h3.434Z" transform="translate(-1.132 -1.274)" fill="#fff"/>
									  <ellipse id="Ellipse_41" data-name="Ellipse 41" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(9.604 10.604)" fill="#fff"/>
									  <path id="Path_821" data-name="Path 821" d="M6.649,5.194,7.86,6.4a4.311,4.311,0,0,1,6.078,0l1.21-1.21a6.01,6.01,0,0,0-8.5,0ZM10.9,0A9.414,9.414,0,0,0,4.22,2.764l1.21,1.21a7.731,7.731,0,0,1,10.928.009l1.21-1.21A9.35,9.35,0,0,0,10.9,0Z" transform="translate(-0.597)" fill="#fff"/>
									</g>
								  </svg>
								  </a>
							</div>
						</div>
					</div>
					<h3 class="fs-18 font-weight-bold text-center mt-3 pt-1">Click Above to Activate Using
						Mobile App</h3>
					<h4 class="fs-18 font-weight-bold mb-0 pb-1">Or add using code</h4>
					<!-- Scanner div end -->
					<form [formGroup]="createSmartObject">
						<div class="object_name pl-0">
							<h5 class="font-weight-bold">Smart Object Name</h5>
							<input class="form-control pl-4" placeholder="Smart Object Name" value="My business Card" formControlName="tag_name"
								>
						</div>
						<div class="object_name pl-0">
							<h5 class="font-weight-bold">Code</h5>
							<input class="form-control pl-4" placeholder="784312" formControlName="tag_id" value="784312"
								>
						</div>
					</form>

					<button class="btn add_ob_btn fs17 font-weight-bold" (click)="createSmart()">Add Smart Object Manually</button>
					<!--=======Code========-->
				</div>


			</div>
		</div>
	</div>
</div>
<!-- The Modal -->
<div class="modal fade" id="contact_detail">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">

			<div class="card_header_pre d-flex justify-content-between pb-0">
				<h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
				<div class="preview_icons d-flex align-items-center">
					<a href=""><img src="assets/images/download-24px.svg" /></a>
					<a href=""><img src="assets/images/create-24px.svg" /></a>
					<a href=""><img src="assets/images/delete_outline-24px.svg" /></a>
				</div>
			</div>
			<button type="button" class="close" data-dismiss="modal">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
					<path id="Path_823" data-name="Path 823"
						d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
						fill="#b6b8c7" />
				</svg>
			</button>
			<div class="modal-body">
				<div class="user_profile d-flex align-items-center">

					<img src="assets/images/img_user.jpg" alt="" />
					<div class="user_info d-flex flex-column">
						<span class="fs17 text_white font-weight-bold">Remi Dupont</span>
						<span class="fs14 pt-1">ESSEC/Student</span>
					</div>
				</div>

				<ul class="list-unstyled user_info_list pt-3">
					<li>
						<label class="fs14 m-0">Phone Number</label>
						<p class="text_white text_opacity_68">0033616930225</p>
					</li>
					<li>
						<label class="fs14 m-0">Work Number</label>
						<p class="text_white text_opacity_68">0033616930224</p>
					</li>
					<li>
						<label class="fs14 m-0">Website</label>
						<p class="text_white text_opacity_68">www.b-intouch.fr</p>
					</li>
					<li>
						<label class="fs14 m-0">Home Address</label>
						<p class="text_white text_opacity_68">23 Rue Louis Pouey, 92800, Puteaux
							France</p>
					</li>
					<li>
						<label class="fs14 m-0">Birthday</label>
						<p class="text_white text_opacity_68">11/04/1991</p>
					</li>
					<li>
						<label class="fs14 m-0">Email </label>
						<p class="text_white text_opacity_68">aaalfarra1@gmail.com</p>
					</li>
					<li>
						<label class="fs14 m-0">Paypal</label>
						<p class="text_white text_opacity_68">aboud.atassi.paris@gmail.com</p>
					</li>
					<li>
						<label class="fs14 m-0">Home Address</label>
						<p class="text_white text_opacity_68">231 Rue Saint honoré, 75001, Paris
							France</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>





<div class="modal fade" id="edit_category">
	<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
		<div class="modal-content">
			<button type="button" class="close" data-dismiss="modal">
				<svg width="24" height="24" viewBox="0 0 24 24">
					<path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
					<path id="Path_823" data-name="Path 823"
						d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
						fill="#b6b8c7" />
				</svg>
			</button>
			<!-- <button class="btn donebtn">Done</button> -->
			<div class="modal-body"> 
				<form [formGroup]="EditGroup">
					<h3 class="fs-18 font-weight-bold mt-3 pt-1">Edit a group</h3>
					<div class="object_name pl-0">
						<h5 class="font-weight-bold">Group name</h5>
						<input class="form-control pl-4" placeholder="Edit 
						Group"  formControlName="name">
					</div>
					<button class="btn add_ob_btn fs17 font-weight-bold" (click)="editGroupsave()">Edit</button>
				</form>
			</div>
		</div>
	</div>
</div>