<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-start align-items-sm-center align-items-sm-center mb-5">
            <form [formGroup]="Appname" class="w-sm-100">
                <div class="d-flex align-items-start justify-content-start mr-auto ml-sm-0 flex-column flex-sm-row">
                    <h2 class="font-w600 mb-3 mb-sm-0 mr-auto text_opacity_68 letterspace">Overview / Calendar &lt;</h2>
                    <input class="app_name" placeholder="Application Name" formControlName="title">
                </div>
            </form>
        </div>
        <div class="row ">

            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll height540 custom_scrollbar d-flex flex-column">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title fs21 text_transform_none">Give others access to your calendar to take
                                    appointments directly</h4>
                                <div class="graphic_icon text-right d-block d-sm-none">
                                    <img class="img-fluid" src="assets/images/event_img.png" />
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-8">
                                    <form class="bform text_white" [formGroup]="AppForm">
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label class="fs17 font-weight-bold">Add a link to your calendar</label>
                                                <input type="text" class="form-control" formControlName="content">
                                            </div>
                                        </div>
                                        <!-- Form row end -->
                                        <div class="form-row">
                                            <span class="text_white text_opacity_68">Example:</span>

                                            <ul class="m-0 pl-3">
                                                <li><a
                                                        class="text_white text_opacity_68">https://calendly.com/AAAtassi/Incubator</a>
                                                </li>
                                                <li><a
                                                        class="text_white text_opacity_68">https://calendar.google.com/calendar/</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <button (click)="submit()"
                                            class="btn btn-primary btn-block rounded-pill mt-5 w-50 mx-auto mx-sm-0"> Submit</button>
                                    </form>
                                </div>
                                <div class="col-md-4">
                                    <div class="graphic_icon text-right d-none d-sm-block">
                                        <img class="img-fluid" src="assets/images/event_img.png" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer border-0 border_half_width py-0">
                            <hr />
                            <p class="text-center fs17 mb-0">The Easiest way at the end of a meeting to give people
                                access to your calendar directly, so they can take check your Availabilities and
                                directly book an appointment accordingly</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-xl-12">
                <div class="card position-relative">
                    <div class="card_header_pre position-absolute">
                        <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    </div>

                    <div class="preview_main text-center">
                        <img class="img-fluid" src="assets/images/preview_jpg3.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- The Modal -->
<div class="modal fade" id="contact_detail">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="card_header_pre d-flex justify-content-between pb-0">
                <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                <div class="preview_icons d-flex align-items-center">
                    <a href=""><img src="images/download-24px.svg" /></a>
                    <a href=""><img src="images/create-24px.svg" /></a>
                    <a href=""><img src="images/delete_outline-24px.svg" /></a>
                </div>
            </div>
            <button type="button" class="close" data-dismiss="modal">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_822" data-name="Path 822" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_823" data-name="Path 823"
                        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                        fill="#b6b8c7" />
                </svg>
            </button>
            <div class="modal-body">
                <div class="user_profile d-flex align-items-center">

                    <img src="images/img_user.jpg" alt="" />
                    <div class="user_info d-flex flex-column">
                        <span class="fs17 text_white font-weight-bold">Remi Dupont</span>
                        <span class="fs14 pt-1">ESSEC/Student</span>
                    </div>
                </div>

                <ul class="list-unstyled user_info_list pt-3">
                    <li>
                        <label class="fs14 m-0">Phone Number</label>
                        <p class="text_white text_opacity_68">0033616930225</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Work Number</label>
                        <p class="text_white text_opacity_68">0033616930224</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Website</label>
                        <p class="text_white text_opacity_68">www.b-intouch.fr</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Home Address</label>
                        <p class="text_white text_opacity_68">23 Rue Louis Pouey, 92800, Puteaux
                            France</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Birthday</label>
                        <p class="text_white text_opacity_68">11/04/1991</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Email </label>
                        <p class="text_white text_opacity_68">aaalfarra1@gmail.com</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Paypal</label>
                        <p class="text_white text_opacity_68">aboud.atassi.paris@gmail.com</p>
                    </li>
                    <li>
                        <label class="fs14 m-0">Home Address</label>
                        <p class="text_white text_opacity_68">231 Rue Saint honoré, 75001, Paris
                            France</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner>