import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ApiService } from '../../services/api-service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2'

import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { NewContactComponent } from '../new-contact/new-contact.component';
import * as AOS from 'aos';

import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';


@Component({
  selector: 'app-profile-redirect',
  templateUrl: './profile-redirect.component.html',
  styleUrls: ['./profile-redirect.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],

})
export class ProfileRedirectComponent implements OnInit {
  contactName = "";
  userData: any = [];
  fullName = "";
  position = "";
  city = "";
  company = "";
  bio = "";
  country = "";
  services: any = [];
  otherServices: any = [];
  imgUrl = environment.service_icons;
  round_icons = environment.round_icons
  usrImg = environment.imageUrl;
  baseUrl = environment.baseURL;
  lost_found = environment.lost_found;
  slug_image = environment.slug_images;
  qr_image = environment.qr_images;
  round_icn = environment.round_icons;
  photo = "";
  contactId: any;
  buttonStyle: any;
  buttonback: any;
  headingColor: any;
  descpColor: any;
  backColor: any;
  appIconStyle: any;
  isActive = false;
  qrimg: any;
  appicon: any;
  encodedId:any
  UppbackColor: any = '';
  private modalRef: any;
  @ViewChild('mymodal', { static: false }) qrModal: ElementRef;
  socialIcon: any;
  Position_textColor: string;
  campanyName_textColor: string;
  bio_textColor: string;
  location_textColor: string; 
  
  location: Location;



  constructor(private auth: ApiService, private router: Router,
    private route: ActivatedRoute, private common: CommonService, private modalService: NgbModal, private _bottomSheet: MatBottomSheet,location: Location) {
    this.common.showSpinner();
    this.location = location;


    this.route.params.subscribe((res: any) => {
      (!res)
        ? this.router.navigate(['/'])
        : this.getUserData(res)
        console.log(res)
    })
  }

  ngOnInit(): void {
    AOS.init();
  }

 /* === Detail of profile page === */
  getUserData(res: any) {
    this.contactName = res.name;

    // multiple accounts

    if(this.location.path().includes('/share')){
      console.log('yes it contains')
      var a = this.location.path().split('/')
      var profile_id =  a[3]
      console.log(profile_id)
      var data:any = { 'username': res.name, type: 'primary', profile_id:profile_id };

    }

    else{
      console.log('no it doesnot contains')
      var data:any = { 'username': res.name, type: 'primary' };

    }

    // const data = { 'username': res.name, type: 'primary' };
    this.auth.getProfileData(data).subscribe((response: any) => {
      if (response.success == 0) {
        Swal.fire('Oops...', response.msg, 'error');
        this.router.navigate(['/']);
      } else if (response.success == 1) {
        this.isActive = response.user.active == '1' ? true : false;
        this.userData = response.user;
        this.contactId = this.userData.contact_id;
        (response?.style) ? this.applyStyles(response.style) : '';
        if (response.services.length != 0) {
          (response.services || []).map((e: any) => {
            if (e.type != 'quick_access' && e.type != 'quickfiles') {
              this.services.push(e);
              console.log(this.services)
            } else {
              this.otherServices.push(e);
            }
          });
        }
        if (response.user.instant == 1) {
          this.router.navigate(['/profile', this.contactName])
        } else {
          this.common.hideSpinner()
          this.position = this.userData.position;
          this.city = `${this.userData.city} ,${this.userData.country}`;
          this.company = this.userData.company;
          this.bio = this.userData.note;
          this.photo = this.userData.photo?this.usrImg + this.userData.photo:'assets/images/user-image@3x.png';
          this.fullName = `${this.userData?.first_name ? this.userData.first_name : ''} ${this.userData?.last_name ? this.userData?.last_name : ''}`;
        }
      }
    })
    AOS.init();
  }

 /* === design of profile page === */
  applyStyles(style: any) {
    this.buttonStyle = (style.btn_style == 'round') ? '40px' : '5px';
    this.buttonback = (style.btn_color == '') ? '#fff' : style.btn_color;
  //  this.headingColor = (style.text_color == '') ? '#0071BC' : style.text_color;
    this.descpColor = (style.text_color2 == '') ? '#9E9E9E' : style.text_color2;
    // console.log(style.keypad_color,"===========================");return
    this.backColor = (style.keypad_color == '') ? '#fff' : style.keypad_color;
    //this.UppbackColor = (style.bg_color == '') ? '#fff' : style.bg_color;
    this.appIconStyle = (style.icon_style == 'rectangular') ? '0px' : '40px';
    this.appicon = style.icon_style;
    console.log(this.appicon)
    this.UppbackColor = style.bg_color 
    // self.upperView.backgroundColor = UIColor(hex: self.contactDetail?.style?.bg_color ?? "")
    // let bg_colour = self.contactDetail?.style?.bg_color ?? ""
    switch(this.UppbackColor) {
    case "#EFF8FF":

         this.headingColor = '#0071BC'
        this.Position_textColor = '#000000'
        this.campanyName_textColor = '#000000'
        this.bio_textColor =  '#9E9E9E'
        this.location_textColor =  '#9E9E9E'
        break
    case "#0D4E81":
        this.headingColor = '#1C8ED9'
        this.Position_textColor = '#FFFFFF'
        this.campanyName_textColor = '#FFFFFF'
        this.bio_textColor  =  '#B5B5B5'
        this.location_textColor =  '#B5B5B5'
        break
    case "#212424":
       this.headingColor = 'FFFFFF'//'#1C8ED9'
        this.Position_textColor = '#FFFFFF'
        this.campanyName_textColor ='FFFFFF'
        this.bio_textColor =  '#B5B5B5'
        this.location_textColor = '#B5B5B5'
        break
    case "#671414":
         this.headingColor = '#FFFFFF'
        this.Position_textColor = '#DBAEAE'
        this.campanyName_textColor = '#DBAEAE'
        this.bio_textColor  =  '#DB8383'
        this.location_textColor =  '#DB8383'
        break
    case "#F2F2F2":
         this.headingColor = '#19689B'
        this.Position_textColor = '#000000'
        this.campanyName_textColor= '#000000'
        this.bio_textColor  =  '#8E8E8E'
        this.location_textColor =  '#8E8E8E'
        break
    case "#FFEAF5":
         this.headingColor = '#C35F8D'
        this.Position_textColor= '#883357'
        this.campanyName_textColor = '#883357'
        this.bio_textColor  =  '#A0798A'
        this.location_textColor =  '#A0798A'
        break
    case  "#FFD1BC":
         this.headingColor = '#C47754'
        this.Position_textColor = 'E28F6A'
        this.campanyName_textColor = '#E28F6A'
        this.bio_textColor  =  '#B97E63'
        this.location_textColor =  '#B97E63'
        break
    case "#909090":
        this.headingColor = '#393B3A'
        this.Position_textColor = '#505050'
        this.campanyName_textColor = '#505050'
        this.bio_textColor  =  '#EFEFEF'
        this.location_textColor =  '#EFEFEF'
        break
    case "#E2B02A":
         this.headingColor = '#71560A'
        this.Position_textColor = '#715811'
        this.campanyName_textColor = '#715811'
        this.bio_textColor  = '#FFFFFF'
        this.location_textColor =  '#FFFFFF'
        break
    case "#04412D":
      this.headingColor = '#A7D6BB'
      this.Position_textColor = '#FFFFFF'
      this.campanyName_textColor = '#FFFFFF'
      this.bio_textColor =  '#B5B5B5'
      this.location_textColor=  '#B5B5B5'
      break
    default:
        this.UppbackColor = '#EFF8FF'
         this.headingColor = '#0071BC'
        this.Position_textColor= '#000000'
        this.campanyName_textColor ='#000000'
        this.bio_textColor  =  '#9E9E9E'
        this.location_textColor =  '#9E9E9E'
    }
    console.log(this.headingColor,this.Position_textColor,  this.campanyName_textColor )
  }

   /* === Click of Social Icons  === */
  onSocial(url: any, slug: string, title:string) {
    if (slug == 'wifi' || slug == 'pcr' || slug == 'vaccine') {
      let { qrimage } = this.services.find((e: any) => e.slug == slug);
      this.qrimg = this.qr_image + qrimage;
      this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      return;
    }

    if (slug == 'medical') {
      let { file_name } = this.services.find((e: any) => e.slug == slug);
      this.qrimg = this.lost_found + file_name;
      this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      return;
    } 
    if (slug == 'messages') {

    }
    if (slug == 'notes') {
      let data = this.services.find((e: any) => e.slug == slug);
      const { url: weburl, bundlename }: any = this.common.redirectToUrl(data, slug, title);

      this.deeplink(weburl, bundlename);
    }
    if (slug == 'event') {
      let data = this.services.find((e: any) => e.slug == slug);
      const encodedId = btoa(data.id);
      const urlData: any = this.common.redirectToUrl(encodedId, slug, '');
      if (urlData) {
        this.common.success('File downloaded successfully');
        window.location.href = urlData.url;
      } else {
        Swal.fire('Oops...', 'Somthing went wrong !', 'error');
        this.common.hideSpinner();
      }
      return;
    }
    this.common.showSpinner();
    const slugData = this.services.find((e: any) => { return e.slug === slug });
    let data = { 'profile_user_id': slugData.user_id, 'slug': slugData.slug, 'service_id': slugData.id };
    const formData = new FormData();
    formData.append('profile_user_id', slugData.user_id);
    formData.append('slug', slugData.slug);
    formData.append('service_id', slugData.id);
    this.auth.saveUserServicesClicks(formData).subscribe((response: any) => {
    //  console.log(response)
      if (response.success == 1) {
        this.common.hideSpinner();
        console.log(url, slug)

        const { url: weburl, bundlename }: any = this.common.redirectToUrl(url, slug, title);

        this.deeplink(weburl, bundlename);

      }
    }, (e) => {
      this.common.hideSpinner();
    })
  }


  redirectToUrl(url:any) {
    // if(url.slug == 'file1' || url.slug == 'file2' || url.slug == 'file3'){
      if(url.slug == 'file'){

      let urll = environment.slug_images + url.file_name//`https://saurabh.parastechnologies.in/BInTouch/public/uploads/user_files/${url.file_name}`
      window.open(urll, '_blank')?.focus();

    }
    // else if(url.slug  == 'bapp1' || url.slug == 'bapp2' || url.slug == 'bapp3' || url.slug == 'bapp4' || url.slug  == 'bapp5'){
    //   let split =url.bapp.split("|")
    //   const { url: weburl, bundlename }: any = this.common.redirectToUrl(url.content, split[0], '');

    //   this.deeplink(weburl, bundlename);

    // }
    else if(url.image == "bapp.png"){
      let split =url.bapp.split("|")
      if(split[0] == "lost_found" || split[0]  == "emenu" || split[0]  == "linktree" || split[0]  == "emergency" || split[0]  == "contact" || split[0]  == "event" || split[0]  == "calendar" || split[0]  == "notes"){
        window.open(url.content, '_blank')?.focus();
      }else{
        const { url: weburl, bundlename }: any = this.common.redirectToUrl(url.content, split[0], '');
        this.deeplink(weburl, bundlename);
      }
    }
    else {
       window.open(url.content, '_blank')?.focus();
    }
  }

   /* === Download of Contact card  === */
  addContact() {
localStorage.setItem('contact', this.contactId)

    this._bottomSheet.open(NewContactComponent, {
      panelClass: 'bottom-sheet-custm',
      disableClose: true,
      backdropClass: 'custm-sheet',
      // data: {
      //   id: this.contactId ,
    
      // }
    });


    // const token = this.common.getToken();
    // if (token) {
    //   let data = { 'contact_id': this.contactId };
    //   this.auth.saveSharedContact(data).subscribe((response: any) => {
    //     if (response.success == 1) {
    //       this.common.success(response.msg);
    //     } else {
    //      // this.common.error(response.msg);
    //     }
    //   }, (e) => {
    //    // this.common.error(e.error.msg);
    //   })
    // }

    // if (this.contactId) {
    //   const encodedStringBtoA = btoa(this.contactId);
    //  window.open(`${this.baseUrl}/export_contact/?contact_id=${encodedStringBtoA}`, '_blank');
    // }

  }

  moveToPage() {
    console.log('clicked');
    const encodedName = btoa(this.contactName);
    this.router.navigate(['/profile-contact-form', encodedName, this.userData.user_id,''])
  }

  onImgError(event: any) {
    event.target.src = '../../../assets/images/dummy_user.png';
  }


  saveOnLinkClick() {
    const slugData = this.services.find((e: any) => { return e.slug === this.userData.instant_slug });
    let data = { 'profile_user_id': this.userData.user_id, 'slug': this.userData.instant_slug, 'service_id': slugData.id };
    this.auth.saveUserServicesClicks(data).subscribe((response: any) => {
      if (response.success == 1) {

      }
    })
  }

  getSocialLinks() {
    const formData = new FormData();
    this.auth.getServiceIcons(formData).subscribe((response: any) => {
    })

  }
  deeplink(url: string, bundlename: string) {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check
      if (isIphone == false) {
      let app = {
        launchApp: function () {
          setTimeout(function () {
           window.open(url, '_blank')?.focus();
          // window.location.href = url
          }, 25);
         // window.location.href = bundlename
         window.open(bundlename,'_blank')?.focus();

        },
        openWebApp: function () {
       window.open(url, '_blank')?.focus();
     // window.location.href = url
        }
      };
      app.launchApp();
    } else if (isAndroid == false) {
      let app = {
        launchApp: function () {
         window.open(bundlename,'_blank')?.focus();
        // window.location.href = bundlename
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
        window.open(url, '_blank')?.focus();
        // window.location.href = url
        }
      };
      app.launchApp();
    } 
    else {
   window.open(url, '_blank')?.focus();
  // window.location.href = url
    }
  }
  


  openWebsiteUrl() {
       window.open('https://b-in-touch.fr', '_blank')?.focus();

  }


  applyCss(bg_colour:any){
    
  }

}
