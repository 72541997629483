<body>
    <div class="main-wrap">
        <div class="template_header">
            <p>Notes</p>
        </div>
        <div class="notes_content_sec">
            <p>{{notesData?.content}}</p>
        </div>
        <p class="footer pl-0">All Rights Reserved. ©2021 B-in-Touch.fr</p>
    </div>
</body>

