<body style="background-color: white; height: 100vh;" [ngClass]="profileData?.style?.header_ads == '0' ? 'pt-0':''">
    <div class="main-wrap">
        <div class="template_header" *ngIf="profileData?.style?.header_ads == '1'">
            <img src="assets/images/ft-logo.png" alt="logo-image" />
            <a (click)="openWebsiteUrl()">Get Your Card</a>
        </div>
        
        <div class="banner-img-wrap"> 
            <img  [src]="cover_photo">
        </div>
        <div class="content_wrap" [ngClass]="profileData?.style?.profile_photo == '0' ? 'without-image':''">

            <div class="profile_img_wrap" *ngIf="profileData?.style?.profile_photo == '1'"> 
                <div class="img_main" *ngIf="photo">
                    <img [src]="photo" alt="profile-img" />
                </div>  
                <div class="logo_wrap"  *ngIf="userData.logo">
                    <!-- <img src="assets/images/logo-icon.png"> -->
                    <img [src]="usrImg +userData.logo">

                </div>
            </div>

            <div class="logo-right d-flex align-items-center justify-content-center" *ngIf="profileData?.style?.profile_photo == '0' && userData.logo">
                <!-- <img src="assets/images/logo-text.png"> -->
                <img [src]="usrImg +userData.logo">

            </div>
            
            <div class="user_info" [ngClass]="{'text-left' : profileData?.style?.profile_photo == '0'}">
                <h2 >{{fullName}}</h2>
                <h3 [ngClass]="{'callAdd' : !userData?.city || !userData?.country}"  *ngIf="userData?.position || userData?.company">{{userData?.position}}{{userData?.position?',':''}} {{userData?.company}}</h3>
                <!-- <p  *ngIf="userData?.company" > {{company}}</p> -->
                <p [ngClass]="{'bioClass' : !userData?.note}" *ngIf="userData?.city || userData?.country"> {{userData.city}}{{userData.city?',':''}}{{userData.country}}</p>
            </div>
            <p class="discription"  *ngIf="userData?.note" >{{bio}}</p>
            <div class="btns-row d-flex align-items-center" *ngIf="isActive">
                <button class="blue-btn btn" (click)="exchangeContact(1)" >Exchange Contact</button>
                <!-- <button class="blue-btn btn" (click)="addContact()" >Add To Contacts</button>
                <button class="blue-btn btn" (click)="moveToPage()">Share Your Contact</button> -->
            </div>
            <div class="btns-sec" *ngIf="otherServices?.length != 0" >
                <ng-container *ngFor="let oth of otherServices">
                    <ng-container *ngIf="oth.image != 'contact.png'">
                        <button  class="btn" [ngClass]="{'custom-style' : profileData?.style?.business_btn_color == '#FFFFFF' || profileData?.style?.business_btn_color == '','blue-btn' : profileData?.style?.business_btn_color == '#101333'}" (click)="redirectToUrl(oth)">{{oth.title}}</button>
                <!-- <button  class="blue-btn btn">CV</button>
                <button  class="blue-btn btn">Filei7</button>
                <button  class="blue-btn btn">File</button>
                <button  class="blue-btn btn">Title</button> -->
                </ng-container>
                </ng-container>
                <!-- <button class="blue-btn btn">Website</button>
                <button class="blue-btn btn">CV</button> -->
            </div>
            <ng-container *ngIf="profileData?.style?.contact_me == '1'">
            <div class="contact-sec" *ngIf=" isActive && (userData?.phone || userData?.work_phone || userData?.email || userData?.website)"> 
                <h5 class="section-heading">Contact Me</h5>
                <div class="content-content d-flex align-items-center justify-content-center" >
                    <div class="inner-sec">
                        <a *ngIf="userData.phone" href="tel:{{userData.phone}}">
                            <span class="icon-wrap"><img src="assets/images/profile2/phone.png"></span>
                            {{userData.phone}} 
                        </a>
                        <a *ngIf="userData.work_phone" href="tel:{{userData.work_phone}}">
                            <span class="icon-wrap"><img src="assets/images/profile2/job.png"></span>
                            {{userData.work_phone}} 
                        </a>
                        <a *ngIf="userData.email" href="mailto:{{userData.email}}">
                            <span class="icon-wrap"><img src="assets/images/profile2/email.png"></span>
                            {{userData.email}} 
                        </a>
                        <a *ngIf="userData.website" href="{{userData.website}}"target="_blank">
                            <span class="icon-wrap"><img src="assets/images/profile2/web.png"></span>
                            {{userData.website}} 
                        </a>
                    </div>
                </div>
                <!-- <div class="content-content d-flex align-items-center justify-content-center" >
                    <div class="inner-sec">
                        <a *ngIf="userData.phone">
                            <span class="icon-wrap"><img src="assets/images/profile2/phone.png"></span>
                            {{userData.phone}} 
                        </a>
                        <a *ngIf="userData.work_phone">
                            <span class="icon-wrap"><img src="assets/images/profile2/job.png"></span>
                            {{userData.work_phone}} 
                        </a>
                        <a *ngIf="userData.email">
                            <span class="icon-wrap"><img src="assets/images/profile2/email.png"></span>
                            {{userData.email}} 
                        </a>
                        <a *ngIf="userData.website">
                            <span class="icon-wrap"><img src="assets/images/profile2/web.png"></span>
                            {{userData.website}} 
                        </a>
                    </div>
                </div> -->
            </div>
            <div class="divider"></div>

        </ng-container>
            <ng-container *ngIf="profileData?.style?.location_map == '1'">
            <!-- <div class="divider"></div> -->
            <div class="locate-sec" *ngIf="userData.address || userData.address_line2 || userData.city || userData.postcode || userData.country">
                <h5 class="section-heading">Locate Me</h5>
                <!-- <div class="map-outer">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d27417.138486241245!2d76.742656!3d30.798643199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1645772356986!5m2!1sen!2sin" height="320" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div> -->
                <div #map style="width:100%; height:250px"></div>
                <button class="blue-btn btn directionbtn" (click)="onSocial(address, 'maps', '')">GET DIRECTIONS</button>
            </div>
            <div class="divider"></div>

        </ng-container>
            <div class="full-content">
                <ng-container *ngFor="let items of array">
                    <ng-container *ngIf="items.length != 0 && items.item !== 'contact'">
                        <!-- <div class="divider"></div> -->
                        <div class="cmn-social">
                            <!-- <ng-container   -->
                            <h5 class="section-heading mb-22">{{items.title}}</h5> 
                            <ng-container  *ngFor="let hightlight of items.highlight" >
                            <div class="highlighted_icons">
    
                                <!-- <div class="icn_item high_lighted" [ngClass]="appicon == 'rectangular' ? 'rect-item' : '' "   (click)=onSocial(srv.content,srv.slug,srv.title)> -->
                                    <div class="icn_item high_lighted" [ngClass]="{ 'rect-item' : appicon == 'rectangular', 'cmn-video' : hightlight.slug=='embedvideo'}"   (click)=onSocial(hightlight.content,hightlight.slug,hightlight.title)>
                                    <!-- <span *ngIf="srv.slug=='embedvideo'"><iframe class="video" width="100%" height="100%" [src]="checkCondition(srv.content)?trustVimeoVideoUrl:trustYouTubeVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></span> -->
    
                                    <img class="rect-img" src="{{imgUrl}}{{hightlight?.image}}" *ngIf="hightlight.slug != 'embedvideo'">
                                    <span *ngIf="hightlight.slug=='embedvideo'"><iframe class="video" width="100%" height="100%" [src]="checkCondition(hightlight.content)?hightlight?.trustVimeoVideoUrl:hightlight?.trustYouTubeVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></span>
    
    
    
                                    <div>
                                        <h6 style="color: #151515dd !important;" *ngIf="hightlight.slug == 'messages'">Message</h6>
                                        <h6 style="color: #151515dd !important;"  *ngIf="hightlight.slug == 'notes'">Notes</h6>
                                        <h6 style="color: #151515dd !important;"  *ngIf="hightlight.slug == 'wifi'">wifi</h6>
                                        <h6 style="color: #151515dd !important;"  *ngIf="hightlight.slug == 'pcr'">Test</h6>
                                        <h6 style="color: #151515dd !important;"  *ngIf="hightlight.slug == 'event'">Event</h6>
                                        <h6 style="color: #151515dd !important;" *ngIf="hightlight.slug != 'notes' && hightlight.slug != 'messages' && hightlight.slug != 'pcr' && hightlight.slug != 'wifi' && hightlight.slug != 'event'">{{hightlight.title}}</h6>
                                        <p>{{hightlight.description}}</p>
                                    </div>
                                </div>
                                <!-- <div class="icn_item high_lighted">
                                    <img src="assets/images/app/twitter-dark.png">
                                    <div>
                                        <h6 style="color: #151515dd !important;">Twitter</h6>
                                        <p>Check out my Twitter profile</p>
                                    </div>
                                </div> -->
                            </div>
                            </ng-container>
                            <ng-container>
                            <!-- <div class="icons-row d-flex align-items-strech"> -->
                                <div class="icons-row align-items-strech">
                                    <!-- <div class="icn_item" [ngStyle]="{'background': backColor}" *ngFor="let srv of items.app"  (click)=onSocial(srv.content,srv.slug,srv.title)> -->
                                        <div class="icn_item" [ngClass]="appicon == 'rectangular' ? 'rect-item' : '' "  *ngFor="let srv of items.normal"  (click)=onSocial(srv.content,srv.slug,srv.title)>
                                        <ng-container *ngIf="srv.image != 'contact.png'">
                                        <img class="rect-img" *ngIf="srv.slug != 'customlink'"  src="{{imgUrl}}{{srv?.image}}" >
                                        <img class="rect-img" [ngClass]="{'cstm_icn': srv.custom_icon != ''}" *ngIf="srv.slug == 'customlink'" src="{{srv?.custom_icon? lost_found : imgUrl}}{{srv?.custom_icon? srv.custom_icon : srv?.image }}" >
                                        <p *ngIf="srv.slug == 'messages'">Message</p>
                                        <p *ngIf="srv.slug == 'notes'">Notes</p>
                                        <!-- <p *ngIf="srv.slug == 'notes'">Notes</p> -->
                                        <p *ngIf="srv.slug == 'wifi'">wifi</p>
                                        <p *ngIf="srv.slug == 'pcr'">Test</p>
                                        <p *ngIf="srv.slug == 'event'">Event</p>
                                        <p *ngIf="srv.slug != 'notes' && srv.slug != 'messages' && srv.slug != 'pcr' && srv.slug != 'wifi' && srv.slug != 'event'">{{srv.title}}</p>
        
                                    </ng-container>
                                    </div>
                                    <!-- <div class="icn_item">
                                        <img src="assets/images/app/insta-dark.png">
                                        <p>Instagram</p>
                                    </div>
                                    <div class="icn_item">
                                        <img src="assets/images/app/fb-dark.png">
                                        <p>Facebook</p>
                                    </div>
                                    <div class="icn_item">
                                        <img src="assets/images/app/snapchat-dark.png">
                                        <p>Snapchat</p>
                                    </div>
                                    <div class="icn_item">
                                        <img src="assets/images/app/youtube-dark.png">
                                        <p>Youtube</p>
                                    </div>
                                    <div class="icn_item">
                                        <img src="assets/images/app/twitter-dark.png">
                                        <p>Twitter</p>
                                    </div> -->
                                </div>
                            </ng-container>
                            <!-- </ng-container> -->
                        </div>
                        <div class="divider"></div>
    
                    </ng-container>
                </ng-container>
           
            </div>
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Communication</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/app/hi-dark.png">
                        <p>Viber</p>
                    </div>
                    <div class="icn_item">
                        <img src="assets/images/app/skype-dark.png">
                        <p>Skype</p>
                    </div>
                    <div class="icn_item">
                        <img src="assets/images/app/whatsapp-dark.png">
                        <p>WhatsApp</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Payments</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/profile2/paypal.png">
                        <p>Paypal</p>
                    </div>
                    <div class="icn_item">
                        <img src="assets/images/profile2/lydia.png">
                        <p>Lydia</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Media</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/profile2/Notes.png">
                        <p>Notes</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Directions</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/profile2/waze.png">
                        <p>Waze</p>
                    </div>
                    <div class="icn_item">
                        <img src="assets/images/profile2/Uber.png">
                        <p>Uber</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Event</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/profile2/Event.png">
                        <p>Event</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Wifi</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/profile2/wifi.png">
                        <p>Wifi</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Other</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/profile2/Trip Advisor.png">
                        <p>Trip Advisor</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider"></div>
            <div class="cmn-social">
                <h5 class="section-heading mb-22">Health Records</h5> 
                <div class="icons-row d-flex align-items-center">
                    <div class="icn_item">
                        <img src="assets/images/profile2/Vaccine.png">
                        <p>Vaccine</p>
                    </div>
                    <div class="icn_item">
                        <img src="assets/images/profile2/PCR Test.png">
                        <p>Test</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="divider mb-15"></div> -->
            <p class="copyright">All Rights Reserved. ©2021 B-in-Touch.fr</p>
        </div>
        
        
    
        
        <div class="template_footer">
            <p>B-In-Touch</p>
        </div>
    </div>
</body>

<ng-template #mymodal let-modal>
    <div id="member_status" class="login_me" role="dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <button type="button" style="outline: none;" class="close close_modal" (click)="modalService.dismissAll()"
                data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span> </button>
            <div class="modal-header">
                <p>Long press on QR Code to connect</p>
            </div>
            <div class="modal-body">
                <form class="member_status_form">
                    <div class="text-center">
                        <img [src]="qrimg" alt="demo" style="height: 150px;width: 150px;">
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner> -->