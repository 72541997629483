import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-app-lost-found',
  templateUrl: './app-lost-found.component.html',
  styleUrls: ['./app-lost-found.component.scss']
})
export class AppLostFoundComponent implements OnInit {

  imgUrl = environment.slug_images;
  image: any;
  id: any;
  slug: any;
  LostFoundData: any;
  constructor(private router: Router, public activeroute: ActivatedRoute, private auth: ApiService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if (this.id) {
        this.getDetails()
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
  }
  getDetails() {
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('slug', this.slug);
    this.auth.getServiceDetail(formData).subscribe((response: any) => {
      if (response.success == 1) {
        console.log(response)
        this.LostFoundData = response.item
        this.image = (this.LostFoundData.photo ? this.imgUrl + this.LostFoundData.photo : '');
      }

    })
  }

  onImgError(event: any) {
    event.target.src = '../../../assets/images/def_app.png';
  }
}
