import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-app-emergency',
  templateUrl: './app-emergency.component.html',
  styleUrls: ['./app-emergency.component.scss']
})
export class AppEmergencyComponent implements OnInit {

  imgUrl=environment.slug_images;
  emergencyData:any={};
  dob:any='';
  isOrganDonor:any='';
  image:any;
  cardData:any=[];
  med_condn:any=[];
  allrgy:any=[];
  medication:any=[];
  med_note:any=[];
  emrg_cont:any=[];
  age: number;
  id: any;
  slug: any;


  constructor(private router:Router, public activeroute: ActivatedRoute, private auth: ApiService) {
    this.activeroute.params.subscribe((params:any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if(this.id){
      this.getDetails()
      }else{
          this.router.navigate(['/']);
        }
    })

   }

  ngOnInit(): void {
  
  }

  onImgError(event:any){
    event.target.src = '../../../assets/images/def_app.png';
  }

  call(data:any){
    const url = `${data}`
    console.log(url)
    document.location.href = "tel:+" + url
  }

  getDetails() {
    const formData = new FormData();
    formData.append('id',this.id);
    formData.append('slug',this.slug);
    this.auth.getServiceDetail(formData).subscribe((response: any) => {
      if (response.success == 1) {
  this.emergencyData = response.item
  this.image = (this.emergencyData.photo ? this.imgUrl + this.emergencyData.photo : '');
  this.dob = (this.emergencyData.dob ? moment(this.emergencyData.dob ).format('MMM DD, YYYY') : '');
  let year:any =  moment(this.emergencyData.dob ).format('YYYY')
  var a = moment();
  var b = moment(year, 'YYYY');  
  var diff = a.diff(b, 'years'); // calculates patient's age in years
  this.age = diff
  this.cardData = (this.emergencyData.medical_card.length != 0 ? this.emergencyData.medical_card : []);
  (this.cardData || []).map((e:any)=>{
    if(e.type == 'medical_condition'){
      this.med_condn.push(e);
    }else if(e.type == 'allergy'){
      this.allrgy.push(e);
    }else if(e.type == 'medical_note'){
      this.med_note.push(e);
    }else if(e.type == 'medication'){
      this.medication.push(e);
    }else if(e.type == 'emergency_contact'){
      this.emrg_cont.push(e);
    }
  })
      }
  
    })
  }

}
