import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  constructor(private metaService:Meta) { }

  ngOnInit() {
    this.metaService.updateTag({property:'og:title', content:'B in Touch App'})
    this.metaService.updateTag({property:'og:image', content: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/82/1f/1a/821f1a6b-4860-f48c-9257-10cd58c35dbe/AppIcon-0-1x_U007emarketing-0-7-0-85-220.png/1200x630wa.png'})
  }

}
