import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../api-service/api.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  userDetails: any;

  constructor(private service: ApiService) { }

  private fooSubject = new Subject<any>();

  publishSomeData(data: any) {
    console.log(data)
      this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
      return this.fooSubject;
  }


  contactData(value: any) {
    var fd = new FormData
    fd.append('target_user_id', value)
    this.service.getContactById(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
      res.user.theme_mode
      this.publishSomeData(res.user.theme_mode)
      }
    })
    }
}
