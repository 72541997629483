<div class="contact-add">
    <img class="close-btn" (click)="closeBtn()" src="../../../assets/images/close_bottom.svg">

    <h2>
        Scroll down to
       <span>Create New Contact</span>
    </h2>

    <div class="img-wrap">
        <img src="../../../assets/images/add_contact.jpg">
    </div>
    <button class="btn btn-ok" (click)="okButton()">OK</button>
</div>