<body *ngIf="type != 'business_template' && type != 'social_template'">
    <div class="main-wrap">
        <div class="splash-sec">
            <img class="img-fluid main_logo" src="assets/images/splash_logo.png">
            <img class="img-fluid" src="assets/images/logo_name.png">
        </div>
    </div>
</body>
<ng-template #mymodal let-modal *ngIf="type != 'business_template' && type != 'social_template'">
    <div id="member_status" class="login_me" role="dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="top-sec">
                <button type="button" style="outline: none;" class="close close_modal" (click)="modalService.dismissAll()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span> </button>
                <p>Long press on QR Code to connect</p>
            </div>
            <div class="modal-body">
                <form class="member_status_form">
                    <div class="text-center">
                        <img [src]="qrimg" alt="demo" style="height: 150px;width: 150px;">
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!-- <app-profile-redirect *ngIf="type == 'social_template'"></app-profile-redirect> -->
<!-- <app-profilev2 *ngIf="type == 'social_template'"></app-profilev2>
<app-profile-redirect2 *ngIf="type == 'business_template'"></app-profile-redirect2> -->
<app-received-contacts *ngIf="type == 'social_template'"></app-received-contacts> 
<app-received-contacts2 *ngIf="type == 'business_template'"></app-received-contacts2> 