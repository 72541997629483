<app-sidebar></app-sidebar>
<div class="content-body">
    <div class="container-fluid">
        <div class="form-head d-flex flex-wrap align-items-start align-items-sm-center align-items-sm-center mb-5">
            <form [formGroup]="Appname" class="w-sm-100">
                <div class="d-flex align-items-start justify-content-start mr-auto ml-sm-0 flex-column flex-sm-row">
                    <h2 class="font-w600 mb-3 mb-sm-0 mr-auto text_opacity_68 letterspace">Overview / Link &lt;</h2>
                    <input class="app_name" placeholder="Application Name" formControlName="title">
                </div>
            </form>
        </div>


        <div class="row ">

            <div class="col-xl-12">
                <div class="card form_col_manage">
                    <div class="card_scroll height540 custom_scrollbar d-flex flex-column">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title fs21 text_transform_none">Send a link to any online page or element
                                </h4>
                                <div class="graphic_icon text-right d-block d-sm-none">
                                    <img class="img-fluid" src="assets/images/link_img.png" />
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-8">
                                    <form class="bform text_white" [formGroup]="AppForm">
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label class="fs17 font-weight-bold">URL</label>
                                                <input type="text" class="form-control" formControlName="content">
                                            </div>

                                        </div>

                                        <button (click)="submit()"
                                            class="btn btn-primary btn-block rounded-pill mt-3 w-50 mx-auto">Submit</button>
                                        <!-- Form row end -->

                                        <div class="form-row mt-4 pt-4">
                                            <div class="form-group col-md-12">
                                                <label class="fs17 font-weight-bold text_opacity_68">Here are also some
                                                    resources that we carefully selected to show you what you
                                                    Can also create & share online today: </label>

                                            </div>
                                        </div>


                                        <!-- Form row end -->
                                    </form>
                                </div>
                                <div class="col-md-4">
                                    <div class="graphic_icon text-right d-none d-sm-block">
                                        <img class="img-fluid" src="assets/images/link_img.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="form-group col-md-12 d-none d-block-sm">
                                    <label class="fs17 font-weight-bold text_opacity_68 text_white">Here are also some
                                        resources that we carefully selected to show you what you
                                        Can also create & share online today: </label>

                                </div>
                                <div class="col-md-4 mb-4">
                                    <h6 class="fs17 font-weight-normal text_opacity_68 mb-3">Digital Catalogs </h6>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.flipsnack.com/digital-catalog</a></p>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.catalogmachine.com/</a></p>
                                </div>

                                <div class="col-md-4 mb-4">
                                    <h6 class="fs17 font-weight-normal text_opacity_68 mb-3">Restaurant eMenus </h6>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.visme.co/menu-maker/</a></p>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://imenupro.com/</a></p>
                                </div>
                                <div class="col-md-4 mb-4">
                                    <h6 class="fs17 font-weight-normal text_opacity_68 mb-3">Digital Brochures & Content
                                    </h6>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://flippingbook.com/online-digital-brochure</a></p>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.lucidpress.com/pages/</a></p>
                                </div>

                                <div class="col-md-4 mb-4">
                                    <h6 class="fs17 font-weight-normal text_opacity_68 mb-3">Online Polls & Surveys</h6>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.poll-maker.com/</a></p>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.surveymonkey.com/</a></p>
                                </div>

                                <div class="col-md-4 mb-4">
                                    <h6 class="fs17 font-weight-normal text_opacity_68 mb-3">Digital Coupons</h6>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.visme.co/coupon-maker/</a></p>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://venngage.com/features/coupon-maker/</a></p>
                                </div>

                                <div class="col-md-4 mb-4">
                                    <h6 class="fs17 font-weight-normal text_opacity_68 mb-3">Digital products & Assets
                                    </h6>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://xtensio.com/</a></p>
                                    <p class="m-0"><a class="fs14 text_white font-weight-bold"
                                            href="#">https://www.kyteapp.com/</a></p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="card-footer border-0 border_half_width py-0">
                        <hr />
                        <p class="text-center fs17 mb-0">Share a link to any online asset or resource and it will open
                            directly in the other person’s web browser</p>
                    </div>
                </div>


            </div>

            <div class="col-xl-12">

                <div class="card position-relative">
                    <div class="card_header_pre position-absolute">
                        <h4 class="font-w600 mb-0 mr-auto fs17 text_opacity_68 letterspace">Preview</h4>
                    </div>

                    <div class="preview_main text-center">
                        <img class="img-fluid" src="assets/images/preview_jpg3.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
</ngx-spinner>