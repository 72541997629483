import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-application-e-menu',
  templateUrl: './application-e-menu.component.html',
  styleUrls: ['./application-e-menu.component.scss']
})
export class ApplicationEMenuComponent implements OnInit {
  src: any;
  File: any;
  menu: FormGroup;
  menu_id: any;
  category_id: any;
  menuDetails: any;
  categoryLenght: any = 0;
  imageUrl = environment.imageUrl
  photo = environment.slug_images
  menuItem: FormGroup;
  showItems = false
  itemsList: any;
  menucategory: FormGroup;
  shoecat: boolean = false;
  itemsData: any;
  id: any;
  slug: any;
  item_id: any;
  itemName: string = '';
  constructor(public activeroute: ActivatedRoute, private spinner: NgxSpinnerService, private apiService: ApiService, private formBuilder: FormBuilder, private common: CommonService) { }

  ngOnInit(): void {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      this.slug = params['slug'];
      if (this.id) {
        this.getDetails()
      }
    })

    this.menu = this.formBuilder.group({
      menu_name: [''],
      text_highlight_color: [''],
      text_color: [''],
      bg_color: [''],
    })

    this.menucategory = this.formBuilder.group({
      category_name: ['']
    })

    this.menuItem = this.formBuilder.group({
      category_id: [''],
      name: [''],
      file_name: [''],
      description: [''],
      price: [''],
      url: [''],
      id: [''],
    })
    this.menu.patchValue({
      bg_color: '#141F3F',
      text_color: '#B9B9B9',
      text_highlight_color: '#141F3F'
    })
  }

  addItemInput: any = false;
  addItems() {
    this.addItemInput = true;

  }

  /* === Create Menu App === */
  catalogSubmitName() {
    var data = new FormData();
    if (this.menu_id) {
      data.append('id', this.menu_id);
    }
    data.append('menu_name', this.menu.value.menu_name);
    data.append('text_highlight_color', this.menu.value.text_highlight_color);
    data.append('text_color', this.menu.value.text_color);
    data.append('bg_color', this.menu.value.bg_color);
    this.apiService.saveMenuCatalog(data).subscribe((res: any) => {
      if (res.http_status == '200') {
        this.menu_id = res.id
      }
      else {
        this.common.error(res["msg"]);
      }
    })
  }

  /* === Create Menu App === */
  catalogSubmit() {
    var data = new FormData();
    if (this.menu_id) {
      data.append('id', this.menu_id);
    }
    data.append('menu_name', this.menu.value.menu_name);
    data.append('text_highlight_color', this.menu.value.text_highlight_color);
    data.append('text_color', this.menu.value.text_color);
    data.append('bg_color', this.menu.value.bg_color);
    this.apiService.saveMenuCatalog(data).subscribe((res: any) => {
      if (res.http_status == '200') {
        this.common.success(res["msg"]);
        this.menu_id = res.id
      }
      else {
        this.common.error(res["msg"]);
      }
    })
  }

  /* === Create Category === */
  categorySubmit() {
    console.log(this.menucategory.value);
    if (!!this.menucategory.value.category_name && this.menucategory.value.category_name.length > 0) {
      var data = new FormData();
      if (this.menu_id) {
        data.append('menu_id', this.menu_id);
        data.append('category_name', this.menucategory.value.category_name);
        this.apiService.saveMenuCategory(data).subscribe((res: any) => {
          if (res.http_status == '200') {
            this.shoecat = false
            this.common.success(res["msg"]);
            this.menucategory.reset();
            this.getMeniDetails()
          }
          else {
            this.common.error(res["msg"]);
          }
        })
      } else {
        this.common.error('please enter Menu/Catalog Title');
      }
    }

  }

  async selectItemImage(e: any) {
    const file = e.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.src = event.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      this.File = file;
    } else {
      this.common.error('Selected file is not image.');
    }
  }

    /* === Create Item === */
  menuItemSubmit() {
    if (this.category_id) {
      var data = new FormData();
      data.append('category_id', this.category_id);
      data.append('name', this.menuItem.value.name);
      data.append('file_name', this.File);
      data.append('description', this.menuItem.value.description);
      data.append('price', this.menuItem.value.price);
      data.append('url', this.menuItem.value.url);
      if (this.item_id) {
        data.append('id', this.item_id);
      }
      this.spinner.show()
      this.apiService.saveMenuItem(data).subscribe((res: any) => {
        this.itemName = ''
        this.spinner.hide()
        this.item_id = ''
        this.menuItem.reset();
        this.src = '',
        this.showItems = true;
        this.getMeniDetails()
      })
    } else {
      this.common.error('Select the Category');
    }
  }

  /* === Get Menu Detail  === */
  getMeniDetails() {
    var data = new FormData();
    data.append('id', this.menu_id);
    this.apiService.getMenu(data).subscribe((res: any) => {
    //  console.log(res)
      if (res.success == 1) {
        this.menuDetails = res.items
        if (res.items.category) {
          this.menuDetails.category.forEach((element: any) => {
            if (element.id == this.category_id) {
              element.active = true
            } else {
              element.active = false
            }
          });
          this.categoryLenght = res.items.category.length
          if (this.category_id) {
            this.itemsList = this.menuDetails.category.find((e: any) => { return e.id == this.category_id })
          } else {
            this.itemsList = this.menuDetails.category.find((e: any) => { return e.id == res.items.category[0].id })
          }
          this.showItems = true;
          this.addItemInput = true
        } else {
          this.categoryLenght = 0
        }
      }
    })
  }

/*  Delete Category */
  delete(id: any) {    
    var data = new FormData();
    data.append('id', id);
    this.apiService.DeletecategoryMenu(data).subscribe((res: any) => {
      if (res.success == 1) {
        this.category_id = ''
        this.getMeniDetails()
      }
    })
  }

  /* === Get Category Detail === */
  getcat(id: any) {
    this.menuItem.reset();
    this.itemName = '';
    this.showItems = true;
    this.category_id = id;
    this.menuDetails.category.forEach((element: any) => {
      if (element.id == id) {
        element.active = true;
      } else {
        element.active = false;
      }

    });
    this.itemsList = this.menuDetails.category.find((e: any) => { return e.id == id })
    console.log(this.itemsList)
  }

  /* === Get Item Detail === */
  getItems(id: any, cat_id: any) {
    this.itemsData = this.itemsList.items.find((e: any) => { return e.id == id })
    console.log(this.itemsData)
    this.category_id = cat_id
    this.item_id = this.itemsData.id
    this.showItems = true
    this.src = this.itemsData.photo ? this.photo + this.itemsData.photo : ''
    this.menuItem.patchValue({
      url: this.itemsData.url,
      price: this.itemsData.price,
      name: this.itemsData.name,
      description: this.itemsData.description,
    })
  }

    /* === Delete Item  === */
  DeleteItems(id: any) {
    var data = new FormData();
    data.append('id', id);
    this.apiService.DeleteMenuItems(data).subscribe((res: any) => {
      if (res.success == 1) {
        this.showItems = false
        this.getMeniDetails()
      }
    })
  }

    /* === Get App Menu Detail === */
  getDetails() {
    const formData = new FormData();
    formData.append('id', this.id);
    formData.append('slug', this.slug);
    this.apiService.getServiceDetail(formData).subscribe((response: any) => {
      //    console.log(response)
      if (response.success == 1) {
        this.menu_id = response.item.id;
        this.getMeniDetails()
        this.menu.patchValue({
          bg_color: response.item.bg_color,
          text_color: response.item.text_color,
          text_highlight_color: response.item.text_highlight_color,
          menu_name: response.item.menu_name
        })
      }
    })
  }

  newItemInput(from: string) {
    if (from == 'standAlone') {
      this.menuItem.patchValue({ name: this.itemName });
    } else {
      this.itemName = this.menuItem.value.name;
    }
  }
}
